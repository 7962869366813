import { RouterView } from 'vue-router'

import Calendar from '@/views/calendar/index.vue'
import AddEvent from '@/views/calendar/create.vue'

export default [
  {
    path: '/calendar',
    name: 'calendar',
    meta: { requiresAuth: true },
    component: RouterView,
    children: [
      {
        path: '',
        name: 'calendar.index',
        component: Calendar
      },
      {
        path: 'add-event',
        name: 'calendar.add-event',
        component: AddEvent
      },
      {
        path: 'edit-event',
        component: RouterView,
        children: [
          {
            path: '',
            name: 'calendar.edit-event-index',
            redirect: { name: 'calendar.index' }
          },
          {
            path: ':eventId',
            name: 'calendar.edit-event',
            component: AddEvent,
            props: true
          }
        ]
      }
    ]
  }
]
