
  import { defineComponent } from 'vue'
  import Icon from '@/components/icon/Icon.vue'

  export default defineComponent({
    name: 'LikeSaveFollow',
    components: { Icon },
    props: {
      id: String,
      likeCount: {
        type: Number,
        required: true
      },
      saveCount: {
        type: Number,
        required: true
      }
    },
    setup() {
      /*
      TODO - dont let the like, save or follow if already done so,
      but who makes those rules?
    *  */
      const onFollowClick = () => {
        console.log('on follow click')
      }

      const onLikeClick = () => {
        console.log('on like click')
      }

      const onSaveClick = () => {
        console.log('on save click')
      }

      return {
        onFollowClick,
        onLikeClick,
        onSaveClick
      }
    }
  })
