import { RouterView } from 'vue-router'

import AdminUsers from '@/views/admin/users.vue'
import AdminJurnis from '@/views/admin/jurnis.vue'
import AdminGroups from '@/views/admin/groups.vue'
import AdminBilling from '@/views/admin/billing.vue'
import AdminSuper from '@/views/admin/super.vue'

export default [
  {
    name: 'admin',
    path: '/admin',
    component: RouterView,
    meta: {
      requiresAuth: true,
      requiresPermission: {
        action: 'manage',
        subject: 'community'
      }
    },
    redirect: '/admin/users',
    children: [
      {
        path: 'users',
        name: 'admin.users',
        component: AdminUsers
      },
      {
        path: 'jurnis',
        name: 'admin.jurnis',
        component: AdminJurnis
      },
      {
        path: 'groups',
        name: 'admin.groups',
        component: AdminGroups
      },
      {
        path: 'billing',
        name: 'admin.billing',
        component: AdminBilling
      },
      {
        path: 'super',
        name: 'admin.super',
        component: AdminSuper
      }
    ]
  }
]
