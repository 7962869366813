import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bce02e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-editable"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "contenteditable remove-scrollbar",
    contenteditable: "true",
    "data-editable": _ctx.editable,
    style: _normalizeStyle(_ctx.css),
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.innerText))),
    onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onKeypress && _ctx.onKeypress(...args))),
    ref: "input"
  }, _toDisplayString(_ctx.modelValue), 45, _hoisted_1))
}