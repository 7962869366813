import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "header"),
    _renderSlot(_ctx.$slots, "cover"),
    _renderSlot(_ctx.$slots, "default"),
    _renderSlot(_ctx.$slots, "footer")
  ]))
}