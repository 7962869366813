import { RouteRecordRaw, RouterView } from 'vue-router'

import { routeOnboarding } from '@/router/guards'
import adminRoutes from '@/router/routes/admin'
import accountRoutes from '@/router/routes/account'
import calendarRoutes from '@/router/routes/calendar'
import meetRoutes from '@/router/routes/meet'
import jitsiRoutes from '@/router/routes/jitsi'
import jurnisRoutes from '@/router/routes/jurnis'
import campaignsRoutes from '@/router/routes/campaigns'

import Auth from '@/views/auth.vue'
import AuthAction from '@/views/auth/action.vue'
import Bots from '@/views/bots/index.vue'
import BotsAll from '@/views/bots/all.vue'
import Chat from '@/views/common/social/Chat.vue'
import ChoosePassword from '@/views/onboarding/choose_password.vue'
import ClaimAccount from '@/views/onboarding/claim_account.vue'
import ContentLibrary from '@/views/content-library/index.vue'
import ContentLibFeatured from '@/views/content-library/featured.vue'
import ContentLibVideos from '@/views/content-library/videos.vue'
import ContentLibHomework from '@/views/content-library/homework.vue'
import ContentLibResources from '@/views/content-library/resources.vue'
import ContentLibRecordings from '@/views/content-library/recording.vue'
import EngagementAnalytics from '@/views/engagement-analytics/index.vue'
import Groups from '@/views/groups/index.vue'
import GroupFeedPost from '@/views/groups/_id/posts/_id/index.vue'
import GroupMembers from '@/views/groups/_id/members.vue'
import GroupUpdate from '@/views/groups/_id/updateGroup'
import CreateGroup from '@/views/groups/createNewGroup.vue'
import Login from '@/views/auth/login.vue'
import Notifications from '@/views/Notifications.vue'
import Onboarding from '@/views/onboarding/index.vue'
import PricingPlans from '@/views/settings/pricing/index.vue'
import Register from '@/views/auth/register.vue'
import ResetPassword from '@/views/auth/reset_password.vue'
import Settings from '@/views/settings'
import SettingsAccount from '@/views/settings/account/index.vue'
import SettingsBranding from '@/views/settings/branding/index.vue'
import SettingsPayments from '@/views/settings/payments/fiserv.vue'
import SettingsOnboarding from '@/views/settings/onboarding/index.vue'
import SignUpEmbed from '@/views/jurnis/signup.vue'
import StyleGuide from '@/views/StyleGuide.vue'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    redirect: '/jurnis',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: {
      layout: 'Default'
    },
    children: [
      {
        path: 'login',
        name: 'login',
        alias: '/login',
        component: Login,
        meta: {
          requiresUnauth: true
        }
      },
      {
        path: 'logout',
        name: 'logout',
        alias: '/logout',
        component: RouterView
      },
      {
        path: 'register',
        name: 'register',
        alias: '/register',
        component: Register,
        meta: {
          requiresUnauth: true
        }
      },
      {
        path: 'reset-password',
        name: 'resetPassword',
        component: ResetPassword
      },
      {
        path: 'action',
        name: 'auth.action',
        component: AuthAction,
        props: (route) : Record<string, any> => ({
          mode: route.query.mode,
          oobCode: route.query.oobCode
        })
      }
    ]
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat,
    meta: { requiresAuth: true }
  },
  {
    path: '/group/:groupId',
    name: 'group',
    component: RouterView,
    children: [
      {
        path: 'feed',
        alias: '',
        name: 'group.feed',
        props: true,
        component: () : unknown => import(/* webpackChunkName: "group" */ '@/views/groups/_id/feed.vue')
      },
      {
        path: 'feed/:postId',
        name: 'group.feed.post',
        props: true,
        component: GroupFeedPost
      },
      {
        path: 'members',
        name: 'group.members',
        props: true,
        component: GroupMembers
      },
      {
        path: 'updateGroup',
        name: 'group.update',
        props: true,
        component: GroupUpdate
      },
      {
        path: 'goLive',
        name: 'group.live',
        props: true,
        meta: { layout: 'Default' },
        component: () : unknown => import(/* webpackChunkName: "group" */ '@/views/groups/_id/goLive.vue')
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/groups',
    name: 'groups',
    component: Groups,
    meta: { requiresAuth: true }
  },
  {
    path: '/group/add',
    name: 'groups.add',
    component: CreateGroup,
    meta: { requiresAuth: true }

  },
  {
    path: '/bots',
    name: 'bots',
    component: RouterView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'bots.index',
        component: Bots
      },
      {
        path: 'all',
        name: 'bots.all',
        component: BotsAll
      }
    ]
  },
  {
    path: '/engagement-analytics',
    name: 'engagement-analytics',
    component: RouterView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'engagement.index',
        component: EngagementAnalytics
      }
    ]
  },
  {
    path: '/content-library',
    name: 'content-library',
    component: RouterView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'content-library.index',
        component: ContentLibrary
      },
      {
        path: 'featured',
        name: 'content-library.featured',
        component: ContentLibFeatured
      },
      {
        path: 'videos',
        name: 'content-library.videos',
        component: ContentLibVideos
      },
      {
        path: 'homework',
        name: 'content-library.homework',
        component: ContentLibHomework
      },
      {
        path: 'resources',
        name: 'content-library.resources',
        component: ContentLibResources
      },
      {
        path: 'recordings',
        name: 'content-library.recordings',
        component: ContentLibRecordings
      }
    ]
  },
  {
    path: '/styleguide',
    name: 'style-guide',
    component: StyleGuide
  },
  {
    path: '/settings',
    name: 'settings',
    redirect: '/settings/account', // default child path
    component: Settings,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'account',
        name: 'settings.account',
        component: SettingsAccount
      },
      {
        path: 'branding',
        name: 'settings.branding',
        component: SettingsBranding
      },
      {
        path: 'payments',
        name: 'settings.payments',
        component: SettingsPayments
      },
      {
        path: 'onboarding',
        name: 'settings.onboarding',
        component: SettingsOnboarding
      }
    ]
  },
  {
    name: 'onboarding',
    path: '/onboarding/:invitationHash',
    component: Onboarding,
    props: true,
    beforeEnter: routeOnboarding,
    meta: {
      layout: 'Default'
    },
    children: [
      {
        path: 'claim-account',
        alias: '',
        name: 'onboarding.claim-account',
        component: ClaimAccount,
        props: true
      },
      {
        path: 'choose-password',
        name: 'onboarding.choose-password',
        component: ChoosePassword,
        props: true
      }
    ]
  },
  {
    name: 'pricing-plans',
    path: '/pricing-plans',
    component: PricingPlans,
    props: true,
    meta: {
      layout: 'Default'
    }
  },
  {
    name: 'setting-payment',
    path: '/setting-payment',
    component: SettingsPayments,
    props: true,
    meta: {
      layout: 'Default'
    }
  },
  {
    name: 'notifications',
    path: '/notifications',
    component: Notifications,
    meta: { requiresAuth: true }
  },
  {
    name: 'signup',
    path: '/signup/:code',
    alias: '/signup',
    props: true,
    meta: {
      layout: 'Default',
      requiresAuth: false
    },
    component: SignUpEmbed
  },
  ...adminRoutes,
  ...accountRoutes,
  ...calendarRoutes,
  ...meetRoutes,
  ...jitsiRoutes,
  ...jurnisRoutes,
  ...campaignsRoutes
]
