import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'

import AgoraRTC from 'agora-rtc-sdk-ng'

import {
  IAgoraRTCClient,
  ILocalVideoTrack,
  ILocalAudioTrack,
  ILocalTrack,
  IAgoraRTCRemoteUser,
  ScreenVideoTrackInitConfig,
  UID
} from 'agora-rtc-sdk-ng'
import AgoraRTM from 'agora-rtm-sdk'
import { RTMClient } from 'agora-rtm-sdk'
import {
  setDoc,
  doc,
  arrayUnion,
  arrayRemove,
  DocumentReference,
  getDoc
} from 'firebase/firestore'

import { fetchToken } from '@/services/agora-call'
import { db } from '@/services/firebase'
import { acquire, start, query, stop } from '@/services/agora-recording'

import { LocalStream } from '@/components/meeting/types'

import { useCoreStore } from '@/stores/core'
import { useThreadStore } from '@/stores/thread'
import { useCalendarStore } from '@/stores/calendar'
import { useAlertStore } from '@/stores/alert'

import { useUser } from '@/composables/useUser'
import router from '@/router'

export const useAgoraCallStore = defineStore('agoraCall', () => {
  let client: IAgoraRTCClient | null = null
  let screenSharingClient: IAgoraRTCClient | null = null
  let signalingEngine: RTMClient | null = null
  const rtcToken = ref<string>('')
  const isMicOn = ref(false)
  const isVideoOn = ref(false)
  const isMicAvailable = ref(false)
  const isVideoAvailable = ref(false)
  const localCameraStream = ref<LocalStream | null>(null)
  const screenRtcToken = ref<string>('')
  const localScreenStream = ref<LocalStream | null>(null)
  const isScreenSharing = ref(false)
  const focusedId = ref<string | UID>('')
  const displayMode = ref('tile')
  const showSidebar = ref(true)
  const remoteUsers = ref<Record<string, IAgoraRTCRemoteUser>>({})
  const participants = ref<Record<string, any>>({})
  const remoteUserProfiles = ref<Record<string, string>>({})
  const triggerReload = ref({})
  const initiatedRecording = ref(false)
  const recordingResourceId = ref<string | null>(null)
  const recordingSid = ref<string | null>(null)
  const isRecorderAndRecording = ref(false)
  const stoppedRecording = ref(false)
  const meetingType = ref('threads')
  const currentMeetingOrganizer = ref<string>()
  const videoRoom = ref<string>('')
  const isHostLeave = ref(false)
  const { add } = useAlertStore()

  const RECORDING_SERVICE_NOT_STARTED = 0
  const RECORDING_SERVICE_INITIALIZED = 1
  const RECORDING_SERVICE_STARTING = 2
  const RECORDING_SERVICE_PARTIALLY_READY = 3
  const RECORDING_SERVICE_READY = 4
  const RECORDING_SERVICE_IN_PROGRESS = 5
  const RECODRING_SERVICE_REQUESTED_TO_STOP = 6
  const RECORDING_SERVICE_STOPS = 7
  const RECORDING_SERVICE_EXITS = 8
  const RECORDING_SERVICE_EXITS_ABNORMALLY = 20
  const RECORDING_STATUS_LIST_TO_STOP_START_QUERY = [
    RECORDING_SERVICE_IN_PROGRESS,
    RECODRING_SERVICE_REQUESTED_TO_STOP,
    RECORDING_SERVICE_STOPS,
    RECORDING_SERVICE_EXITS,
    RECORDING_SERVICE_EXITS_ABNORMALLY
  ]
  const RECORDING_STATUS_LIST_START_ERROR = [
    RECODRING_SERVICE_REQUESTED_TO_STOP,
    RECORDING_SERVICE_STOPS,
    RECORDING_SERVICE_EXITS,
    RECORDING_SERVICE_EXITS_ABNORMALLY
  ]

  const { getMemberIdByAgoraId } = useCoreStore()
  const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
  const { updateThread } = useThreadStore()
  const { getMeetingEventByMeetingLink, updateMeetingEvent, cleanSnapshots } = useCalendarStore()

  const remoteUserList = computed(() => Object.values(remoteUsers.value))

  async function setupLocalCameraStream(uid: string, attendeeMode: string) {
    const defaultConfig = {
      streamID: uid,
      audio: true,
      video: true,
      screen: false
    }

    switch (attendeeMode) {
      case 'audio-only':
        defaultConfig.video = false
        break
      case 'audience':
        defaultConfig.video = false
        defaultConfig.audio = false
        break
      default:
      case 'video':
        break
    }

    // to check if mic or video is available
    let videoTrack: ILocalVideoTrack | null = null
    try {
      await navigator.mediaDevices.getUserMedia({ video: true })
      isVideoAvailable.value = true
      videoTrack = await AgoraRTC.createCameraVideoTrack()
    } catch (err) {
      defaultConfig.video = false
    }

    let audioTrack: ILocalAudioTrack | null = null
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true })
      isMicAvailable.value = true
      audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
    } catch (err) {
      defaultConfig.audio = false
    }

    isVideoOn.value = defaultConfig.video
    isMicOn.value = defaultConfig.audio

    const stream = {
      videoTrack,
      audioTrack,
      uid: defaultConfig.streamID,
      type: 'camera'
    } as LocalStream

    localCameraStream.value = stream
  }

  const checkCurrentUserIsHost = async (uid) => {
    try {
      const userId = await getMemberIdByAgoraId(uid)
      if (userId === currentMeetingOrganizer.value)
        return true
      return false
    } catch (error) {
      console.log(error)
      throw new Error('Error: is here')
    }
  }

  const checkIsUserRecording = async (uid, roomId) => {
    const { recorders } = storeToRefs(useThreadStore())
    const { recorders: calendarEventRecorders } = storeToRefs(useCalendarStore())

    try {
      const userId = await getMemberIdByAgoraId(uid)
      let recorder: Record<string, string> = recorders.value[roomId]
      if (meetingType.value === 'meetingEvents') {
        const meetingEventId = await getMeetingEventByMeetingLink(roomId)
        recorder = calendarEventRecorders.value[meetingEventId]
      }

      return recorder && recorder.userId === userId
    } catch (error) {
      console.log(error)
      throw new Error(`[checkIsUserRecording] - failed to stop recording: ${error}`)
    }
  }

  const publishLocalStream = async (uid) => {
    if (!localCameraStream.value || !client)
      throw new Error('unable to start video')

    try {
      if (localCameraStream.value.audioTrack && localCameraStream.value.videoTrack) {
        await client.publish([
          localCameraStream.value.audioTrack as unknown as ILocalTrack,
          localCameraStream.value.videoTrack as unknown as ILocalTrack
        ])
      } else if (localCameraStream.value.videoTrack) {
        await client.publish([localCameraStream.value.videoTrack as unknown as ILocalTrack])
      } else if (localCameraStream.value.audioTrack) {
        await client.publish([localCameraStream.value.audioTrack as unknown as ILocalTrack])
      }

      focusedId.value = `${uid}`
    } catch (err: any) {
      console.log(`publishLocalStream: ${err.message}`)
      throw new Error('please try to rejoin')
    }
  }

  const getRemoteUserId = (streamUid) => {
    if (!streamUid)
      return ''

    const agoraId = streamUid.includes('screen') ? streamUid.slice(7) : streamUid

    return remoteUserProfiles.value[agoraId]
  }

  function removeRemoteUser(uid) {
    delete remoteUsers.value[uid]
    delete participants.value[uid]
    delete remoteUserProfiles.value[uid]
  }

  async function addRemoteUser(remoteUser) {
    remoteUsers.value[remoteUser.uid] = remoteUser
    const memberId = await getMemberIdByAgoraId(String(remoteUser.uid))
    remoteUserProfiles.value[remoteUser.uid] = memberId

    if (!participants.value[remoteUser.uid] && getRemoteUserId(String(remoteUser.uid)))
      participants.value[remoteUser.uid] = useUser(getRemoteUserId(String(remoteUser.uid)), null)

    if (triggerReload.value[remoteUser.uid])
      triggerReload.value[remoteUser.uid] += 1
    else
      triggerReload.value[remoteUser.uid] = 1
  }

  const subscribeStream = async (remoteUser, mediaType) => {
    if (!client)
      throw new Error(`unable to subscribe to remote user ${mediaType} `)

    try {
      await client.subscribe(remoteUser, mediaType)
      await client.setStreamFallbackOption(remoteUser.uid, 1)
      await addRemoteUser(remoteUser)
    } catch (error) {
      throw new Error(`Error in subscribing the remote user:: ${remoteUser.uid} >> ${mediaType} >> ${error}`)
    }
  }

  const joinStream = async (appId, room, uid) => {
    if (!appId || !room || !uid || !rtcToken.value || !client)
      throw new Error('unable to start video')

    try {
      await client.join(appId, room, rtcToken.value, uid)
      const isHost = await checkCurrentUserIsHost(String(uid))
      if (isHost)
        isHostLeave.value = false
    } catch (err) {
      throw new Error(`failed to join stream: ${err}`)
    }
  }

  const createLocalScreenStream = async (screenUid: string, config?: ScreenVideoTrackInitConfig) => {
    const defaultConfig = {
      ...config
    }

    const videoTrack = await AgoraRTC.createScreenVideoTrack(defaultConfig, 'disable')

    const stream = {
      videoTrack,
      audioTrack: null,
      uid: `${screenUid}`,
      type: 'screen'
    } as LocalStream

    return stream
  }

  const stopSharingScreen = async (uid: string) => {
    if (!screenSharingClient)
      return

    try {
      if (localScreenStream.value?.videoTrack) {
        await screenSharingClient.unpublish([localScreenStream.value.videoTrack as unknown as ILocalVideoTrack])

        localScreenStream.value?.videoTrack?.setEnabled(false)
        localScreenStream.value?.videoTrack?.close()
      }

      await screenSharingClient.leave()

      isScreenSharing.value = false
      localScreenStream.value = null
      focusedId.value = `${uid}`
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
  }

  const subscribeScreenStreamEvents = (uid: string, videoTrack?: ILocalVideoTrack | null) => {
    if (!videoTrack)
      throw new Error('failed to subscribe to stream events')

    videoTrack.on('track-ended', async () => {
      stopSharingScreen(uid)
    })
  }

  const setupLocalScreenStream = async (uid: string, screenUid: string) => {
    try {
      localScreenStream.value = await createLocalScreenStream(screenUid)

      subscribeScreenStreamEvents(uid, localScreenStream.value?.videoTrack as unknown as ILocalVideoTrack)
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
  }

  const joinScreenStream = async (appId: string, room: string, screenUid: string) => {
    if (!appId || !room || !screenUid || !screenRtcToken.value || !screenSharingClient)
      throw new Error('unable to start screen sharing')

    try {
      focusedId.value = `${await screenSharingClient.join(appId, room, screenRtcToken.value, screenUid)}`
    } catch (err: any) {
      throw new Error(`failed to join screen share stream: ${err.message}`)
    }
  }

  const publishLocalScreenStream = async (screenUid: string) => {
    if (!localScreenStream.value?.videoTrack || !screenSharingClient)
      throw new Error('unable to start screen sharing')

    try {
      await screenSharingClient.publish(localScreenStream.value.videoTrack as unknown as ILocalVideoTrack)

      isScreenSharing.value = true
      focusedId.value = screenUid
    } catch (err: any) {
      throw new Error(`failed to start screen sharing: ${err.message}`)
    }
  }

  const focusUser = (steamUid: string) => {
    displayMode.value = 'pip'
    focusedId.value = steamUid
  }

  const switchDisplay = (e) => {
    if (displayMode.value === 'pip')
      displayMode.value = 'tile'
    else if (displayMode.value === 'tile')
      displayMode.value = 'pip'
    else if (displayMode.value === 'share')
      console.log('do nothing or alert, tbd')
    else
      throw new Error('Display Mode can only be tile/pip/share')
  }

  const initAgoraCall = async (room, uid, transcode, audience = false) => {
    try {
      const responseData = await fetchToken(room, uid, true)
      rtcToken.value = responseData.rtcToken
      videoRoom.value = room
      client = AgoraRTC.createClient({ mode: transcode, codec: 'vp8' })
      if (transcode === 'live') {
        if (audience)
          await client.setClientRole('audience')
        else
          await client.setClientRole('host')
      }

      client.enableAudioVolumeIndicator()
      client.enableDualStream().then(() => {
        console.log('[initAgoraCall] - Enable Dual stream success!')
      }).catch((err) => {
        throw err
      })
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
  }

  const initAgoraScreenShare = async (room, screenUid, transcode) => {
    try {
      const responseData = await fetchToken(room, screenUid, true)
      screenRtcToken.value = responseData.rtcToken

      screenSharingClient = AgoraRTC.createClient({ mode: transcode, codec: 'vp8' })
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
  }

  const trackMeetingJoinRequestors = async (room: string) => {
    if (!currentUser.value?.userRef)
      throw new Error('no user found')

    const docId = await getMeetingEventByMeetingLink(room)

    await setDoc(
      doc(db, `meetingEvents/${docId}`),
      {
        joinRequestors: arrayUnion(currentUser.value?.userRef)
      },
      { merge: true }
    )
  }

  const acceptJoinRequest = async (userRef: DocumentReference, room: string) => {
    if (!currentUser.value?.userRef)
      throw new Error('no user found')

    if (meetingType.value !== 'meetingEvents')
      throw new Error('action not supported')

    const docId = await getMeetingEventByMeetingLink(room)

    await setDoc(
      doc(db, `meetingEvents/${docId}`),
      {
        joinRequestors: arrayRemove(userRef),
        acceptedRequestors: arrayUnion(userRef)
      },
      { merge: true }
    )
  }

  const acceptMultipleJoinRequest = async (userRefs, room: string) => {
    if (!currentUser.value?.userRef)
      throw new Error('no user found')

    if (meetingType.value !== 'meetingEvents')
      throw new Error('action not supported')

    const docId = await getMeetingEventByMeetingLink(room)

    await setDoc(
      doc(db, `meetingEvents/${docId}`),
      {
        joinRequestors: arrayRemove(...userRefs),
        acceptedRequestors: arrayUnion(...userRefs)
      },
      { merge: true }
    )
  }

  const trackMeetingJoiners = async (room: string) => {
    if (!currentUser.value?.userRef)
      throw new Error('no user found')

    let docId = room
    if (meetingType.value === 'meetingEvents')
      docId = await getMeetingEventByMeetingLink(room)

    await setDoc(
      doc(db, `${meetingType.value}/${docId}`),
      {
        joinRequestors: arrayRemove(currentUser.value?.userRef),
        joinedMeeting: arrayUnion(currentUser.value?.userRef)
      },
      { merge: true }
    )
  }

  const trackMeetingLeavers = async (room: string) => {
    if (!currentUser.value?.userRef)
      throw new Error('no user found')

    let docId = room
    if (meetingType.value === 'meetingEvents')
      docId = await getMeetingEventByMeetingLink(room)

    await setDoc(
      doc(db, `${meetingType.value}/${docId}`),
      {
        joinRequestors: arrayRemove(currentUser.value?.userRef),
        joinedMeeting: arrayRemove(currentUser.value?.userRef)
      },
      { merge: true }
    )
  }

  const trackMeetingAcceptedLeavers = async (room: string) => {
    if (!currentUser.value?.userRef)
      throw new Error('no user found')

    if (meetingType.value !== 'meetingEvents')
      throw new Error('action not supported')

    const docId = await getMeetingEventByMeetingLink(room)

    await setDoc(
      doc(db, `meetingEvents/${docId}`),
      {
        acceptedRequestors: arrayRemove(currentUser.value?.userRef)
      },
      { merge: true }
    )
  }

  const startRecordVideo = async (room: string, uid: string) => {
    if (!currentUser.value?.userRef)
      throw new Error('no user found')

    initiatedRecording.value = true

    // get cloud recording resource
    try {
      const resourceId = await acquire(room, `${uid}`)

      if (!resourceId)
        throw new Error('failed to acquire resource')

      recordingResourceId.value = resourceId
      // start a cloud recording
      const sid = await start(room, recordingResourceId.value, rtcToken.value, currentCommunity.value?.id, `${uid}`)
      console.log(sid, 'sid value is here')
      if (!sid)
        throw new Error('failed to start cloud recording')

      recordingSid.value = sid

      // const currentRecordingStatus = await query(recordingResourceId.value, recordingSid.value)
      // console.log(currentRecordingStatus, 'currentRecordingStatus')

      const meetingEvent = await getMeetingEventByMeetingLink(room)
      const meetingEventSnap = await getDoc(doc(db, `meetingEvents/${meetingEvent}`))

      if (meetingEventSnap.exists()) {
        const meetingEventData = meetingEventSnap.data()
        if (meetingEventData.meta.recordingSid)
          throw new Error('recording is already in progress, failed to start cloud recording')
      }

      let _recordingStatus = 0
      /* eslint-disable no-await-in-loop */
      while (!RECORDING_STATUS_LIST_TO_STOP_START_QUERY.includes(_recordingStatus))
        _recordingStatus = await query(recordingResourceId.value, recordingSid.value)

      if (RECORDING_STATUS_LIST_START_ERROR.includes(_recordingStatus))
        throw new Error('recording failed to start')

      isRecorderAndRecording.value = true
      initiatedRecording.value = false

      // track recording progress
      if (meetingType.value === 'threads') {
        await updateThread({
          meta: {
            recordingResourceId: recordingResourceId.value,
            recordingSid: recordingSid.value,
            startedRecordingBy: currentUser?.value?.userRef,
            recordingProgress: 'recording'
          }
        }, room)
      } else if (meetingType.value === 'meetingEvents') {
        await updateMeetingEvent({
          meta: {
            recordingResourceId: recordingResourceId.value,
            recordingSid: recordingSid.value,
            startedRecordingBy: currentUser?.value?.userRef,
            recordingProgress: 'recording'
          }
        }, await getMeetingEventByMeetingLink(room))
      }
    } catch (err: any) {
      initiatedRecording.value = false
      isRecorderAndRecording.value = false
      throw new Error(`${err.message}`)
    }
  }

  const stopRecordingVideo = async (room: string, uid: string) => {
    try {
      if (!recordingResourceId.value || !recordingSid.value)
        throw new Error('unable to find recording')

      if (!currentCommunity.value)
        throw new Error('no community found')

      // stop recording
      let roomId
      if (meetingType.value === 'meetingEvents')
        roomId = await getMeetingEventByMeetingLink(room)

      await stop(room, recordingResourceId.value, recordingSid.value, currentCommunity.value?.id, `${uid}`, roomId)

      isRecorderAndRecording.value = false
      stoppedRecording.value = false

      if (meetingType.value === 'threads') {
        await updateThread({
          meta: {
            recordingResourceId: null,
            recordingSid: null,
            startedRecordingBy: null,
            recordingProgress: 'stopped'
          }
        }, room)
      } else if (meetingType.value === 'meetingEvents') {
        await updateMeetingEvent({
          meta: {
            recordingResourceId: null,
            recordingSid: null,
            startedRecordingBy: null,
            recordingProgress: 'stopped'
          }
        }, await getMeetingEventByMeetingLink(room))
      }
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
    // recording is then saved in cloud storage
    // and created a content library recording item
    // handled in the cloud function
    // post link to chat?
    // send link to email of participants?
  }

  const endAgoraCall = async (uid: string) => {
    if (!client)
      return

    try {
      if (isScreenSharing.value)
        await stopSharingScreen(uid)

      if (localCameraStream.value?.videoTrack) {
        await client.unpublish([localCameraStream.value.videoTrack as unknown as ILocalTrack])
        localCameraStream.value.videoTrack?.setEnabled(false)
        localCameraStream.value.videoTrack?.close()
      }

      if (localCameraStream.value?.audioTrack) {
        await client.unpublish([localCameraStream.value?.audioTrack as unknown as ILocalTrack])
        localCameraStream.value.audioTrack?.setEnabled(false)
        localCameraStream.value.audioTrack?.close()
      }

      await client.leave()
      localCameraStream.value = null
    } catch (err: any) {
      if (localCameraStream.value) {
        // for instance where a user fails to join the call and is asked to rejoin
        // we must close their video/audio first since unpublishing/leaving agora client
        // without successfully joining returns an error
        localCameraStream.value?.videoTrack?.setEnabled(false)
        localCameraStream.value?.videoTrack?.close()
        localCameraStream.value?.audioTrack?.setEnabled(false)
        localCameraStream.value?.audioTrack?.close()
        localCameraStream.value = null
      }
      console.log(`endAgoraCall: ${err.message}`)
      throw new Error('you will still be redirected back home')
    }
  }

  const extractMessageInfo = (messageObject, handleMic: any, handleExit: any, uid: any, updateChatMessages?: any) => {
    // Parse the message string to convert it into a JavaScript object
    const messageData = JSON.parse(messageObject.message.replace(/'/g, '"'))

    // Extract the desired fields
    const publisher = messageObject.publisher
    const type = messageData.type
    const info = messageData.info
    // Return the extracted fields
    if (type === 'mute' && info === uid)
      handleMic()
    else if (type === 'remove' && info === uid)
      handleExit('remove')
    else if (type === 'chat')
      updateChatMessages(info)
    else if (type === 'chatWithUid' && info.chatForUid === 'everyone')
      updateChatMessages(info)
    else if (type === 'chatWithUid' && info.chatForUid === uid)
      updateChatMessages(info)
  }

  const startRtmConnectionInit = async (room: string, uid: string, appId: string,
    handleMic: any, handleExit: any, updateChatMessages?: any) => {
    const responseData = await fetchToken(room, uid, true)
    signalingEngine = new AgoraRTM.RTM(appId, uid, { token: responseData.rtmToken })

    // Listen for events
    const resmeg = signalingEngine.addEventListener('message', (eventArgs) => {
      extractMessageInfo(eventArgs, handleMic, handleExit, uid, updateChatMessages)
    })

    // subscribeUserMetadata
    // signalingEngine.subscribeUserMetadata(userId) => {
    //   console.log('listening for user metadata', userId)
    // }

    // Login
    try {
      const joinres = await signalingEngine.login()
      console.log('joined', joinres)
    } catch (err) {
      console.log('error occurs at login: ', err)
    }
    try {
      const subscribeOptions = {
        withMessage: true,
        withPresence: true,
        withMetadata: true,
        withLock: true
      }
      const ressubs = await signalingEngine.subscribe(room, subscribeOptions)
      console.log('subscribed', ressubs)
    } catch (error) {
      console.log(error)
    }
  }

  const sendMessage = async (room: string, type: string, uid: string, message?: any) => {
    // Send channel message
    try {
      if (signalingEngine) {
        const mes = uid || message
        const res = await signalingEngine.publish(room, JSON.stringify({ type, info: mes }))
      } else {
        console.log('signaling engine', signalingEngine)
      }
    } catch (err) {
      console.log({ err }, 'error occurs at publish message')
    }
  }
  const setCurrentMeetingOrganiserId = async (id) => {
    try {
      currentMeetingOrganizer.value = id
    } catch (error) {
      throw new Error('Error while updating the current meeting organiser value')
    }
  }

  const leaveUser = async (uid) => {
    try {
      if (!client)
        throw new Error('unable to start video')

      const meetingEvent = await getMeetingEventByMeetingLink(videoRoom.value)
      const meetingEventSnap = await getDoc(doc(db, `meetingEvents/${meetingEvent}`))

      if (meetingEventSnap.exists()) {
        const meetingEventData = meetingEventSnap.data()
        if (meetingEventData.meta.recordingSid) {
          /* eslint-disable no-await-in-loop */
          if (meetingEventData.meta.recordingResourceId && meetingEventData.meta.recordingSid) {
            let _status = await query(meetingEventData.meta.recordingResourceId, meetingEventData.meta.recordingSid)
            while (!RECORDING_STATUS_LIST_TO_STOP_START_QUERY.includes(_status))
              _status = await query(meetingEventData.meta.recordingResourceId, meetingEventData.meta.recordingSid)
          }
        }
      }

      if (isRecorderAndRecording.value)
        await stopRecordingVideo(videoRoom.value, uid)
      if (localCameraStream.value?.videoTrack) {
        await client.unpublish([localCameraStream.value.videoTrack as unknown as ILocalTrack])
        localCameraStream.value.videoTrack?.setEnabled(false)
        localCameraStream.value.videoTrack?.close()
      }

      if (localCameraStream.value?.audioTrack) {
        await client.unpublish([localCameraStream.value?.audioTrack as unknown as ILocalTrack])
        localCameraStream.value.audioTrack?.setEnabled(false)
        localCameraStream.value.audioTrack?.close()
      }

      await trackMeetingLeavers(videoRoom.value)

      if (meetingType.value === 'meetingEvents') {
        await trackMeetingAcceptedLeavers(videoRoom.value)
        cleanSnapshots()
      }
      await client.leave()
      localCameraStream.value = null
      return router.push({ name: 'jurnis' })
    } catch (error) {
      console.log(error, 'error is here')
      throw new Error('Error')
    }
  }

  const handleStopVideoRecording = async (uid) => {
    try {
      if (!client)
        throw new Error('unable to stop video recording')

      const meetingEvent = await getMeetingEventByMeetingLink(videoRoom.value)
      const meetingEventSnap = await getDoc(doc(db, `meetingEvents/${meetingEvent}`))

      if (!meetingEventSnap.exists())
        return

      const meetingEventData = meetingEventSnap.data()
      if (!meetingEventData.meta.recordingResourceId || !meetingEventData.meta.recordingSid)
        return

      const _status = await query(meetingEventData.meta.recordingResourceId, meetingEventData.meta.recordingSid)
      if (_status === RECORDING_SERVICE_IN_PROGRESS) {
        recordingResourceId.value = meetingEventData.meta.recordingResourceId
        recordingSid.value = meetingEventData.meta.recordingSid
        await stopRecordingVideo(videoRoom.value, uid)
      }
    } catch (error) {
      console.log(`[handleStopVideoRecording] - unable to stop video recording: ${error}`)
    }
  }

  const handleHostLeft = async (hostUid, streamId) => {
    const isHostRecording = await checkIsUserRecording(hostUid, videoRoom.value)
    if (isHostRecording)
      await handleStopVideoRecording(hostUid)
    await leaveUser(streamId)
  }

  const handleParticipantLeft = async (participantUid) => {
    const isParticipantRecording = await checkIsUserRecording(participantUid, videoRoom.value)
    if (isParticipantRecording)
      await handleStopVideoRecording(participantUid)
  }

  const subscribeStreamEvents = (streamId) => {
    if (!client)
      throw new Error('unable to start video')

    client.on('connection-state-change', async (curState, prevState) => {
      console.log(`current_state:: ${curState} >> prev_state:: ${prevState}`)
      if (curState === 'CONNECTED' && client?.role === 'host') {
        await setupLocalCameraStream(streamId, 'video')
        await publishLocalStream(streamId)
      }
    })

    client.on('media-reconnect-start', async (uid) => {
      console.log(`Media reconnection start for uid:: ${uid}`)
      if (uid === streamId)
        await publishLocalStream(streamId)
    })

    client.on('user-joined', (remoteUser) => {
      console.log('user-joined', remoteUser)
    })

    client.on('user-left', async (remoteUser) => {
      console.log('user-left', remoteUser)
      removeRemoteUser(remoteUser.uid)
      try {
        isHostLeave.value = await checkCurrentUserIsHost(remoteUser.uid)
        // and not stopped share screen
        if (isHostLeave.value && !String(remoteUser.uid).includes('screen'))
          handleHostLeft(remoteUser.uid, streamId)
        else if (!String(remoteUser.uid).includes('screen'))
          handleParticipantLeft(remoteUser.uid)
      } catch (error) {
        console.log('[Error]: While Left the call when host leave', error)
      }

      if (remoteUser.uid === focusedId.value)
        focusedId.value = `${streamId}`
    })

    client.on('user-published', async (remoteStream, mediaType) => {
      console.log('user-published', remoteStream, mediaType)

      try {
        await subscribeStream(remoteStream, mediaType)
      } catch (error) {
        console.log(`[user-published] Error in subscribing remote user:: ${error}`)
      }
    })

    client.on('user-unpublished', (remoteStream, mediaType) => {
      console.log('user-unpublished', remoteStream, mediaType)
    })
  }

  return {
    meetingType,
    isMicOn,
    isVideoOn,
    isMicAvailable,
    isVideoAvailable,
    initAgoraCall,
    client,
    screenSharingClient,
    signalingEngine,
    setupLocalCameraStream,
    publishLocalStream,
    subscribeStreamEvents,
    triggerReload,
    joinStream,
    localCameraStream,
    localScreenStream,
    focusedId,
    displayMode,
    showSidebar,
    remoteUsers,
    remoteUserList,
    participants,
    getRemoteUserId,
    initAgoraScreenShare,
    screenRtcToken,
    setupLocalScreenStream,
    subscribeScreenStreamEvents,
    isScreenSharing,
    joinScreenStream,
    startRtmConnectionInit,
    sendMessage,
    publishLocalScreenStream,
    stopSharingScreen,
    focusUser,
    switchDisplay,
    endAgoraCall,
    trackMeetingJoinRequestors,
    acceptJoinRequest,
    trackMeetingJoiners,
    trackMeetingLeavers,
    trackMeetingAcceptedLeavers,
    startRecordVideo,
    initiatedRecording,
    isRecorderAndRecording,
    recordingResourceId,
    recordingSid,
    stopRecordingVideo,
    stoppedRecording,
    RECORDING_STATUS_LIST_TO_STOP_START_QUERY,
    RECORDING_STATUS_LIST_START_ERROR,
    removeRemoteUser,
    setCurrentMeetingOrganiserId,
    acceptMultipleJoinRequest,
    isHostLeave
  }
})
