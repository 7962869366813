
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      to: {
        type: Object,
        required: true
      }
    },
    setup() {
      return {}
    }
  })
