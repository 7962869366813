import { defineStore } from 'pinia'

export const useSpinnerStore = defineStore('spinner', {
  state: () => ({
    loading: false
  }),
  getters: {
    isLoading(state) {
      return state.loading
    }
  },
  actions: {
    start() {
      this.loading = true
    },
    done() {
      this.loading = false
    }
  }
})
