
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      icon: {
        type: String,
        default: null
      }
    },
    setup() {
      return {}
    }
  })
