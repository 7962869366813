import { storeToRefs } from 'pinia'
import { useOnboardingStore } from '@/stores/onboarding'
import { useAlertStore } from '@/stores/alert'
import { useCoreStore } from '@/stores/core'
import { useBillingStore } from '@/stores/billing'
import { useJurniStore } from '@/stores/jurni'
import { useCalendarStore } from '@/stores/calendar'
import { NavigationGuard } from 'vue-router'

export async function doesUserNeedToSetupPayment(jurniId: string) : Promise<boolean> {
  if (!jurniId || jurniId === 'coach')
    return false

  const jurniStore = useJurniStore()
  const jurni = await jurniStore.loadJurni(jurniId)
  const { currentUser } = storeToRefs(useCoreStore())
  const { billingStatus } = storeToRefs(useBillingStore())

  if (!jurni || !jurni.meta || !currentUser.value)
    return false

  return jurni.meta.billingProvider === 'Through Jurni' && billingStatus.value === 'Inactive'
}

export const routeOnboarding : NavigationGuard = async (to, from, next) => {
  const onboardingStore = useOnboardingStore()

  const { getCurrentUserAuth } = useCoreStore()
  const isAuthValid = async () : Promise<boolean> => {
    const auth = await getCurrentUserAuth()
    return !!auth
  }
  const invitationHash = to.params.invitationHash
  const preview = Boolean(to.query.preview)
  let jurniId = to.params.jurniId as string
  let invitation
  const isCoach = jurniId === 'coach'

  if (invitationHash) {
    invitation = await onboardingStore.loadInvitation(invitationHash)
    jurniId = invitation.value.jurniId
  }

  if (to.name === 'onboarding.claim-account') {
    next()
  } else if (await isAuthValid() && jurniId && !isCoach) {
    const onboarding = await onboardingStore.loadUserOnboarding(jurniId)
    const userNeedsToSetupPayment = await doesUserNeedToSetupPayment(jurniId)

    if (onboarding?.completed && !preview) {
      next({
        name: 'jurni',
        params: { jurniId }
      })
    } else if (to.name === 'jurni.onboarding.student-payment' && userNeedsToSetupPayment && !preview) {
      // Go to the student payments
      next()
    } else if ((to.name === 'jurni.onboarding.steps' || to.name === 'onboarding.claim-account') &&
      userNeedsToSetupPayment && !preview) {
      next({
        name: 'jurni.onboarding.student-payment',
        params: { jurniId }
      })
    } else if (to.name === 'jurni.onboarding.steps') { next() } else {
      next({
        name: 'jurni.onboarding.steps',
        params: { jurniId }
      })
    }
  } else if (await isAuthValid() && jurniId && isCoach) {
    if (invitation) {
      if (invitation.value.docType === 'community-coach') {
        const onboarding = await onboardingStore.loadUserOnboarding('coach')
        if (onboarding?.completed)
          next({ name: 'home' })
        else if (to.name === 'jurni.onboarding.steps')
          next()
        else
          next({ name: 'jurni.onboarding.steps', params: { jurniId: 'coach' } })
      } else {
        next()
      }
      return
    }

    next()
  } else {
    next({ name: 'login' })
  }
}

export const resumeOnboarding : NavigationGuard = async (to, from, next) => {
  const { getJurniById } = useJurniStore()

  const { currentUser } = storeToRefs(useCoreStore())
  const jurniId = to.params.jurniId as string
  const jurni = getJurniById(jurniId)

  if (jurni?.coach?.id === currentUser.value?.id) {
    next()
    return
  }

  const { loadUserOnboarding } = useOnboardingStore()
  if (!jurni || !jurni.onboardingflow) {
    next()
  } else {
    const onboarding = await loadUserOnboarding(jurniId)

    if (onboarding?.completed || onboarding?.onboardingflow.steps.length === 0) {
      next()
    } else {
      next({
        name: 'jurni.onboarding',
        params: { jurniId }
      })
    }
  }
}

export const muteCallNotification : NavigationGuard = async (to) => {
  if (to.name === 'meet' || to.name === 'meet.room' || to.name === 'meet.pending') {
    const alertStore = useAlertStore()
    if (Object.keys(alertStore.audio).includes('meeting'))
      alertStore.stopAudio('meeting')
  }
}

export const routeMeetingRoom : NavigationGuard = async (to, from, next) => {
  const { currentMeetingId, currentMeetingOrganizer, acceptedRequestors } = storeToRefs(useCalendarStore())
  const { getMeetingEventByMeetingLink, setCurrentMeetingId } = useCalendarStore()
  const { currentUserId } = storeToRefs(useCoreStore())

  if (to.query.type !== 'meetingEvents' && to.name === 'meet.room')
    next()

  // if meeting events, go to pending room
  const meetingId = await getMeetingEventByMeetingLink(to.params.room as string)
  await setCurrentMeetingId(meetingId)
  let isUserAccepted = 0
  if (acceptedRequestors.value[currentMeetingId.value]) {
    isUserAccepted = acceptedRequestors.value[currentMeetingId.value].filter(
      (userRef) => userRef.id === currentUserId.value
    ).length
  }

  await muteCallNotification(to, from, next)
  if (to.name === 'meet.room' && (currentMeetingOrganizer.value === currentUserId.value || isUserAccepted))
    next()

  if (to.name === 'meet.pending')
    next()

  next({ name: 'meet.pending', params: { room: to.params.room }, query: { type: to.query.type } })
}

export default {
  routeOnboarding,
  resumeOnboarding,
  muteCallNotification,
  routeMeetingRoom
}
