import { ref, Ref } from 'vue'
import { NotificationComposable, useNotification } from '@/composables/useNotification'
import { Notification } from '@/types'

type UseNotifications = (notifications : Notification[]) => NotificationComposable[]

export const useNotifications: UseNotifications = (notifications) => {
  const notificationList : Ref<NotificationComposable[]> = ref([])

  notifications.forEach((notification) => {
    notificationList.value.push(useNotification(notification.id))
  })

  return notificationList.value
}
