import { ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import {
  doc,
  getDoc,
  getDocs,
  query,
  collection,
  where,
  setDoc,
  DocumentReference
} from 'firebase/firestore'
import { db } from '@/services/firebase'

import { useNotification } from '@/composables/useNotification'

import { useCoreStore } from '@/stores/core'

import { addPaymentMethod, removePaymentMethod } from '@/services/fiserv'

import { UserPaymentMethodDetails, UserBillables } from '@/types'

export const useBillingStore = defineStore('billing', () => {
  const billingStatus = ref('Inactive')
  const activePaymentMethodToken = ref('')
  const activePaymentMethodExpiry = ref('')
  const activePaymentMethodPostal = ref('')
  const userPaymentMethods = ref<UserPaymentMethodDetails[]>([])
  const studentBillPayment = ref<UserBillables[]>([])
  const coachBillPayment = ref<UserBillables[]>([])
  const paymentSettingChangedExcludeBillingFrom = ref<Record<string, boolean>>({})

  const { currentUser, currentUserId, currentCommunity } = storeToRefs(useCoreStore())

  const loadBillPayments = async () => {
    if (!currentCommunity.value?.id || !currentUser.value?.userRef)
      return

    const commSettings = await getDoc(doc(currentUser.value?.userRef, 'communitySettings', currentCommunity.value?.id))
    const comSettingsData = commSettings.data()

    if (comSettingsData) {
      if (comSettingsData.studentBillPayment) {
        Object.keys(comSettingsData.studentBillPayment).forEach((jurniId) => {
          if (currentUser.value)
            studentBillPayment.value[jurniId] = comSettingsData.studentBillPayment[jurniId]
        })
      }
      coachBillPayment.value = comSettingsData.coachBillPayment ?? []
    } else {
      studentBillPayment.value = []
      coachBillPayment.value = []
    }
  }

  const loadBillingExclusion = async () => {
    if (!currentCommunity.value || !currentUser.value)
      return

    const commSettings = await getDoc(doc(currentUser.value?.userRef, 'communitySettings', currentCommunity.value?.id))
    const comSettingsData = commSettings.data()

    paymentSettingChangedExcludeBillingFrom.value = comSettingsData
      ?.paymentSettingChangedExcludeBillingFrom ?? ref({})
  }

  const loadBillingStatus = async () => {
    if (!currentCommunity.value?.id || !currentUser.value?.userRef)
      return

    const commSettings = await getDoc(doc(currentUser.value?.userRef, 'communitySettings', currentCommunity.value?.id))
    const comSettingsData = commSettings.data()
    billingStatus.value = comSettingsData?.billingStatus ? comSettingsData.billingStatus : 'Inactive'
    activePaymentMethodToken.value = comSettingsData?.activePaymentMethodToken ?? ''
    activePaymentMethodExpiry.value = comSettingsData?.activePaymentMethodExpiry ?? ''
    activePaymentMethodPostal.value = comSettingsData?.activePaymentMethodPostal ?? ''
  }

  const loadUserPaymentMethods = async () => {
    if (!currentUserId.value || !currentCommunity.value?.id)
      return

    // NOTE:x create composite index for customerBillingDetails collection,
    // uid + communityId ascending
    const userBillingDetailsSnap = await getDocs(
      query(
        collection(db, 'customerBillingDetails'),
        where('uid', '==', currentUser.value?.id),
        where('communityId', '==', currentCommunity.value?.id),
        where('status', '==', 'ACTIVE')
      )
    )
    const cards: UserPaymentMethodDetails[] = []
    userBillingDetailsSnap.forEach(async (userBillingDetailsRef) => {
      const { paymentMethods } = userBillingDetailsRef.data()

      if (currentUser.value) {
        Object.keys(paymentMethods).forEach((token) => {
          cards.push({
            token,
            meta: paymentMethods[token],
            isActive: activePaymentMethodToken.value === token
          })
        })
      }
    })

    userPaymentMethods.value = cards
  }

  const addFiservPaymentMethod = async (token: string, expiry: string, postal: string, studentOnboarding: boolean) => {
    if (!currentUserId.value || !currentCommunity.value?.id)
      return

    const data = {
      token,
      expiry,
      postal,
      uid: currentUserId.value,
      communityId: currentCommunity.value?.id,
      studentOnboarding
    }

    await addPaymentMethod(data)
      .then(async () => {
        await loadBillingStatus()
        await loadUserPaymentMethods()
      })
      .catch((err) => {
        throw err
      })
  }

  const updateFiservActivePaymentMethod = async (token: string, expiry: string, postal: string) => {
    if (!currentUserId.value || !currentCommunity.value?.id || !token || !expiry)
      return

    const userSettingsRef = doc(db, 'users', currentUserId.value, 'communitySettings', currentCommunity.value.id)
    await setDoc(userSettingsRef, {
      activePaymentMethodToken: token,
      activePaymentMethodExpiry: expiry,
      activePaymentMethodPostal: postal,
      billingStatus: 'Active'
    }, { merge: true })

    activePaymentMethodToken.value = token
    activePaymentMethodExpiry.value = expiry
    activePaymentMethodPostal.value = postal
    await loadUserPaymentMethods()
  }

  const removeFiservPaymentMethod = async (token: string) => {
    if (!currentUserId.value || !currentCommunity.value?.id)
      return

    const data = {
      uid: currentUserId.value,
      communityId: currentCommunity.value.id,
      token
    }

    const resource = await removePaymentMethod(data) as any
    activePaymentMethodToken.value = resource.data.activePaymentMethodToken ?? ''
    activePaymentMethodExpiry.value = resource.data.activePaymentMethodExpiry ?? ''
    activePaymentMethodPostal.value = resource.data.activePaymentMethodPostal ?? ''
    await loadUserPaymentMethods()
  }

  const viewPaymentFailedNotif = async (
    customerBillingDetailsRef: DocumentReference, notificationType: string
  ): Promise<void> => {
    if (!currentUserId.value)
      return

    // read all meeting event update notifications
    const notifSnaps = await getDocs(
      query(
        collection(db, 'notifications'),
        where('meta.item.ref', '==', customerBillingDetailsRef),
        where('meta.user', '==', doc(db, 'users', currentUserId.value)),
        where('notificationType', '==', notificationType)
      )
    )

    await Promise.all(notifSnaps.docs.map(async (doc) => {
      const notif = useNotification(doc.ref.id)
      if (notif.original.status === 'new')
        await notif.updateStatus('read')
    }))
  }

  watch(() => currentCommunity.value, async (val) => {
    if (val) {
      await loadBillingStatus()
      await loadUserPaymentMethods()
      await loadBillPayments()
      await loadBillingExclusion()
    }
  }, { immediate: true })

  return {
    billingStatus,
    studentBillPayment,
    coachBillPayment,
    paymentSettingChangedExcludeBillingFrom,
    loadBillPayments,
    loadBillingExclusion,
    loadBillingStatus,
    loadUserPaymentMethods,
    activePaymentMethodToken,
    activePaymentMethodExpiry,
    userPaymentMethods,
    addFiservPaymentMethod,
    updateFiservActivePaymentMethod,
    removeFiservPaymentMethod,
    viewPaymentFailedNotif
  }
})
