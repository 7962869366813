import { storeToRefs } from 'pinia'

import { useCoreStore } from '@/stores/core'

type UsePermissions = () => {
  can: (action: string, subject: string, conditions?: string[]) => boolean
}

export const usePermissions: UsePermissions = () => {
  const { permissions } = storeToRefs(useCoreStore())

  function can(action, subject) : boolean {
    const hasPermission = !!permissions.value.find((permission) =>
      permission.action === action && permission.subject === subject)
    return hasPermission
  }

  return {
    can
  }
}
