import { MeetingEvent } from '@/types'
import Moment from 'moment'
import { DateTime } from 'luxon'

interface FormattedMeetingEvent extends Omit<MeetingEvent, 'endTime' | 'startTime' | 'eventDate'> {
  endTime: string
  startTime: string
  eventDate: string
  tz: string
}

type useMeetingEvent = (event: MeetingEvent) => FormattedMeetingEvent

export const useMeetingEvent: useMeetingEvent = (event) => {
  const meetingEvent = {
    ...event,
    startTime: Moment(event.startTime.toDate()).format('LT'),
    endTime: Moment(event.endTime.toDate()).format('LT'),
    eventDate: Moment(event.startTime.toDate()).format('ll'),
    tz: DateTime.fromJSDate(event.startTime.toDate()).toFormat('ZZZZ')
  } as FormattedMeetingEvent

  return meetingEvent
}
