import { defineStore, storeToRefs } from 'pinia'
import { MeetingEvent, UpcomingEvents, Jurni, Group, User } from '@/types'
import { ref, computed, Ref } from 'vue'
import {
  collection,
  CollectionReference,
  getDocs,
  setDoc,
  QueryDocumentSnapshot,
  Timestamp,
  doc,
  WhereFilterOp,
  limit as queryLimit,
  DocumentReference,
  getDoc,
  query,
  where,
  deleteDoc,
  DocumentData,
  Query,
  Unsubscribe,
  onSnapshot,
  deleteField
} from 'firebase/firestore'
import { v4 } from 'uuid'

import { useMeetingEvent } from '@/composables/useMeetingEvent'
import { useSortedCollection } from '@/composables/utils/useSortedCollection'
import { useNotification } from '@/composables/useNotification'

import { useCoreStore } from '@/stores/core'
import { useAlertStore } from '@/stores/alert'
import { useAgoraCallStore } from '@/stores/agoraCall'

import { db } from '@/services/firebase'
import { query as agoraRecordingQuery } from '@/services/agora-recording'

export const useCalendarStore = defineStore('calendar', () => {
  const firestoreMeetingEventCollection = collection(db, 'meetingEvents') as CollectionReference<MeetingEvent>
  const meetingEvents = ref<Record<string, MeetingEvent>>({})
  const currentMeetingId = ref<string>('')
  const currentMeetingOrganizer = ref<string>()
  let meetingEventSnapshot: Unsubscribe | null = null
  const meetingEventsSnapped = ref<Record<string, MeetingEvent>>({})
  const recorders: Ref<{ [meetingEventId: string]: Record<string, string> }> = ref({})
  const joinRequestors: Ref<{ [meetingEventId: string]: DocumentReference<User>[] }> = ref({})
  const acceptedRequestors: Ref<{ [meetingEventId: string]: DocumentReference<User>[] }> = ref({})

  const { add: addAlert } = useAlertStore()

  // somehow im unable to export these from agoraCall.ts
  const RECORDING_SERVICE_NOT_STARTED = 0
  const RECORDING_SERVICE_INITIALIZED = 1
  const RECORDING_SERVICE_STARTING = 2
  const RECORDING_SERVICE_PARTIALLY_READY = 3
  const RECORDING_SERVICE_READY = 4
  const RECORDING_SERVICE_IN_PROGRESS = 5
  const RECODRING_SERVICE_REQUESTED_TO_STOP = 6
  const RECORDING_SERVICE_STOPS = 7
  const RECORDING_SERVICE_EXITS = 8
  const RECORDING_SERVICE_EXITS_ABNORMALLY = 20
  const RECORDING_STATUS_LIST_TO_STOP_START_QUERY = [
    RECORDING_SERVICE_IN_PROGRESS,
    RECODRING_SERVICE_REQUESTED_TO_STOP,
    RECORDING_SERVICE_STOPS,
    RECORDING_SERVICE_EXITS,
    RECORDING_SERVICE_EXITS_ABNORMALLY
  ]
  const RECORDING_STATUS_LIST_START_ERROR = [
    RECODRING_SERVICE_REQUESTED_TO_STOP,
    RECORDING_SERVICE_STOPS,
    RECORDING_SERVICE_EXITS,
    RECORDING_SERVICE_EXITS_ABNORMALLY
  ]

  const setCurrentMeetingId = async (meetingId: string) : Promise<void> => {
    currentMeetingId.value = meetingId
    const meetingRef = doc(db, 'meetingEvents', meetingId)
    const meetingSnap = await getDoc(meetingRef)
    if (meetingSnap.exists()) {
      const organizerRef: DocumentReference = meetingSnap.data().organizer
      const uRef = doc(db, 'users', organizerRef.id)
      const uSnap = await getDoc(uRef)
      if (uSnap.exists())
        currentMeetingOrganizer.value = uRef.id
      else
        currentMeetingOrganizer.value = undefined
    }
  }

  const updateMeetingEvent = async (data: Partial<MeetingEvent>, meetingEventId: string) => {
    if (!meetingEventId)
      return

    await setDoc(doc(collection(db, 'meetingEvents'), meetingEventId), data, { merge: true })
  }

  const getMeetingEventByMeetingLink = async (meetingLink: string) => {
    let meetingEventSnaps = await getDocs(
      query(
        collection(db, 'meetingEvents'),
        where('meetingLink', '==', meetingLink)
      )
    )

    // since meeting links are unique
    if (meetingEventSnaps.docs.length)
      return meetingEventSnaps.docs[0].id

    // means it is a recurring event link
    // removes appended -timestamp
    const arr = meetingLink.split('-')
    arr.pop()
    const processedMeetingLink = arr.join('-')

    meetingEventSnaps = await getDocs(
      query(
        collection(db, 'meetingEvents'),
        where('meetingLink', '==', processedMeetingLink)
      )
    )

    const meetingEventSnap = meetingEventSnaps.docs[0]
    return meetingEventSnap.id
  }

  const getMeetingEventNameByMeetingLink = async (meetingLink: string) => {
    let meetingEventSnaps = await getDocs(
      query(
        collection(db, 'meetingEvents'),
        where('meetingLink', '==', meetingLink)
      )
    )

    // since meeting links are unique
    if (meetingEventSnaps.docs.length) {
      const meetingEventSnap = meetingEventSnaps.docs[0]
      return meetingEventSnap.data().title
    }

    // means it is a recurring event link
    // removes appended -timestamp
    const arr = meetingLink.split('-')
    arr.pop()
    const processedMeetingLink = arr.join('-')

    meetingEventSnaps = await getDocs(
      query(
        collection(db, 'meetingEvents'),
        where('meetingLink', '==', processedMeetingLink)
      )
    )

    const meetingEventSnap = meetingEventSnaps.docs[0]
    return meetingEventSnap.data().title
  }

  const _isUserParticipant = async (meetingEvent: MeetingEvent) => {
    const { currentUser } = storeToRefs(useCoreStore())

    if (!meetingEvent.joinedMeeting || !currentUser.value)
      return false

    const mmIds = meetingEvent.joinedMeeting.map(
      (meetingMember) => meetingMember.id
    ).filter((id) => id).sort()

    return mmIds.includes(currentUser.value?.id)
  }

  const _addEventSnapToStore = async (meetingEventSnap: QueryDocumentSnapshot<MeetingEvent>) : Promise<void> => {
    const meetingEvent = meetingEventSnap.data() as MeetingEvent
    meetingEvent.id = meetingEventSnap.id
    meetingEvents.value[meetingEvent.id] = meetingEvent
  }

  const loadMeetingEventByMeetingLink = async (meetingLink: string) => {
    let meetingLinkToUse = meetingLink
    const meetingEventSnaps = await getDocs(
      query(
        collection(db, 'meetingEvents'),
        where('meetingLink', '==', meetingLink)
      )
    )

    if (!meetingEventSnaps.docs.length) {
      // means it is a recurring event link
      // removes appended -timestamp
      const arr = meetingLink.split('-')
      arr.pop()
      meetingLinkToUse = arr.join('-')
    }

    const meetingQuery: Query<MeetingEvent> | undefined = query(
      collection(db, 'meetingEvents') as CollectionReference<MeetingEvent>,
      where('meetingLink', '==', meetingLinkToUse)
    )

    const meetingEventSnapshot = await getDocs(meetingQuery)

    await Promise.all(meetingEventSnapshot.docs.map(async (doc: QueryDocumentSnapshot<MeetingEvent>) => {
      const meetingEvent = doc.data() as MeetingEvent

      if (await _isUserParticipant(meetingEvent))
        await _addEventSnapToStore(doc)
    }))
  }

  const _handleMeetingRecordingStoppedEvent = async (meetingEvent: MeetingEvent, meetingEventId: string) => {
    if (!meetingEvent.meta || !meetingEvent.meta.recordingProgress)
      return

    if (meetingEvent.meta.recordingProgress === 'stopped') {
      delete recorders.value[meetingEventId]
      addAlert({ description: 'Recording has been stopped. It might take a while to process the video. It will be added to your content library shortly.', color: 'info' })

      // so it does not show every time a change in thread is made
      await updateMeetingEvent({
        meta: {
          recordingResourceId: null,
          recordingSid: null,
          startedRecordingBy: null,
          recordingProgress: null
        }
      }, meetingEventId)
      return
    }

    // clear uncleared recording trackers
    // if something is missing, means it was broken
    const _trackers = [
      meetingEvent.meta.startedRecordingBy?.id,
      meetingEvent.meta.recordingProgress,
      meetingEvent.meta.recordingResourceId,
      meetingEvent.meta.recordingSid
    ]
    if (!_trackers.every((item) => !!item)) {
      await updateMeetingEvent({
        meta: {
          recordingResourceId: null,
          recordingSid: null,
          startedRecordingBy: null,
          recordingProgress: null
        }
      }, meetingEventId)
    }
  }

  const _handleMeetingRecordingStartedEvent = async (meetingEvent: MeetingEvent, meetingEventId: string) => {
    const { currentUser } = storeToRefs(useCoreStore())
    const { isRecorderAndRecording, recordingResourceId, recordingSid } = storeToRefs(useAgoraCallStore())

    if (!meetingEvent.meta || !meetingEvent.meta.startedRecordingBy || !meetingEvent.meta.recordingProgress)
      return

    const userSnap = await getDoc(meetingEvent.meta.startedRecordingBy as DocumentReference<User>)
    const recorderProfile = userSnap.data() as User
    let recorderData = {
      name: 'unknown',
      userId: ''
    }

    // this way even late joiners to the meeting will be notified with the recording
    if (meetingEvent.meta.startedRecordingBy?.id && meetingEvent.meta.recordingProgress === 'recording' &&
      meetingEvent.meta.recordingResourceId && meetingEvent.meta.recordingSid) {
      recorderData = {
        name: recorderProfile.firstName ?? 'unknown',
        userId: meetingEvent.meta.startedRecordingBy?.id
      }
      recorders.value[meetingEventId] = recorderData
      recordingResourceId.value = meetingEvent.meta.recordingResourceId
      recordingSid.value = meetingEvent.meta.recordingSid

      try {
        let _recordingStatus = 0
        /* eslint-disable no-await-in-loop */
        while (!RECORDING_STATUS_LIST_TO_STOP_START_QUERY.includes(_recordingStatus)) {
          _recordingStatus = await agoraRecordingQuery(meetingEvent.meta.recordingResourceId,
            meetingEvent.meta.recordingSid)
        }

        if (RECORDING_STATUS_LIST_START_ERROR.includes(_recordingStatus))
          throw new Error('recording not in progress')

        // other users' end of the recording
        if (currentUser.value?.id !== meetingEvent.meta.startedRecordingBy?.id) {
          addAlert({
            description: `This meeting is being recorded by ${recorderData.name}`,
            color: 'info'
          })
        } else {
          isRecorderAndRecording.value = true
        }
      } catch (err: any) {
        // recording not in progress anymore
        delete recorders.value[meetingEventId]
        await updateMeetingEvent({
          meta: {
            recordingResourceId: null,
            recordingSid: null,
            startedRecordingBy: null,
            recordingProgress: null
          }
        }, meetingEventId)
      }
    }
  }

  const snapShotMeetingEvent = async (meetingLink: string) => {
    const { currentUserId } = storeToRefs(useCoreStore())

    if (meetingEventSnapshot)
      meetingEventSnapshot()

    let meetingLinkToUse = meetingLink
    const meetingEventSnaps = await getDocs(
      query(
        collection(db, 'meetingEvents'),
        where('meetingLink', '==', meetingLink)
      )
    )

    if (!meetingEventSnaps.docs.length) {
      // means it is a recurring event link
      // removes appended -timestamp
      const arr = meetingLink.split('-')
      arr.pop()
      meetingLinkToUse = arr.join('-')
    }

    const meetingQuery: Query<DocumentData> | undefined = query(
      collection(db, 'meetingEvents'),
      where('meetingLink', '==', meetingLinkToUse)
    )

    meetingEventSnapshot = onSnapshot(meetingQuery, async (meetingSnap) => {
      await Promise.all(meetingSnap.docChanges().map(async (change) => {
        const meetingEventId = change.doc.id

        if (change.type === 'removed') {
          delete meetingEventsSnapped.value[meetingEventId]
          delete recorders.value[meetingEventId]
          delete joinRequestors.value[meetingEventId]
          delete acceptedRequestors.value[meetingEventId]
          return
        }

        // added or modified
        const meetingEvent = change.doc.data() as MeetingEvent
        meetingEventsSnapped.value[meetingEventId] = meetingEvent
        if (change.type === 'modified') {
          let userJustJoinedMeeting = false
          const existingMeeting = meetingEvents.value[change.doc.id]

          // if user has just joined and recording is in progress or user refreshed the page
          const updatedUserJoined = meetingEvent.joinedMeeting?.map((userRef) => userRef.id)
          // eslint-disable-next-line max-len
          const existingUserJoined = existingMeeting?.joinedMeeting ? existingMeeting.joinedMeeting?.map((userRef) => userRef.id) : []
          const newlyJoinedUsers = updatedUserJoined?.filter((item) => !existingUserJoined.includes(item))
          // eslint-disable-next-line max-len
          userJustJoinedMeeting = await _isUserParticipant(meetingEvent) && Boolean(newlyJoinedUsers?.filter((item) => item === currentUserId.value).length)
          const userNotNotifiedWithRecordingStartedYet = existingMeeting && meetingEvent.meta?.recordingProgress &&
            existingMeeting.meta?.recordingProgress !== meetingEvent.meta?.recordingProgress

          // meeting recording listeners
          if (userJustJoinedMeeting || userNotNotifiedWithRecordingStartedYet)
            await _handleMeetingRecordingStartedEvent(meetingEvent, meetingEventId)
          await _handleMeetingRecordingStoppedEvent(meetingEvent, meetingEventId)

          if (meetingEvent.joinRequestors) {
            joinRequestors.value[meetingEventId] = meetingEvent.joinRequestors

            // eslint-disable-next-line max-len
            const existingJoinRequestors = existingMeeting?.joinRequestors ? existingMeeting.joinRequestors?.map((userRef) => userRef.id) : []
            const updatedJoinRequestors = meetingEvent.joinRequestors?.map((userRef) => userRef.id)
            const areThereNewRequests: boolean = updatedJoinRequestors ? Boolean(existingJoinRequestors.length) ||
              updatedJoinRequestors.some((item) => !existingJoinRequestors.includes(item)) : false
            const { currentUserId } = storeToRefs(useCoreStore())
            const { currentMeetingOrganizer } = storeToRefs(useCalendarStore())

            if (areThereNewRequests && currentMeetingOrganizer.value === currentUserId.value)
              addAlert({ description: 'Someone is asking to join', color: 'info' })
          }

          if (meetingEvent.acceptedRequestors)
            acceptedRequestors.value[meetingEventId] = meetingEvent.acceptedRequestors

          // update meeting event item
          await _addEventSnapToStore(change.doc as QueryDocumentSnapshot<MeetingEvent>)
        }
      }))
    })
  }

  const cleanSnapshots = () => {
    meetingEventsSnapped.value = {}
    if (meetingEventSnapshot)
      meetingEventSnapshot()
    meetingEventSnapshot = null
    recorders.value = {}
    joinRequestors.value = {}
    acceptedRequestors.value = {}
  }

  const events = computed(() => {
    const { currentCommunity } = storeToRefs(useCoreStore())
    const events = ref<Record<string, MeetingEvent>>({})

    const sortedEvents = Object.values(meetingEvents.value).sort((a, b) => {
      if (!a.startTime || !b.startTime)
        return 0

      if (a.startTime.valueOf() < b.startTime.valueOf())
        return -1

      return 1
    })

    sortedEvents.forEach((event) => {
      events.value[event.id] = { ...event }
    })

    return Object.values(events.value).map((event) => {
      if (event.community && currentCommunity.value && currentCommunity.value?.id === event.community.id) {
        return {
          origStartTime: event.origStartTime,
          origEndTime: event.origEndTime,
          start: event.startTime.toDate(), // Required.
          end: event.endTime.toDate(), // Required.
          title: event.title, // Optional.
          content: event.description, // Optional.
          class: 'calendar__event', // Optional - space-separated css classes.
          background: false, // Optional. (Event type not CSS property)
          allDay: false, // Optional.
          deletable: false, // optional - force undeletable when events are editable.
          resizable: false, // optional - force unresizable when events are editable.
          meetingEvent: useMeetingEvent(event)
        }
      }
      return null
    }).filter((event) => event !== null)
  })
  function calculateDatesWithGap(startDate, endDate, gap) {
    const dates: Date[] = []
    const currentDate = new Date(startDate)

    // Loop until the current date is less than or equal to the end date
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate)) // Add the current date to the array
      currentDate.setDate(currentDate.getDate() + gap) // Move to the next date with the specified gap
    }
    return dates
  }
  const getTimeFromTimestampToLastDay = (
    timestamp: Timestamp | null,
    firstDay: Date,
    lastDay: Date,
    repeat = '',
    selectedDay: string[],
    repeatUntil: Date | undefined,
    repeatEnds: string,
    organizerTzOffset: number | 0
  ) : Array<Date> => {
    if (!timestamp)
      return []

    // convert first to organizer's timezone so we can compute selectedDay repeat based from them
    // convert to current user's timezone before adding
    const usersTzOffset = new Date().getTimezoneOffset()
    const timestampMillis = timestamp.toMillis()
    const timestampDate = new Date(timestampMillis)
    const timestampUTCDate = new Date(
      timestampDate.getUTCFullYear(),
      timestampDate.getUTCMonth(),
      timestampDate.getUTCDate(),
      timestampDate.getUTCHours(),
      timestampDate.getUTCMinutes(),
      timestampDate.getUTCSeconds()
    )
    const organizersDate = new Date(timestampUTCDate.getTime() - organizerTzOffset * 60 * 1000)

    const timeDates: Date[] = []
    let endOfRepeatUntil: Date | undefined
    if (repeatUntil) {
      endOfRepeatUntil = new Date(repeatUntil.getFullYear(),
        repeatUntil.getMonth(), repeatUntil.getDate(), 23, 59, 59, 999)
    }
    const biWeeklyDates = calculateDatesWithGap(organizersDate, lastDay, 14)

    while (organizersDate <= lastDay) {
      const timeDateTime = new Date(organizersDate)
      if (timeDateTime >= firstDay) {
        if ((repeat === 'Daily' || (repeat === 'Weekly' && selectedDay.includes(timeDateTime.getDay().toString()))) &&
        ((endOfRepeatUntil && endOfRepeatUntil >= timeDateTime) || repeatEnds === 'never')) {
          const userTimeDateTime = new Date(timeDateTime.setMinutes(
            timeDateTime.getMinutes() - usersTzOffset + organizerTzOffset
          ))
          timeDates.push(userTimeDateTime)
        } else if (repeat === 'BiWeekly' && selectedDay.includes(timeDateTime.getDay().toString()) &&
          ((endOfRepeatUntil && endOfRepeatUntil >= timeDateTime) || repeatEnds === 'never')) {
          biWeeklyDates.forEach((date) => {
            console.log(date.getDate() === timeDateTime.getDate())
            if (date.getDate() === timeDateTime.getDate()) {
              const userTimeDateTime = new Date(timeDateTime.setMinutes(
                timeDateTime.getMinutes() - usersTzOffset + organizerTzOffset
              ))
              timeDates.push(userTimeDateTime)
            }
          })
        }
      }
      organizersDate.setDate(organizersDate.getDate() + 1)
    }

    return timeDates
  }

  const _addRecurringEventToStore = async (
    meetingEventSnap: QueryDocumentSnapshot<MeetingEvent>, firstDay: Date, lastDay: Date, isUpcoming = false
  ) : Promise<void> => {
    const meetingEvent = meetingEventSnap.data() as MeetingEvent
    meetingEvent.id = meetingEventSnap.id

    if (meetingEvent.repeat === 'Once' && (meetingEvent.startTime.toDate() > lastDay || (meetingEvent.endTime && meetingEvent.endTime.toDate() < firstDay)))
      return

    const repeatUntilTimeStamp = meetingEvent.repeatUntil ? new Date(meetingEvent.repeatUntil.toDate()) : undefined
    const startTimeFromStartTimeToLastDay = getTimeFromTimestampToLastDay(
      meetingEvent.startTime,
      firstDay,
      lastDay,
      meetingEvent?.repeat,
      meetingEvent?.selectedDay || [],
      repeatUntilTimeStamp,
      meetingEvent.repeatEnds,
      meetingEvent.tzoffset ?? 0
    )
    const endTimeFromEndTimeToLastDay = getTimeFromTimestampToLastDay(
      meetingEvent.endTime || null,
      firstDay,
      lastDay,
      meetingEvent?.repeat,
      meetingEvent?.selectedDay || [],
      repeatUntilTimeStamp,
      meetingEvent.repeatEnds,
      meetingEvent.tzoffset ?? 0
    )

    const dateTimeNow = new Date()

    startTimeFromStartTimeToLastDay.forEach((startTime, index) => {
      const endTime = endTimeFromEndTimeToLastDay[index]

      // if meeting not upcoming or not on going
      if (startTime < dateTimeNow && endTime < dateTimeNow && isUpcoming)
        return

      const startTimestamp = Timestamp.fromDate(startTime)
      const endTimestamp = Timestamp.fromDate(endTime)
      const timestampUTCDate = new Date(
        startTime.getUTCFullYear(),
        startTime.getUTCMonth(),
        startTime.getUTCDate(),
        startTime.getUTCHours(),
        startTime.getUTCMinutes(),
        0,
        0
      )
      const startTimestampUTC = Timestamp.fromDate(timestampUTCDate)

      const meetingLink = meetingEvent?.meta?.videoType === 'other' ? meetingEvent.meetingLink : `${meetingEvent.meetingLink}-${startTimestamp.toMillis()}`
      const meetingEventCopy = {
        ...meetingEvent,
        origStartTime: meetingEvent.startTime,
        origEndTime: meetingEvent.endTime,
        startTime: startTimestamp,
        endTime: endTimestamp,
        id: `${meetingEvent.id}-${startTimestampUTC.toMillis()}`,
        meetingLink,
        videoType: meetingEvent.meta.videoType
      }
      meetingEvents.value[`${meetingEvent.id}-${startTimestamp.toMillis()}`] = meetingEventCopy
    })
  }

  const getParticipantMembers = async (meetingDoc: QueryDocumentSnapshot<MeetingEvent>) : Promise<Array<string>> => {
    const meetingData = meetingDoc.data()
    const participants = meetingData.participants as DocumentReference<Jurni | Group | User>[]
    const participantMembers: Array<string> = []

    // eslint-disable-next-line no-restricted-syntax
    for await (const participant of participants) {
      if (['jurnis', 'groups', 'communities'].includes(participant.parent.id)) {
        const participantRef = doc(db, `${participant.parent.id}/${participant.id}`)
        const participantSnap = await getDoc(participantRef)
        if (participantSnap.exists()) {
          const docData = participantSnap.data()
          docData.members.forEach((member) => {
            participantMembers.push(member.id)
          })
        } else {
          console.log('No such document!')
        }
      } else if (participant.parent.id === 'users') {
        participantMembers.push(participant.id)
      }
    }
    return [...new Set(participantMembers)]
  }

  const loadRecurringMeetingEventsByRange = async (firstDay: Date, lastDay: Date) : Promise<void> => {
    const { currentCommunity, currentUser } = storeToRefs(useCoreStore())
    const communityRef = doc(db, `communities/${currentCommunity.value?.id}`)

    const meetingEventsQuery = query(
      firestoreMeetingEventCollection,
      where('community', '==', communityRef),
      where('repeat', 'in', ['Daily', 'Weekly', 'BiWeekly'])
    )

    const meetingEventSnapshot = await getDocs(meetingEventsQuery)
    meetingEventSnapshot.forEach(async (doc: QueryDocumentSnapshot<MeetingEvent>) => {
      const participantMembers = await getParticipantMembers(doc)
      if (participantMembers && currentUser.value && participantMembers.includes(currentUser.value.id))
        _addRecurringEventToStore(doc, firstDay, lastDay)
    })
  }

  const loadRecurringUpcomingEvents = async (
    meetingEventsLength: number,
    limit: number,
    communityRef: DocumentReference,
    jurni: DocumentReference<Jurni> | undefined,
    member: DocumentReference<User> | undefined,
    group: DocumentReference<Group> | undefined
  ) : Promise<void> => {
    const meetingEventSchedule: Array<Record<string, any>> = []

    const conditions: {field: string, operator: WhereFilterOp, value: any}[] = [
      { field: 'community', operator: '==', value: communityRef }
    ]

    const participantsContainsAny: DocumentReference[] = []

    if (jurni)
      participantsContainsAny.push(doc(db, `jurnis/${jurni.id}`))

    if (group)
      participantsContainsAny.push(doc(db, `groups/${group.id}`))

    if (member)
      participantsContainsAny.push(doc(db, `users/${member.id}`))

    if (participantsContainsAny.length > 0) {
      conditions.push({
        field: 'participants',
        operator: 'array-contains-any',
        value: participantsContainsAny
      })
    }

    const queryConditions = conditions.map((condition) =>
      where(condition.field, condition.operator, condition.value))

    const meetingEventsQuery = query(
      firestoreMeetingEventCollection,
      ...queryConditions
    )

    const meetingEventSnapshot = await getDocs(meetingEventsQuery)
    // get first x number of docs based on remaining from limit
    // order by time (excluding date)
    await Promise.all(meetingEventSnapshot.docs.map(async (doc: QueryDocumentSnapshot<MeetingEvent>) => {
      const meetingEvent = doc.data() as MeetingEvent
      if (!meetingEvent || !meetingEvent.repeat || !['Daily', 'Weekly', 'BiWeekly'].includes(meetingEvent.repeat))
        return

      const startTimeDate = new Date(meetingEvent.startTime.toDate())
      const hours = startTimeDate.getHours()
      const minutes = startTimeDate.getMinutes()

      meetingEventSchedule.push({
        doc,
        hours,
        minutes
      })
    }))

    if (!meetingEventSchedule.length)
      return

    const sortedMeetingSchedule = useSortedCollection(
      meetingEventSchedule, ['hours', 'minutes'], ['asc', 'asc']
    ).value

    const today = new Date()
    const startToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
    const endToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
    let runCount = 0

    while (meetingEventsLength < limit) {
      if (runCount > 120)
        break

      runCount += 1
      let startTimeDate = new Date(startToday.getFullYear(), startToday.getMonth(), startToday.getDate(), 0, 0, 0)
      let endTimeDate = new Date(endToday.getFullYear(), endToday.getMonth(), endToday.getDate(), 23, 59, 59)

      for (let i = 0; i < sortedMeetingSchedule.length && meetingEventsLength < limit; i += 1) {
        const doc = sortedMeetingSchedule[i].doc
        const meetingEvent = doc.data() as MeetingEvent

        if (meetingEvent.repeat === 'Daily' || ((meetingEvent.repeat === 'Weekly' || meetingEvent.repeat === 'BiWeekly') && meetingEvent.selectedDay &&
        meetingEvent.selectedDay.includes(startTimeDate.getDay().toString()))) {
          const repeatUntilTimeStamp = meetingEvent.repeatUntil ? new Date(meetingEvent.repeatUntil.toDate())
            : undefined
          // eslint-disable-next-line no-await-in-loop
          const startTimeFromStartTimeToLastDay = await getTimeFromTimestampToLastDay(
            meetingEvent.startTime,
            startTimeDate,
            endTimeDate,
            meetingEvent?.repeat,
            meetingEvent?.selectedDay || [],
            repeatUntilTimeStamp,
            meetingEvent.repeatEnds,
            meetingEvent.tzoffset ?? 0
          )
          // eslint-disable-next-line no-await-in-loop
          const endTimeFromEndTimeToLastDay = await getTimeFromTimestampToLastDay(
            meetingEvent.endTime,
            startTimeDate,
            endTimeDate,
            meetingEvent?.repeat,
            meetingEvent?.selectedDay || [],
            repeatUntilTimeStamp,
            meetingEvent.repeatEnds,
            meetingEvent.tzoffset ?? 0
          )

          const startTime = startTimeFromStartTimeToLastDay[0]
          const endTime = endTimeFromEndTimeToLastDay[0]

          // if meeting is not on going or past
          if (startTime > today && endTime > today) {
            const meetingEventCopy = { ...meetingEvent }
            const startTimestamp = Timestamp.fromDate(startTime)
            const endTimestamp = Timestamp.fromDate(endTime)
            meetingEventCopy.startTime = startTimestamp
            meetingEventCopy.endTime = endTimestamp
            meetingEventCopy.id = `${doc.id}-${startTimestamp.toMillis()}`
            meetingEventCopy.meetingLink = `${meetingEvent.meetingLink}-${startTimestamp.toMillis()}`
            meetingEvents.value[meetingEventCopy.id] = meetingEventCopy

            meetingEventsLength += 1
          }

          if (meetingEventsLength === limit)
            break
        }
      }

      startToday.setDate(startToday.getDate() + 1)
      startTimeDate = new Date(startToday.getFullYear(), startToday.getMonth(), startToday.getDate(), 0, 0, 0)
      endToday.setDate(endToday.getDate() + 1)
      endTimeDate = new Date(endToday.getFullYear(), endToday.getMonth(), endToday.getDate(), 23, 59, 59)
    }
  }

  const loadMeetingEventsByRange = async (firstDay: Date, lastDay: Date) : Promise<void> => {
    // meetingEvents.value = {}

    const { currentCommunity, currentUser } = storeToRefs(useCoreStore())
    const firstDayTimestamp = Timestamp.fromDate(firstDay)
    const lastDayTimestamp = Timestamp.fromDate(lastDay)
    const communityRef = doc(db, `communities/${currentCommunity.value?.id}`)

    const meetingEventsQuery = query(
      firestoreMeetingEventCollection,
      where('community', '==', communityRef),
      where('startTime', '>=', firstDayTimestamp),
      where('startTime', '<=', lastDayTimestamp)
    )

    const meetingEventSnapshot = await getDocs(meetingEventsQuery)

    await Promise.all(meetingEventSnapshot.docs.map(async (doc: QueryDocumentSnapshot<MeetingEvent>) => {
      const meetingEvent = doc.data() as MeetingEvent
      if (meetingEvent.repeat !== 'Once')
        return

      const participantMembers = await getParticipantMembers(doc)
      if (participantMembers && currentUser.value && participantMembers.includes(currentUser.value.id))
        await _addEventSnapToStore(doc)
    }))

    await loadRecurringMeetingEventsByRange(firstDay, lastDay)
  }

  const loadUpcomingMeetingEvents: UpcomingEvents = async (
    { limit = 2, jurni, member, group }
  ) => {
    meetingEvents.value = {}

    const { currentCommunity } = storeToRefs(useCoreStore())
    const communityRef = doc(db, `communities/${currentCommunity.value?.id}`)
    const conditions: {field: string, operator: WhereFilterOp, value: any}[] = [
      { field: 'community', operator: '==', value: communityRef },
      { field: 'endTime', operator: '>', value: Timestamp.now() }
    ]

    const participantsContainsAny: DocumentReference[] = []

    if (jurni)
      participantsContainsAny.push(doc(db, `jurnis/${jurni.id}`))

    if (group)
      participantsContainsAny.push(doc(db, `groups/${group.id}`))

    if (member)
      participantsContainsAny.push(doc(db, `users/${member.id}`))

    if (participantsContainsAny.length > 0) {
      conditions.push({
        field: 'participants',
        operator: 'array-contains-any',
        value: participantsContainsAny
      })
    }

    const queryConditions = conditions.map((condition) =>
      where(condition.field, condition.operator, condition.value))

    const meetingEventsQuery = query(
      firestoreMeetingEventCollection,
      ...queryConditions,
      queryLimit(limit)
    )

    const meetingEventSnapshot = await getDocs(meetingEventsQuery)
    await Promise.all(meetingEventSnapshot.docs.map(async (doc: QueryDocumentSnapshot<MeetingEvent>) => {
      if (doc.data().repeat === 'Daily' || doc.data().repeat === 'Weekly')
        return

      await _addEventSnapToStore(doc)
    }))

    const meetingEventsLength = Object.keys(meetingEvents.value).length
    if (meetingEventsLength >= limit)
      return

    await loadRecurringUpcomingEvents(meetingEventsLength, limit, communityRef, jurni, member, group)
  }

  const getUpcomingEvents = () : Array<Record<string, any>> => {
    const events = ref<Record<string, MeetingEvent>>({})
    const sortedEvents = Object.values(meetingEvents.value).sort((a, b) => {
      if (!a.startTime || !b.startTime)
        return 0

      if (a.startTime.valueOf() < b.startTime.valueOf())
        return -1

      return 1
    })

    sortedEvents.forEach((event) => {
      events.value[event.id] = { ...event }
    })

    return Object.values(events.value).map((event) => ({
      start: event.startTime.toDate(), // Required.
      end: event.endTime.toDate(), // Required.
      title: event.title, // Optional.
      content: event.description, // Optional.
      class: 'calendar__event', // Optional - space-separated css classes.
      background: false, // Optional. (Event type not CSS property)
      allDay: false, // Optional.
      deletable: false, // optional - force undeletable when events are editable.
      resizable: false, // optional - force unresizable when events are editable.
      meetingEvent: useMeetingEvent(event)
    }))
  }

  const createCommunityEvent = async (values) : Promise<void> => {
    const { currentCommunity, currentUser } = storeToRefs(useCoreStore())

    if (!currentCommunity.value || !currentUser.value)
      return

    const communityRef = doc(db, 'communities', currentCommunity.value?.id)
    const newCalendarEvent: Partial<MeetingEvent> = { ...values }
    const newCalendarEventRef = doc(collection(db, 'meetingEvents'))
    newCalendarEvent.community = communityRef
    newCalendarEvent.tzoffset = currentUser.value?.profile?.tzoffset ?? new Date().getTimezoneOffset()

    await setDoc(newCalendarEventRef, newCalendarEvent)
  }

  const saveCalendarEvent = async (values: Record<string, any>) : Promise<void> => {
    const { currentCommunity, currentUser } = storeToRefs(useCoreStore())

    if (!currentCommunity.value || !currentUser.value)
      return

    const communityRef = doc(db, 'communities', currentCommunity.value?.id)

    let evntRef : DocumentReference | null = null
    const evntCollection = collection(db, 'meetingEvents')
    if (values.id)
      evntRef = doc(evntCollection, values.id.split('-')[0])
    else
      evntRef = doc(evntCollection)

    console.log(evntRef, 'evntRef value is here')
    const meta : Record<string, any> = {}
    if (!values.id)
      meta.createdAt = Timestamp.now()
    meta.updatedAt = Timestamp.now()
    meta.deletedAt = null

    const calendarData : Record<string, any> = {
      community: communityRef,
      description: values.description,
      meta,
      organizer: currentUser.value?.userRef,
      repeat: values.repeat,
      repeatEnds: values.repeatEnds,
      startTime: Timestamp.fromDate(values.eventDateStart),
      endTime: Timestamp.fromDate(values.eventDateEnd),
      title: values.title
    }

    if (values?.videoLinkType?.value !== 'jurni') {
      calendarData.meetingLink = values.videoLinkReference
      calendarData.meta.videoType = values.videoLinkType.value
    } else if (!values.id) {
      calendarData.meetingLink = v4()
      calendarData.meta.videoType = 'jurni'
    }

    if (values.repeat === 'Weekly' || values.repeat === 'BiWeekly')
      calendarData.selectedDay = values.selectedDay

    if (values.repeat !== 'Once')
      calendarData.repeatUntil = values.repeatUntil

    if (values.repeatEnds === 'after')
      calendarData.eventOccurrences = parseInt(values.eventOccurrences, 10)

    if (values.participants)
      calendarData.participants = values.participants

    // save tzoffset of organizer/editor to event
    calendarData.tzoffset = currentUser.value?.profile?.tzoffset ?? new Date().getTimezoneOffset()

    await setDoc(evntRef, calendarData, { merge: true })
  }

  const removeCalendarEvent = async (eventId: string, deleteSeries = false) : Promise<void> => {
    if (!eventId)
      return

    const calendarEventRef = doc(collection(db, 'meetingEvents'), eventId.split('-')[0])
    const calendarEventSnap = await getDoc(calendarEventRef)
    const meetingEvent = calendarEventSnap.data() as MeetingEvent
    const eventUpdates: Record<string, any> = {}
    const dbEventUpdates : Record<string, any> = {}
    const newEventUpdates: Record<string, any> = { ...meetingEvent, meetingLink: v4() }
    const dbNewEventUpdates : Record<string, any> = {}

    if (!meetingEvent)
      throw new Error('Event not found')

    if (meetingEvent.repeat !== 'Once' && !deleteSeries) {
      if (!meetingEvent.repeatUntil)
        throw new Error('Recurring event without end date')

      // Convert recurring event to multiple events
      const dateToDelete = new Date(parseInt(eventId.split('-')[1], 10))
      dateToDelete.setMinutes(dateToDelete.getMinutes() - dateToDelete.getTimezoneOffset())
      const eventStart = new Date(meetingEvent.startTime.toDate())
      const eventEnd = new Date(meetingEvent.repeatUntil.toDate() ?? meetingEvent.endTime.toDate())
      const daysBefore = (dateToDelete.getDate() - eventStart.getDate())
      const daysAfter = (eventEnd.getDate() - dateToDelete.getDate())

      // If event is on the first or last day of the series
      // Update the series start / repeatUntil date
      if (daysBefore === 0 || daysAfter === 0) {
        if (daysBefore === 0) {
          const newStart = new Date(meetingEvent.startTime.toDate())
          newStart.setFullYear(dateToDelete.getFullYear())
          newStart.setMonth(dateToDelete.getMonth())
          newStart.setDate(dateToDelete.getDate() + 1)
          const newEnd = new Date(meetingEvent.endTime.toDate())
          newEnd.setFullYear(dateToDelete.getFullYear())
          newEnd.setMonth(dateToDelete.getMonth())
          newEnd.setDate(dateToDelete.getDate() + 1)
          eventUpdates.startTime = Timestamp.fromDate(newStart)
          eventUpdates.endTime = Timestamp.fromDate(newEnd)
        } else if (daysAfter === 0) {
          const newEnd = new Date(meetingEvent.endTime.toDate())
          newEnd.setFullYear(dateToDelete.getFullYear())
          newEnd.setMonth(dateToDelete.getMonth())
          newEnd.setDate(dateToDelete.getDate() - 1)
          eventUpdates.repeatUntil = Timestamp.fromDate(newEnd)
          eventUpdates.repeatEnds = 'onDate'
        }

        // If there is only one event in the series
        // Convert the event to a one time event
        if (daysBefore + daysAfter === 1) {
          eventUpdates.repeat = 'Once'

          const newEndDate = new Date(meetingEvent.endTime.toDate())
          newEndDate.setFullYear(dateToDelete.getFullYear())
          newEndDate.setMonth(dateToDelete.getMonth())
          newEndDate.setDate(dateToDelete.getDate() - 1)
          eventUpdates.endDate = newEndDate
          // repeat Once default repeatEnds is never
          eventUpdates.repeatEnds = 'never'

          if (meetingEvent.repeatUntil) {
            delete meetingEvent.repeatUntil
            dbEventUpdates.repeatUntil = deleteField()
          }

          if (eventUpdates.repeatUntil) {
            delete eventUpdates.repeatUntil
            dbEventUpdates.repeatUntil = deleteField()
          }
        }
      } else {
        const newEventStart = new Date(meetingEvent.startTime.toDate())
        newEventStart.setFullYear(dateToDelete.getFullYear())
        newEventStart.setMonth(dateToDelete.getMonth())
        newEventStart.setDate(dateToDelete.getDate() + 1)
        newEventUpdates.startTime = Timestamp.fromDate(newEventStart)

        const newEventEnd = new Date(meetingEvent.endTime.toDate())
        newEventEnd.setFullYear(dateToDelete.getFullYear())
        newEventEnd.setMonth(dateToDelete.getMonth())
        newEventEnd.setDate(dateToDelete.getDate() + 1)
        newEventUpdates.endTime = Timestamp.fromDate(newEventEnd)

        const newEndDate = new Date(meetingEvent.endTime.toDate())
        newEndDate.setFullYear(dateToDelete.getFullYear())
        newEndDate.setMonth(dateToDelete.getMonth())
        newEndDate.setDate(dateToDelete.getDate() - 1)
        eventUpdates.repeatUntil = Timestamp.fromDate(newEndDate)
        eventUpdates.repeatEnds = 'onDate'

        if (daysBefore === 1) {
          eventUpdates.repeat = 'Once'
          const meetingEndDate = new Date(meetingEvent.endTime.toDate())
          meetingEndDate.setDate(eventUpdates.repeatUntil.toDate().getDate())
          eventUpdates.endTime = Timestamp.fromDate(meetingEndDate)
          // repeat Once default repeatEnds is never
          eventUpdates.repeatEnds = 'never'

          if (meetingEvent.repeatUntil)
            delete meetingEvent.repeatUntil

          if (eventUpdates.repeatUntil)
            delete eventUpdates.repeatUntil

          dbEventUpdates.repeatUntil = deleteField()
        }

        if (daysAfter === 1) {
          newEventUpdates.repeat = 'Once'
          if (newEventUpdates.repeatUntil)
            delete newEventUpdates.repeatUntil
          dbNewEventUpdates.repeatnUtil = deleteField()
          // repeat Once default repeatEnds is never
          eventUpdates.repeatEnds = 'never'
          newEventUpdates.repeatEnds = 'never'
        }

        await setDoc(doc(collection(db, 'meetingEvents')), { ...newEventUpdates, ...dbNewEventUpdates })
      }

      await setDoc(calendarEventRef, { ...eventUpdates, ...dbEventUpdates }, { merge: true })
    } else {
      // Delete one time event and series if deleteSeries is true
      await deleteDoc(calendarEventRef)
    }
    meetingEvents.value = {}
  }

  const viewUpdatedEventNotif = async (meetingEventRef: DocumentReference, notificationType: string): Promise<void> => {
    const { currentUser } = storeToRefs(useCoreStore())

    if (!currentUser.value?.id)
      return

    // read all meeting event update notifications
    const notifSnaps = await getDocs(
      query(
        collection(db, 'notifications'),
        where('meta.item.ref', '==', meetingEventRef),
        where('meta.user', '==', doc(db, 'users', currentUser.value?.id)),
        where('notificationType', '==', notificationType)
      )
    )

    await Promise.all(notifSnaps.docs.map(async (doc) => {
      const notif = useNotification(doc.ref.id)
      if (notif.original.status === 'new')
        await notif.updateStatus('read')
    }))
  }

  return {
    events,
    meetingEvents,
    loadUpcomingMeetingEvents,
    loadMeetingEventsByRange,
    getUpcomingEvents,
    setCurrentMeetingId,
    createCommunityEvent,
    saveCalendarEvent,
    removeCalendarEvent,
    currentMeetingId,
    currentMeetingOrganizer,
    getMeetingEventByMeetingLink,
    getMeetingEventNameByMeetingLink,
    loadMeetingEventByMeetingLink,
    snapShotMeetingEvent,
    cleanSnapshots,
    updateMeetingEvent,
    recorders,
    joinRequestors,
    acceptedRequestors,
    viewUpdatedEventNotif
  }
})
