import { ref, Ref } from 'vue'
import { getDocs, query, collection, where, DocumentReference, doc, getDoc, setDoc, deleteField } from 'firebase/firestore'
import { db } from '@/services/firebase'
import { Group, Jurni } from '@/types'

export const useJurniActions = () : Record<string, CallableFunction> => {
  const archive = async (jurniId: string) : Promise<boolean> => {
    const jurniRef = doc(db, 'jurnis', jurniId)
    const jurniSnap = await getDoc(jurniRef)
    const jurniData = jurniSnap.data()
    const isArchived : Record<string, any> = {}

    if (!jurniData)
      return false

    if (!jurniData?.meta?.isTemplate)
      isArchived.isArchived = true
    else
      isArchived.isArchivedFor = { [jurniData.community.id]: true }

    await setDoc(jurniRef, { meta: { ...isArchived, order: deleteField() } }, { merge: true })
    return true
  }

  const unarchive = async (jurniId: string, order: number) : Promise<boolean> => {
    const jurniRef = doc(db, 'jurnis', jurniId)
    const jurniSnap = await getDoc(jurniRef)
    const jurniData = jurniSnap.data()
    const isArchived : Record<string, any> = {}

    if (!jurniData)
      return false

    if (!jurniData?.meta?.isTemplate)
      isArchived.isArchived = false
    else
      isArchived.isArchivedFor = { [jurniData.community.id]: false }

    await setDoc(jurniRef, { meta: { ...isArchived, order } }, { merge: true })
    return true
  }

  const duplicate = async (jurniId: string) : Promise<string | false> => false

  const create = async (
    jurniData: Record<string, any> | null,
    coachRef: DocumentReference,
    communityRef: DocumentReference,
    order: number
  ) : Promise<string|false> => {
    if (!jurniData) {
      jurniData = {
        name: 'Create ȷurnı Title',
        description: 'Create ȷurnı Description'
      }
    }

    if (coachRef && communityRef) {
      const newJurniRef = doc(collection(db, 'jurnis'))
      const newJurni: Partial<Jurni> = { ...jurniData }
      newJurni.coach = coachRef
      newJurni.community = communityRef
      newJurni.members = [coachRef]
      newJurni.meta = { isArchived: false, order, jurniAdminMode: true }

      // create new jurni
      await setDoc(newJurniRef, newJurni)
      const newJurniSnapshot = await getDoc(newJurniRef)

      // create special card
      const specialCard: Record<string, any> = {
        id: 100000,
        title: 'Congrats!',
        description: 'Congrats!',
        type: 'special'
      }
      const newCardRef = doc(collection(db, `jurnis/${newJurniSnapshot.id}/gameboardCards`))
      await setDoc(newCardRef, specialCard)

      return newJurniSnapshot.id
    }
    return false
  }

  const remove = async (jurniId: string) : Promise<boolean> => {
    const jurniRef = doc(db, 'jurnis', jurniId)
    try {
      await setDoc(jurniRef, { meta: { isDeleted: true } }, { merge: true })
    } catch (e) {
      console.error(e)
      return false
    }
    return true
  }

  const saveOrder = async (jurnis: Array<Record<string, any>>) : Promise<boolean> => {
    try {
      await Promise.all(jurnis.map(async (jurni, index) => {
        if (!jurni.id)
          return

        const jurniRef = doc(db, 'jurnis', jurni.id)
        await setDoc(jurniRef, { meta: { order: index } }, { merge: true })
        jurni.meta.order = index
      }))
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const addBanner = async (jurniId: string, contentRef: DocumentReference) : Promise<boolean> => {
    const jurniRef = doc(db, 'jurnis', jurniId)
    try {
      await setDoc(jurniRef, { bannerItem: contentRef }, { merge: true })
    } catch (e) {
      console.error(e)
      return false
    }
    return true
  }

  const removeBanner = async (jurniId: string) : Promise<boolean> => {
    const jurniRef = doc(db, 'jurnis', jurniId)
    try {
      await setDoc(jurniRef, { banner: deleteField(), bannerItem: deleteField() }, { merge: true })
    } catch (e) {
      console.error(e)
      return false
    }
    return true
  }

  const getAssociatedGroups = async (jurniId: string) : Promise<Ref<Array<Group>>> => {
    const groupQuerySnapshot = await getDocs(
      query(
        collection(db, 'groups'),
        where('meta.associateJurnies', 'array-contains', jurniId)
      )
    )

    const assoicatedGroups = ref<Array<Group>>([])
    await Promise.all(groupQuerySnapshot.docs.map(async (groupSnap) => {
      const group = groupSnap.data() as Group
      assoicatedGroups.value.push(group)
    }))

    return assoicatedGroups
  }

  const totalMembers = (jurni: Jurni) : number => {
    const members = jurni.members.length
    const inactive = jurni.inactiveMembers?.length ?? 0
    return members + inactive
  }

  return {
    archive,
    unarchive,
    duplicate,
    create,
    remove,
    saveOrder,
    addBanner,
    removeBanner,
    getAssociatedGroups,
    totalMembers
  }
}
