import { RouterView } from 'vue-router'
import Campaign from '@/views/campaigns/index.vue'
import CampaignEditor from '@/views/campaigns/editor.vue'
import ArchivedDripCampaign from '@/views/campaigns/archived.vue'

export default [
  {
    path: '/campaigns',
    name: 'campaign',
    component: RouterView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'campaign.index',
        component: Campaign,
        meta: {
          requiresAuth: true,
          requiresPermission: {
            action: 'manage',
            subject: 'community'
          }
        }
      },
      {
        path: 'create',
        name: 'campaign.create',
        props: { id: null },
        component: CampaignEditor,
        meta: { requiresAuth: true }
      },
      {
        path: ':id',
        name: 'campaign.edit',
        props: true,
        component: CampaignEditor,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/archived-drip-campaign',
    name: 'archived-drip-campaign',
    component: ArchivedDripCampaign,
    meta: {
      requiresAuth: true,
      requiresPermission: {
        action: 'manage',
        subject: 'community'
      }
    }
  }
]
