import type { Ref, UnwrapRef } from 'vue'
import { computed, reactive, ref } from 'vue'

import type { Thread } from '@/types'

import { useThread } from '@/composables/useThread'

type UseThreads = (threads: Ref<Array<Thread | string>> | Array<Thread | string>) => {
  threads: Ref<UnwrapRef<ReturnType<typeof useThread>[]>>
}

export const useThreads: UseThreads = (threadsOrIds) => {
  const list = ref(threadsOrIds)

  const threads = computed(() => (
    list.value.map((idOrThread) => {
      if (typeof idOrThread === 'string')
        return reactive(useThread(idOrThread))

      return reactive(useThread(idOrThread.thread_id))
    })
  ))

  return { threads }
}
