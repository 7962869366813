<template>
  <div class="body">
    <slot name="content"></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'CardBody'
  })
</script>

<style scoped>

</style>
