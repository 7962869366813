import { RouterView } from 'vue-router'

import Jitsi from '@/views/meet/jitsi.vue'

export default [
  {
    path: '/room',
    name: 'jitsi',
    component: RouterView,
    meta: {
      requiresrequiresAuth: true,
      layout: 'Default'
    },
    children: [
      {
        path: ':roomId',
        name: 'jitsi.room',
        props: true,
        component: Jitsi,
        meta: { requiresAuth: true }
      }
    ]
  }
]
