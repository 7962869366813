import type { Ref } from 'vue'
import type { MaybeRef } from '@vueuse/core'

import type { ChatMessage } from '@/types'
import moment from 'moment'

import { computed, ref } from 'vue'
// eslint-disable-next-line import/no-cycle
import { useThreadStore } from '@/stores/thread'

type UseMessage = (threadId?: MaybeRef<string>, messageId?: MaybeRef<string>) => {
  altText: Ref<string>
  message: Ref<ChatMessage | null>
}

export const useMessage: UseMessage = (maybeThreadId, maybeMessageId) => {
  const threadStore = useThreadStore()

  const threadId = ref(maybeThreadId)
  const messageId = ref(maybeMessageId)

  const messageRef = computed(() => {
    if (!threadId.value || !messageId.value)
      return null

    const message = threadStore.getThreadMessageById(threadId.value, messageId.value)
    if (message) {
      const msgDate = message.timestamp?.toDate()
      const formattedTimestamp = moment(msgDate).fromNow()
      if (formattedTimestamp !== 'Invalid date')
        message.formattedTimestamp = formattedTimestamp
    }

    return message
  }) as Ref

  const altText = computed(() => {
    if (!messageRef.value)
      return ''

    if (messageRef.value.type === 'text')
      return messageRef.value.meta.content

    if (messageRef.value.type === 'image')
      return 'An image'

    return ''
  }) as Ref

  return { altText, message: messageRef }
}
