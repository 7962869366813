import { Ref, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

type RecorderState = { disabled: boolean, error: boolean, recording: boolean }
export type RecorderCompletionCallback = (recording: File, duration: number) => any

interface MediaRecorderHelper {
  record: (completionCallback: RecorderCompletionCallback, limit: number) => void
  finish: () => void
  state: Ref<RecorderState>
}

const defaultAudioMimeType = () : string => {
  if (!MediaRecorder.isTypeSupported)
    throw new Error('MediaRecorder API not available')

  const supportsMp4 = MediaRecorder.isTypeSupported('audio/mp4') // ios
  const supportsOgg = MediaRecorder.isTypeSupported('audio/ogg') // firefox
  const supportsWebm = MediaRecorder.isTypeSupported('audio/webm') // chrome
  const supportsWav = MediaRecorder.isTypeSupported('audio/wav') // safari

  if (supportsMp4)
    return 'audio/mp4'
  if (supportsOgg)
    return 'audio/ogg'
  if (supportsWebm)
    return 'audio/webm'
  if (supportsWav)
    return 'audio/wav'
  throw new Error('No supported mime type found')
}

export const useMediaRecorder = (): MediaRecorderHelper => {
  let recorder : MediaRecorder|null = null
  const recorderState: Ref<RecorderState> = ref({
    disabled: false,
    error: false,
    recording: false
  })

  const startRecording: MediaRecorderHelper['record'] = (completionCallback, limit) => {
    recorder = null
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      recorder = new MediaRecorder(stream, { mimeType: defaultAudioMimeType() })

      if (recorderState.value.disabled || recorderState.value.error)
        return

      const startTime = new Date().getTime()
      recorder.addEventListener('dataavailable', (event) => {
        const duration = new Date().getTime() - startTime
        const file = new File(
          [event.data],
          uuidv4(),
          { type: defaultAudioMimeType() }
        )

        completionCallback(file, duration)
      })

      recorderState.value.recording = true
      recorder.start()
    })
  }

  const endRecording = () : void => {
    recorderState.value.recording = false
    recorder?.stop()
    recorder = null
  }

  return {
    record: startRecording,
    finish: endRecording,
    state: recorderState
  }
}
