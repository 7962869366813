import { ref } from 'vue'
import type { MaybeRef } from '@vueuse/core'
import { useDebounce } from '@vueuse/core'
import axios from 'axios'
import { removeStopwords, eng } from 'stopword'
import { search } from 'ss-search'

/* eslint-disable eol-last */
async function runFunc(text: string): Promise<string[] | null> {
  try {
    const response = await axios.post('https://api.sapling.ai/api/v1/paraphrase', {
      key: process.env.VUE_APP_SAPLING_ID,
      text
      // mapping:'informal_to_formal'
    })
    const res: any[] = []
    response.data.results
      .slice(0, 2)
      .forEach((item: { replacement: { split: (arg0: string) => string } }) => res.push(item.replacement.split(' ')))
    const customStopwords = ['interesting', 'really', 'its', 'involves', 'learning', 'process', 'common', 'across', 'entire', 'refers', 'used', 'accessed', 'need', 'passing']
    const result = res.map((it) => removeStopwords(it, [...eng, ...customStopwords]))
    const results = result.map((res) => res.map((it) => it.replace('.', '')))
    return Array.from(new Set(results[0].concat(results[1])))
  } catch (err: any) {
    const { msg } = err.response.data
    console.log({ err: msg })
  }
  return null
}

export const useSmartSearch = async (maybeRef: MaybeRef<Record<string, any>[]>, keys: string[], newSearch: string) => {
  const collection = ref(maybeRef)
  const query = ref(newSearch)
  const queryDebounced = useDebounce(query, 100)

  const final: Record<string, any>[] = []
  if (queryDebounced.value.length > 0) {
    const data = await runFunc(queryDebounced.value)
    data?.push(queryDebounced.value)
    const items = data?.map((it) => (it !== '' ? search(collection.value || [], keys, it ?? '') : []))
    // eslint-disable-next-line
    collection.value.map((val) => val.name.split('.').map((it) => it.trim().split(' ').map((i) => i.toLowerCase() + "s" === queryDebounced.value.toLowerCase() ? final.push(val) : null)))
    items?.map((it) => (it.length > 0 ? it.map((i) => final.push(i)) : null))
  }

  return {
    query: queryDebounced,
    matches: queryDebounced.value.length === 0
      ? collection.value
      : [...new Set(final?.map((item: any) => item))]
  }
}