import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()

interface fetchTokenReqInterface {
  channel: string,
  uid: string,
  role: boolean
}

interface fetchTokenResInterface {
  rtcToken: string,
  rtmToken: string
}

export const fetchToken = async (channel: string, uid: string, role: boolean) => {
  const fetchTokenFunc = httpsCallable<fetchTokenReqInterface, fetchTokenResInterface>(functions, 'generateToken')

  const data = {
    channel,
    uid: `${uid}`,
    role
  }

  try {
    const response = await fetchTokenFunc(data)
    return response.data
  } catch (err) {
    throw new Error('error generating call token')
  }
}

export default {
  fetchToken
}
