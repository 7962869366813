import { defineStore, storeToRefs } from 'pinia'
import {
  doc,
  collection,
  Timestamp,
  setDoc
} from 'firebase/firestore'
import { db } from '@/services/firebase'

import { useCalendarStore } from '@/stores/calendar'
import { useCoreStore } from '@/stores/core'
import { fetchToken } from '@/services/jitsi-call'

import { RecordingsQueueItem } from '@/types/recordings'

export const useJitsiCallStore = defineStore('jitsiCall', () => {
  const { currentMeetingOrganizer } = storeToRefs(useCalendarStore())
  const { currentUser, currentUserId, currentCommunity } = storeToRefs(useCoreStore())

  const getToken = async (isThreads: boolean) => {
    if (!currentMeetingOrganizer.value && !isThreads)
      throw new Error('no meeting organizer')

    if (!currentUser.value || !currentUserId.value)
      throw new Error('no user found')

    const name = `${currentUser.value?.profile?.firstName} ${currentUser.value?.profile?.lastName}`
    const email = `${currentUser.value?.profile?.email}`
    const avatar = currentUser.value?.profile?.avatar ?? `https://ui-avatars.com/api/?
    background=random&color=random&name=${name}`
    // sets all participants in threads call to moderator
    const moderator = isThreads || currentMeetingOrganizer.value === currentUserId.value

    const res = await fetchToken(currentUserId.value, name, email, avatar, moderator)
      .catch((err: any) => {
        throw err
      })

    return res.token
  }

  const createRecordingsQueue = async (sessionId: string, roomId: string) => {
    if (!currentUserId.value)
      throw new Error('no user found')

    if (!currentCommunity.value)
      throw new Error('no community found')

    const newRecordingsQueueRef = doc(collection(db, 'recordingsQueue'))
    // set isProcessed to true first so we can upload the recording to storage then
    // process it to content library
    const newRecordingsQueueData: RecordingsQueueItem = {
      id: newRecordingsQueueRef.id,
      communityId: currentCommunity.value?.id,
      authorId: currentUserId.value,
      isProcessed: true,
      createdAt: Timestamp.now(),
      roomId,
      jitsi: {
        recordingSessionId: sessionId
      }
    }

    await setDoc(newRecordingsQueueRef, newRecordingsQueueData)
  }

  return {
    getToken,
    createRecordingsQueue
  }
})
