import { storeToRefs } from 'pinia'
import { computed } from 'vue'

import { useThreadStore } from '@/stores/thread'

import { useThread } from '@/composables/useThread'

type UseActiveThread = () => ReturnType<typeof useThread>

export const useActiveThread: UseActiveThread = () => {
  const threadStore = useThreadStore()
  const { activeThread } = storeToRefs(threadStore)

  const threadId = computed(() => activeThread.value?.thread_id)

  return useThread(threadId)
}
