import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()

interface acquireReqInterface {
  cname: string
  uid: string
}

interface acquireResInterface {
  resourceId: string
}

interface startReqInterface {
  cname: string
  uid: string
  rtcToken: string
  resourceId: string
  communityId: string
}

interface startResInterface {
  resourceId: string
  sid: string
}

interface queryReqInterface {
  resourceId: string
  sid: string
}

interface queryResInterface {
  serverResponse: {
    status: number
  }
}

interface stopReqInterface {
  cname: string
  uid: string
  sid: string
  resourceId: string
  communityId: string
  roomId: string
}

interface stopResInterface {
  resourceId: string;
}

const clientId = '987654321'

export const acquire = async (channelName, uid = clientId) => {
  const acquireFunc = httpsCallable<acquireReqInterface, acquireResInterface>(functions, 'videoRecordingAcquire')

  const data = {
    cname: channelName,
    uid
  }

  try {
    const resource = await acquireFunc(data)

    return resource.data.resourceId
  } catch (err) {
    throw new Error('acquire err')
  }
}

export const start = async (channelName, resourceId, rtcToken, communityId, uid = clientId) : Promise<string> => {
  const startFunc = httpsCallable<startReqInterface, startResInterface>(functions, 'videoRecordingStart')

  const data = {
    cname: channelName,
    uid,
    rtcToken,
    resourceId,
    communityId
  }

  try {
    const response = await startFunc(data)
    return response.data.sid
  } catch (err) {
    throw new Error('start err')
  }
}

export const query = async (resourceId, sid) : Promise<number> => {
  const queryFunc = httpsCallable<queryReqInterface, queryResInterface>(functions, 'videoRecordingQuery')

  const data = {
    resourceId,
    sid
  }

  /*
    status: Number. The status of the cloud service.
    0: The cloud service has not started.
    1: The initialization is complete.
    2: The cloud service is starting.
    3: The cloud service is partially ready.
    4: The cloud service is ready.
    5: The cloud service is in progress.
    6: The cloud service receives the request to stop.
    7: The cloud service stops.
    8: The cloud service exits.
    20: The cloud service exits abnormally.
  */
  try {
    const response = await queryFunc(data)
    return response.data.serverResponse.status
  } catch (err) {
    throw new Error('recording did not start')
  }
}

export const stop = async (channelName, resourceId, sid, communityId, uid = clientId, roomId = '') => {
  const stopFunc = httpsCallable<stopReqInterface, stopResInterface>(functions, 'videoRecordingStop')

  const data = {
    cname: channelName,
    uid,
    resourceId,
    sid,
    communityId,
    roomId
  }

  try {
    const response = await stopFunc(data)
    return response
  } catch (err) {
    throw new Error('stop err')
  }
}

export default {
  acquire,
  start,
  query,
  stop
}
