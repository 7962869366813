import { storeToRefs } from 'pinia'
import {
  createRouter,
  createWebHistory
} from 'vue-router'

import { routes } from '@/router/routes'

import { useCoreStore } from '@/stores/core'
import { useSpinnerStore } from '@/stores/spinner'
import { useJurniStore } from '@/stores/jurni'
import { useAlertStore } from '@/stores/alert'
import { usePermissions } from '@/composables/usePermissions'
import { useFirebaseAuth } from '@/composables/useFirebaseAuth'
import { routeInactiveCommunity } from './guards/account'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const { logout } = useFirebaseAuth()
  const { currentUser, isCommunityActive, awaitCurrentCommunity } = storeToRefs(useCoreStore())
  const { getCurrentUserAuth, setMember } = useCoreStore()
  const { canAccessJurni } = useJurniStore()
  const { can } = usePermissions()
  const { add } = useAlertStore()

  // Check user is authenticated
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresUnauth = to.matched.some((record) => record.meta.requiresUnauth)
  const requiresPermission = to.matched.some((record) => record.meta.requiresPermission)

  useSpinnerStore().start()
  const currentUserAuth = await getCurrentUserAuth()
  if (currentUserAuth && !currentUser.value) {
    await setMember(currentUserAuth)
    await awaitCurrentCommunity.value
  }

  // console.log('to.name', to.name, 'from.name', from.name)
  if (requiresAuth && !currentUserAuth) {
    next({ name: 'logout' })
  } else if (requiresUnauth && currentUserAuth) {
    next({ name: 'home' })
  } else if (to.name === 'logout') {
    await logout()
    next({ name: 'login' })
  } else if (requiresPermission) {
    const action = to.meta.requiresPermission?.action
    const subject = to.meta.requiresPermission?.subject

    if (!action || !subject) {
      next({ name: 'home' })
      return
    }

    if (can(action, subject))
      next()
    else
      next({ name: 'home' })
  } else if (currentUserAuth && !isCommunityActive.value) {
    // Check payment access to community
    await routeInactiveCommunity(to, from, next)
  } else if (currentUserAuth && to.name?.toString().includes('jurni') && to.params.jurniId) {
    // Check payment access to (jurni) page
    const canProceed = await canAccessJurni(currentUserAuth?.uid ?? '', to.params.jurniId as string)
    if (!canProceed && !to.name.toString().includes('onboarding')) {
      add({ description: 'Entering a paid jurni. Please review your billing details or contact your coach', color: 'danger' })
      next({ name: 'settings.payments' })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  useSpinnerStore().done()
})

export default router
