// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Vue3Mq } from 'vue3-mq'

export type Breakpoints = {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  xxl?: number
}

interface Options {
  breakpoints?: Breakpoints,
  preset?: string
}

export const options: Options = {
  // preset: 'tailwind',
  // define breakpoints same as tailwind preset for easy reference
  breakpoints: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536
  }
}

export const Vuemq : any = Vue3Mq
