import type { Ref } from 'vue'
import { computed, ref } from 'vue'

// eslint-disable-next-line import/no-cycle
import { useCoreStore } from '@/stores/core'
import { User } from '@/types'

type UseMember = (id: Ref<string | undefined> | string) => {
  user: Ref<User | null>
  name: Ref<string>
}

export const useMember: UseMember = (id) => {
  const userId = ref(id)

  const coreStore = useCoreStore()
  const user = computed(() => {
    if (userId.value === undefined)
      return null

    coreStore.loadMember(userId.value)
    const member = coreStore.getCommunityMembers.find((member) => member.id === userId.value)
    if (userId.value === `${process.env.VUE_APP_CHAT_SUPPORT_USER_ID}`) {
      const supportChatUser = coreStore.getMemberById(`${process.env.VUE_APP_CHAT_SUPPORT_USER_ID}`)
      if (supportChatUser)
        return supportChatUser.profile
    }

    if (!member)
      return null
    return member.profile
  }) as Ref<User | null>

  const name = computed(() => {
    if (!user.value)
      return ''

    if (user.value.meta?.isBot)
      return user.value.meta.name

    if (user.value.preferredName)
      return user.value.preferredName ?? ''

    if (user.value.firstName || user.value.middleInitial || user.value.lastName) {
      return [
        user.value?.firstName,
        ...(user.value?.middleInitial ? [user.value?.middleInitial] : []),
        user.value?.lastName
      ].join(' ')
    }

    return user.value.email
  }) as Ref

  return {
    name,
    user
  }
}
