import { RouterView } from 'vue-router'

import { resumeOnboarding, routeOnboarding } from '@/router/guards'
import { fallbackToFirstChapter, fallbackToFirstStep } from '@/router/guards/jurni'
import JurniGameboard from '@/views/jurnis/_id/gameboard.vue'
import JurniMembers from '@/views/jurnis/_id/members.vue'
import JurniInvitations from '@/views/jurnis/_id/invitations.vue'
import JurniOnboardingSettings from '@/views/jurnis/_id/onboarding_settings.vue'
import JurniOnboardingResponses from '@/views/jurnis/_id/onboarding_responses.vue'
import JurniPaymentSettings from '@/views/jurnis/_id/payment_settings.vue'
import Onboarding from '@/views/onboarding/index.vue'
import OnboardingStudentPayment from '@/views/onboarding/student_payment.vue'
import OnboardingSteps from '@/views/onboarding/steps.vue'
import JurniStep from '@/views/jurnis/_id/steps/_id/index.vue'
import JurniChapter from '@/views/jurnis/_id/steps/_id/chapters/_id/index.vue'
import JurniChapterSidebar from '@/views/jurnis/_id/steps/_id/chapters/_id/sidebar.vue'
import JurniHomework from '@/views/jurnis/_id/homeworks/_id/index.vue'
import Jurnis from '@/views/jurnis/index.vue'
import ArchivedJurnis from '@/views/jurnis/archived.vue'

export default [
  {
    path: '/jurnis',
    name: 'jurnis',
    component: Jurnis,
    meta: { requiresAuth: true }
  },
  {
    path: '/archived-jurnis',
    name: 'archived-jurnis',
    component: ArchivedJurnis,
    meta: {
      requiresAuth: true,
      requiresPermission: {
        action: 'manage',
        subject: 'community'
      }
    }
  },
  {
    path: '/jurnis/:jurniId',
    name: 'jurni',
    component: RouterView,
    props: true,
    meta: { requiresAuth: true },
    redirect: (to) : any => ({
      name: 'jurni.gameboard',
      params: { jurniId: to.params.jurniId }
    }),
    children: [
      {
        path: 'gameboard',
        name: 'jurni.gameboard',
        props: true,
        component: JurniGameboard,
        beforeEnter: resumeOnboarding,
        meta: { requiresAuth: true }
      },
      {
        path: 'members',
        name: 'jurni.members',
        props: true,
        component: JurniMembers,
        meta: { requiresAuth: true }
      },
      {
        path: 'invitations',
        name: 'jurni.invitations',
        props: true,
        component: JurniInvitations,
        meta: { requiresAuth: true }
      },
      {
        path: 'onboarding-settings',
        name: 'jurni.onboarding-settings',
        props: true,
        component: JurniOnboardingSettings,
        meta: { requiresAuth: true }
      },
      {
        path: 'onboarding-responses',
        name: 'jurni.onboarding-responses',
        props: true,
        component: JurniOnboardingResponses,
        meta: { requiresAuth: true }
      },
      {
        path: 'payment-settings',
        name: 'jurni.payment-settings',
        props: true,
        component: JurniPaymentSettings,
        meta: { requiresAuth: true }
      },
      {
        name: 'jurni.onboarding',
        path: 'onboarding',
        component: Onboarding,
        props: true,
        beforeEnter: routeOnboarding,
        meta: {
          layout: 'Default',
          requiresAuth: true
        },
        children: [
          {
            path: 'student-payment',
            alias: '',
            name: 'jurni.onboarding.student-payment',
            props: true,
            component: OnboardingStudentPayment
          },
          {
            path: 'steps',
            alias: '',
            name: 'jurni.onboarding.steps',
            props: true,
            component: OnboardingSteps
          }
        ]
      },
      {
        path: 'steps',
        name: 'jurni.steps',
        props: (route) : Record<string, string | string[]> => ({
          jurniId: route.params.jurniId,
          stepId: route.params.stepId
        }),
        beforeEnter: fallbackToFirstStep,
        meta: { requiresAuth: true },
        component: RouterView,
        children: [
          {
            path: ':stepId',
            name: 'jurni.step',
            props: true,
            beforeEnter: fallbackToFirstChapter,
            components: {
              default: JurniStep
            },
            children: [
              {
                path: 'chapters',
                redirect: (to) : any => ({
                  name: 'jurni.step',
                  params: { jurniId: to.params.jurniId, stepId: to.params.stepId }
                })
              },
              {
                name: 'jurni.step.chapter',
                path: 'chapters/:chapterId',
                props: true,
                components: {
                  content: JurniChapter,
                  sidebar: JurniChapterSidebar
                }
              }
            ]
          }
        ]
      },
      {
        path: 'homeworks',
        name: 'jurni.homeworks',
        props: true,
        meta: { requiresAuth: true },
        component: RouterView,
        children: [
          {
            path: ':homeworkId',
            name: 'jurni.homework',
            props: true,
            meta: { requiresAuth: true },
            component: JurniHomework
          }
        ]
      }
    ]
  }
]
