import { Timestamp } from 'firebase/firestore'
import { storeToRefs } from 'pinia'
import { useJurniStore } from '@/stores/jurni'
import { useCoreStore } from '@/stores/core'
import { useBillingStore } from '@/stores/billing'
import { computed, ref } from 'vue'

import { useTimestamp } from '@/composables/utils/useTimestamp'

export const useBilling = () => {
  const isStudent = (student) => {
    const { currentCommunity } = storeToRefs(useCoreStore())

    return currentCommunity.value?.coach.id !== student.id &&
      !['admin', 'coach', 'coachadmin', 'coachmoderator', 'superadmin'].includes(currentCommunity.value?.roles[student.id])
  }

  const isStudentIn30DayTrial = (student, jurniId: string, dateNow: Timestamp) => {
    const { trialActivatedJurnis } = storeToRefs(useJurniStore())

    if (!trialActivatedJurnis.value.includes(jurniId))
      return false

    if (student.joined) {
      const userJoinedDate = new Date(student.joined)
      const userLastActivityDay = Timestamp.fromDate(userJoinedDate)
      const timeDiff = dateNow.toDate().getTime() - userLastActivityDay?.toDate().getTime()
      const daysDiff = Math.floor(timeDiff / 1000 / 60 / 60 / 24)

      if (daysDiff <= 30)
        return true
    }

    return false
  }

  const isStudentInPaidJurni = (student) => {
    const { paidJurnis } = storeToRefs(useJurniStore())

    const studentJurniIds = student.jurnis.map((jurni) => jurni.id)
    const dateNow = Timestamp.now()

    return studentJurniIds.some((jurniId) => paidJurnis.value.indexOf(jurniId) >= 0 &&
      !isStudentIn30DayTrial(student, jurniId, dateNow))
  }

  const isBillable = (student) => isStudent(student) && isStudentInPaidJurni(student)

  const isInactive = (student) => !student.isActiveLast30Days

  const calculateCoachCost = () => {
    const { getCommunityMembers: communityStudents, currentCommunity } = storeToRefs(useCoreStore())
    const billableStudents = ref(new Set())
    const nonBillableStudents = ref(new Set())
    const inactiveStudents = ref(new Set())
    const date = new Date()

    communityStudents.value.map((student) => {
      if (!isStudent(student))
        return null

      if (!inactiveStudents.value.has(student) && isInactive(student))
        inactiveStudents.value.add(student)
      else if (!billableStudents.value.has(student) && isBillable(student))
        billableStudents.value.add(student)
      else if (!nonBillableStudents.value.has(student) && !isBillable(student))
        nonBillableStudents.value.add(student)
      return null
    })

    return {
      name: currentCommunity.value?.name ?? '',
      students: Array.from(billableStudents.value).length,
      freeStudents: Array.from(nonBillableStudents.value).length,
      inactiveStudents: Array.from(inactiveStudents.value).length,
      cost: Array.from(billableStudents.value).length * 5,
      billingDate: useTimestamp(Timestamp.fromDate(new Date(date.getFullYear(), date.getMonth() + 1, 1)))
    }
  }

  const coachLatestPayment = () => {
    const { currentCommunity } = storeToRefs(useCoreStore())
    const { coachBillPayment } = storeToRefs(useBillingStore())

    const coachBillsPayment: Array<Record<string, any>> = []
    const currentCoachBillPayment = coachBillPayment.value ?? []
    let latestCoachBillingDate = 'Subscribed '
    let latestCoachBillingCost = 0

    if (currentCoachBillPayment.length > 0) {
      // eslint-disable-next-line max-len
      const latestCoachBilling = currentCoachBillPayment.reduce((a, b) => (useTimestamp(a.paidOn) > useTimestamp(b.paidOn) ? a : b))
      latestCoachBillingCost = latestCoachBilling.cost
      latestCoachBillingDate = useTimestamp(latestCoachBilling.paidOn)

      coachBillsPayment.push({
        name: currentCommunity.value?.name ?? '',
        coachPaymentCost: latestCoachBillingCost ?? 0,
        transactionDate: latestCoachBillingDate ?? '-'
      })
    }

    return coachBillsPayment
  }

  const coachPayments = () => {
    const { currentCommunity } = storeToRefs(useCoreStore())
    const { coachBillPayment } = storeToRefs(useBillingStore())

    const payments: Array<Record<string, any>> = []
    const currentCoachBillPayment = coachBillPayment.value ?? []

    if (currentCoachBillPayment.length === 0)
      return payments

    currentCoachBillPayment.forEach((item) => {
      payments.push(
        {
          name: currentCommunity.value?.name ?? '',
          charged: item.cost / 5,
          cost: item.cost,
          date: item.isPaid ? useTimestamp(item.paidOn) : 'not paid'
        }
      )
    })

    return payments
  }

  const studentCommunityJurnis = () => {
    const { getCurrentUserJurnis } = storeToRefs(useJurniStore())
    const { currentCommunity } = storeToRefs(useCoreStore())

    return getCurrentUserJurnis.value.filter((jurni) => jurni.community.id === currentCommunity.value?.id)
  }

  const studentLatestJurnisPayments = () => {
    const { currentUser } = storeToRefs(useCoreStore())
    const { studentBillPayment } = storeToRefs(useBillingStore())
    const myJurnis: Array<Record<string, any>> = []

    if (!currentUser.value)
      return myJurnis

    // if student
    if (isStudent(currentUser.value)) {
      studentCommunityJurnis().map((jurni) => {
        const currentJurniStudentBillables = studentBillPayment.value[jurni.id ?? ''] ?? []
        const latestJurniBillingDate = ref('-')
        const latestJurniBillingCost = ref('-')
        const jurniCost = ref('-')
        const recurStatus = ref('')
        const date = new Date()

        if (!jurni.meta?.billingProvider || jurni.meta?.billingProvider === 'Free') {
          jurniCost.value = '0'
          recurStatus.value = 'Free'
        } else if (jurni.meta?.billingProvider === 'External') {
          jurniCost.value = '-'
          recurStatus.value = 'Offline Subscription'
        } else if (jurni.meta?.billingType === 'Upfront') {
          jurniCost.value = `${jurni.meta?.cost}`
          recurStatus.value = 'One-Time'
        } else if (jurni.meta?.billingType === 'Recurring') {
          jurniCost.value = `${jurni.meta?.cost}`
          recurStatus.value = 'Monthly Recurring'
        } else {
          jurniCost.value = '-'
          recurStatus.value = 'Free'
        }

        if (currentJurniStudentBillables.length > 0) {
          // eslint-disable-next-line max-len
          const latestJurniBilling = currentJurniStudentBillables.reduce(
            (a, b) => (useTimestamp(a.paidOn) > useTimestamp(b.paidOn) ? a : b)
          )
          if (jurni.meta?.billingType === 'Recurring')
            latestJurniBillingDate.value = 'Subscribed '
          else if (jurni.meta?.billingType === 'Upfront')
            latestJurniBillingDate.value = 'Paid '

          latestJurniBillingDate.value += useTimestamp(latestJurniBilling.paidOn)
          latestJurniBillingCost.value = latestJurniBilling.cost
        }

        const studentPaymentCost = computed(() => {
          if (jurni.meta?.billingProvider === 'Through Jurni' && recurStatus.value !== 'Free')
            return latestJurniBillingCost.value ?? 5
          return jurniCost.value
        })

        const transactionDate = computed(() => {
          if (jurni.meta?.billingProvider === 'Through Jurni' && latestJurniBillingDate.value)
            return latestJurniBillingDate.value
          return useTimestamp(Timestamp.fromDate(new Date(date.getFullYear(), date.getMonth(), 1)))
        })

        myJurnis.push({
          id: jurni.id,
          name: jurni.name,
          recurringStatus: recurStatus.value,
          studentPaymentCost: studentPaymentCost.value,
          transactionDate: transactionDate.value
        })
        return null
      })
    }
    return myJurnis
  }

  const calculateStudentMonlthlyCost = () => {
    const billables: Array<Record<string, any>> = []
    const totalCost = ref(0)
    const date = new Date()
    const studentPayments = studentLatestJurnisPayments()

    studentCommunityJurnis().map((jurni) => {
      const studentJurniPayment = studentPayments.find((item) => item.id === jurni.id)
      if (!jurni.meta?.cost)
        return null

      if (
        jurni.meta?.billingType === 'Recurring' ||
        (jurni.meta?.billingType === 'Upfront' && studentJurniPayment?.transactionDate === '-')
      ) {
        billables.push({
          name: jurni.name,
          cost: jurni.meta.cost,
          billingDate: jurni.meta?.billingType === 'Recurring' ? useTimestamp(Timestamp.fromDate(new Date(date.getFullYear(), date.getMonth() + 1, 1))) : 'Upfront'
        })
        totalCost.value += parseFloat(jurni.meta.cost.toString())
      }

      return null
    })

    if (billables.length > 0)
      billables.push({ name: 'Total', cost: totalCost.value })
    return billables
  }

  return {
    calculateCoachCost,
    calculateStudentMonlthlyCost,
    studentLatestJurnisPayments,
    coachLatestPayment,
    coachPayments
  }
}
