<template>
  <div class="questionaire-review">Review</div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>
