import type { Ref } from 'vue'
import { computed, ref } from 'vue'

import { useMq } from 'vue3-mq'
import { useElementSize, useMouse } from '@vueuse/core'

import { options, Breakpoints } from '@/plugins/media-query'

type UseResponsive = (containerEl?: Ref<HTMLElement | null> | HTMLElement) => {
  mq: Record<string, any>
  breakpoints?: Breakpoints
  isMobile: Ref<boolean>
  isPhone: Ref<boolean>
  isTablet: Ref<boolean>
  isDesktop: Ref<boolean>
  isLaptop: Ref<boolean>
  isTouch: Ref<boolean>
  width?: Ref<number>
  height?: Ref<number>
  responsiveClasses: Ref<Record<string, any>>
}

export const useResponsive: UseResponsive = (containerEl) => {
  const container = ref(containerEl)
  const mq = useMq()
  const isMobile = computed(() => mq.mdMinus)
  const isPhone = computed(() => mq.xs)
  const isTablet = computed(() => mq.smPlus && mq.mdMinus)
  const isLaptop = computed(() => mq.lgPlus && mq.xlMinus)
  const isDesktop = computed(() => mq.xxl)

  const responsiveClasses = computed(() => ({
    'is-mobile': isMobile.value,
    'is-phone': isPhone.value,
    'is-tablet': isTablet.value,
    'is-desktop': isDesktop.value,
    'is-laptop': isLaptop.value,
    'is-sm-minus': mq.smMinus,
    'is-sm-plus': mq.smPlus,
    'is-md-minus': mq.mdMinus,
    'is-md-plus': mq.mdPlus,
    'is-lg-minus': mq.lgMinus,
    'is-lg-plus': mq.lgPlus,
    'is-xl-minus': mq.xlMinus,
    'is-xl-plus': mq.xlPlus,
    [`is-${mq.current}`]: true
  }))

  const { width, height } = useElementSize(container)

  const isTouch = computed(() => {
    let result = false
    if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
      // if Pointer Events are supported, just check maxTouchPoints
      if (navigator.maxTouchPoints > 0)
        result = true
    } else {
      // no Pointer Events...
      // eslint-disable-next-line no-lonely-if
      if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
        // check for any-pointer:coarse which mostly means touchscreen
        result = true
      } else if (window.TouchEvent || ('ontouchstart' in window)) {
        // last resort - check for exposed touch events API / event handler
        result = true
      }
    }
    return result
  })

  const exports = {
    mq,
    isMobile,
    isPhone,
    isTablet,
    isLaptop,
    isDesktop,
    isTouch,
    width,
    height,
    responsiveClasses,
    breakpoints: options.breakpoints
  }

  return exports
}
