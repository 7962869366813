
  import {
    Ref, computed, defineComponent, onMounted, ref, watch
  } from 'vue'

  export default defineComponent({
    name: 'contenteditable',
    props: {
      editable: Boolean,
      modelValue: String,
      placeholder: String
    },
    emits: ['submit', 'update:modelValue'],
    setup(props) {
      const input: Ref<null|HTMLElement> = ref(null)
      const focus = () => {
        input.value?.focus()
      }

      onMounted(() => {
        if (input.value)
          input.value.innerText = props.modelValue || ''
      })

      const placeholderText = ref('...') // Default
      watch(() => props.placeholder, (value) => {
        if (value)
          placeholderText.value = value
      }, { immediate: true })

      const css = computed(() => ({
        // must be in quotes in order to be used with `content` CSS prop
        '--placeholder-text': `' ${placeholderText.value}'`
      }))

      return {
        input,
        focus,

        css
      }
    },
    methods: {
      onKeypress(event: KeyboardEvent) {
        if (!this.editable) {
          event.preventDefault()
          return
        }

        if (event.key === 'Enter') {
          // Allow for multi-line on native app
          if (navigator.userAgent.indexOf('native') > -1)
            return

          const target = event.target as HTMLElement
          if (!event.shiftKey && target.innerText.length > 0) {
            event.preventDefault()
            this.$emit('submit')
          }
        }
      }
    }
  })
