import type { JurniOnboardingChecklist } from '@/types'

import { defineStore, storeToRefs } from 'pinia'
// eslint-disable-next-line import/no-cycle
import { useCoreStore } from '@/stores/core'
import { setDoc } from 'firebase/firestore'
import { computed, watch } from 'vue'

import { useStorage } from '@vueuse/core'
import { usePermissions } from '@/composables/usePermissions'

export const useAppOnboardingStore = defineStore('appOnboarding', () => {
  const { can } = usePermissions()

  const checklist: JurniOnboardingChecklist = {
    dismissed: false,
    items: [
      { event: 'profile.avatar.create', label: 'Set up profile image', location: { name: 'settings.account' } },
      { event: 'jurni.onboarding.video.watch', label: 'Watch the video in first jurni step', location: { name: 'jurnis' } },
      { event: 'group.post.create', label: 'Create a post in the community group', location: { name: 'groups' } },
      { event: 'calendar.event.view', label: 'View calendar events', location: { name: 'calendar.index' } }
    ] }

  const initState = computed(() => checklist.items.reduce((o, item) => Object.assign(o, { [item.event]: false }), {}))

  const state = useStorage('jurni-onboarding', initState.value)
  const isDismissed = useStorage('jurni-onboarding-dismiss', false)

  const items = computed(() => checklist.items.map((item) => ({ ...item, completed: state.value[item.event] })))
  const isCompleted = computed(() => !items.value.find((item) => item.completed === false))

  const { currentUser, permissionsLoaded, permissions } = storeToRefs(useCoreStore())

  const completeItem = (event) : void => {
    state.value = { ...state.value, [event]: true }
  }

  const dismiss = () : void => {
    isDismissed.value = true
  }

  const showOnboarding = computed(() => {
    if (!currentUser.value || !currentUser.value.profile)
      return false

    if (isDismissed.value)
      return false

    if (isCompleted.value)
      return false

    if (currentUser.value.profile.isOnboardingCompleted)
      return false

    if (!permissionsLoaded.value || permissions.value.length === 0)
      return false

    return !can('manage', 'community')
  })

  watch(() => isCompleted.value, (newVal) => {
    if (newVal && currentUser.value && currentUser.value.userRef)
      setDoc(currentUser.value.userRef, { isOnboardingCompleted: true }, { merge: true })
  })

  return {
    state,
    items,
    dismiss,
    completeItem,
    initState,
    showOnboarding,
    isDismissed,
    isCompleted,
    currentUser
  }
})
