import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import type { MaybeRef } from '@vueuse/core'

import { sort } from 'fast-sort'

type UseSortedCollection = <T extends Record<string | number | symbol, any>>(
  collection: MaybeRef<T[]>,
  keys: string[],
  orders: Array<'asc' | 'desc'>
) => Ref<T[]>

export const useSortedCollection: UseSortedCollection = (maybeRef, keysMaybeRef, ordersMaybeRef) => {
  const collection = ref(maybeRef)
  const keys = ref(keysMaybeRef)
  const orders = ref(ordersMaybeRef)

  return computed({
    get: () => (
      sort(collection.value).by(keys.value.map(
        (key, index) => {
          const order = orders.value[index]
          const get = (x) : unknown => x[key]

          if (order === 'desc')
            return { desc: get }

          return { asc: get }
        }
      ))
    ),
    set: (v) => { collection.value = v }
  })
}
