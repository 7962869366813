import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()

interface fetchTokenReqInterface {
  id: string
  name: string
  email: string
  avatar: string
  moderator: boolean
}

interface fetchTokenResInterface {
  token: string
}

export const fetchToken = async (
  currentUserId: string, name: string, email: string, avatar: string, moderator: boolean
) => {
  const fetchTokenFunc = httpsCallable<fetchTokenReqInterface, fetchTokenResInterface>(functions, 'videoConference/getToken')

  const data = {
    id: currentUserId,
    name,
    email,
    avatar,
    moderator
  }

  try {
    const response = await fetchTokenFunc(data)
    return response.data
  } catch (err) {
    throw new Error('error generating call token')
  }
}

export default {
  fetchToken
}
