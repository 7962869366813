import { RouterView } from 'vue-router'

import Meet from '@/views/meet/meeting.vue'
import MeetPending from '@/views/meet/meeting_pending.vue'

import { muteCallNotification, routeMeetingRoom } from '@/router/guards'

export default [
  {
    path: '/meet',
    name: 'meet',
    component: RouterView,
    beforeEnter: muteCallNotification,
    meta: {
      requiresAuth: true,
      layout: 'Default'
    },
    children: [
      {
        path: 'pending/:room',
        name: 'meet.pending',
        props: true,
        component: MeetPending,
        beforeEnter: routeMeetingRoom,
        meta: { requiresAuth: true }
      },
      {
        path: ':room',
        name: 'meet.room',
        props: true,
        component: Meet,
        beforeEnter: routeMeetingRoom,
        meta: { requiresAuth: true }
      }
    ]
  }
]
