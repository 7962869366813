import { Plugin } from 'vue'
import InfiniteLoading from 'vue-infinite-loading'
import Multiselect from '@vueform/multiselect'

import Card from '@/components/card-ui/Card.vue'
import ComingSoon from '@/components/coming-soon/ComingSoon.vue'
import DropdownMenu from '@/components/dropdown/Dropdown.vue'
import JurButton from '@/components/jur-button/JurButton.vue'
import JurHeader from '@/components/jur-header/JurHeader.vue'
import JurInput from '@/components/jur-input/JurInput.vue'
import Icon from '@/components/icon/Icon.vue'
import Heading from '@/components/heading/Heading.vue'
import Text from '@/components/text/Text.vue'

import { VaSelect } from 'vuestic-ui'
// import 'vuestic-ui/dist/vuestic-ui.css'

import { MqResponsive } from 'vue3-mq'

export default {
  install(app) {
    app.component('InfiniteLoading', InfiniteLoading)
    app.component('Multiselect', Multiselect)

    app.component('Card', Card)
    app.component('DropdownMenu', DropdownMenu)
    app.component('JurButton', JurButton)
    app.component('JurHeader', JurHeader)
    app.component('JurInput', JurInput)
    app.component('Icon', Icon)
    app.component('Heading', Heading)
    app.component('Text', Text)
    app.component('va-select', VaSelect)
    app.component('mq-responsive', MqResponsive)
    app.component('coming-soon', ComingSoon)
  }
} as Plugin
