import { defineStore, storeToRefs } from 'pinia'
import { ref, watch, computed } from 'vue'
import { useCoreStore } from '@/stores/core'
import {
  arrayUnion,
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs, limit,
  query,
  setDoc,
  Timestamp,
  where,
  DocumentSnapshot
} from 'firebase/firestore'
import { db } from '@/services/firebase'
import { usePermissions } from '@/composables/usePermissions'
import { Campaign, CampaignTrigger } from '@/types/campaign'

const durationToSeconds = (value : number, interval: string) : number => {
  const formulas = {
    minutes: 60, hours: 3600, days: 86400, weeks: 604800
  }
  return formulas[interval] * value
}

export const useCampaignStore = defineStore('campaign', () => {
  const { currentCommunity } = storeToRefs(useCoreStore())
  const { can } = usePermissions()
  const campaigns = ref<Array<Campaign>>([])
  const campaignsObject = ref<Record<string, Campaign>>({})

  const triggerTypes = ref([{ label: 'Fixed Time', id: 'time' }, { label: 'Action', id: 'action' }])
  const messageTypes = ref([{ label: 'Email', id: 'email' }, { label: 'SMS', id: 'sms' }, { label: 'Chat', id: 'chat' }])
  const delayTypes = ref([{ label: 'Fixed Time', id: 'time' }, { label: 'Duration', id: 'duration' }])

  const groupingOptions = ref([{ id: 'individual', label: 'Individual' }, { id: 'group', label: 'Group' }])
  const durationOptions = ref([
    { label: 'Minutes', id: 'minutes' },
    { label: 'Hours', id: 'hours' },
    { label: 'Days', id: 'days' },
    { label: 'Weeks', id: 'weeks' }
  ])
  const timeframeOptions = ref([
    { label: 'Before', id: 'before' },
    { label: 'After', id: 'after' }
  ])

  const actionOptions = computed(() => {
    const actionEvents = [
      { label: 'Event', id: 'events' },
      { label: 'Billing Date', id: 'billingdate' },
      { label: 'Joining Group', id: 'joininggroup' },
      { label: 'Starting Jurni', id: 'startingjurni' },
      { label: 'Finishing Jurni', id: 'finishingjurni' },
      { label: 'Removed From Group', id: 'removedgroup' },
      { label: 'Removed From Jurni', id: 'removedjurni' },
      { label: 'Birthday', id: 'birthday' },
      { label: 'Missed Payment', id: 'missedpayment' }
    ]

    if (can('manage', 'app'))
      actionEvents.push({ label: 'New Community', id: 'newcommunity' })

    return actionEvents
  })

  const repeatOptions = ref([
    { label: 'Daily', id: 'daily' },
    { label: 'Weekly', id: 'weekly' },
    { label: 'Weekdays', id: 'weekday' }
  ])

  const dayOptions = ref([
    { label: 'Monday', id: 'monday' },
    { label: 'Tuesday', id: 'tuesday' },
    { label: 'Wednesday', id: 'wednesday' },
    { label: 'Thursday', id: 'thursday' },
    { label: 'Friday', id: 'friday' },
    { label: 'Saturday', id: 'saturday' },
    { label: 'Sunday', id: 'sunday' }
  ])

  const getCampaignAudience = (card) : Array<DocumentReference> => {
    const tmpAudience = ref<Array<DocumentReference>>([])

    if (card.audJurni) {
      card.audJurni.forEach((jurniPath) => {
        tmpAudience.value.push(doc(db, jurniPath))
      })
    }

    if (card.audGroup) {
      card.audGroup.forEach((groupPath) => {
        tmpAudience.value.push(doc(db, groupPath))
      })
    }

    if (card.audEvent) {
      card.audEvent.forEach((eventPath) => {
        tmpAudience.value.push(doc(db, eventPath))
      })
    }

    return tmpAudience.value
  }

  const addCampaign = async (campaignSnap: DocumentSnapshot, forceLoad = false) : Promise<void> => {
    const campaign = campaignSnap.data() as Campaign

    if (campaign.pendingDelete)
      return

    const hasCampaign = campaigns.value.find((c) => c.id === campaignSnap.id)
    if (hasCampaign && !forceLoad)
      return

    const triggerSnaps = await getDocs(
      query(collection(db, campaignSnap.ref.path, 'triggers'), limit(200))
    )

    triggerSnaps.forEach((triggerSnap) => {
      if (!campaign.triggers)
        campaign.triggers = []
      campaign.triggers.push({ ...triggerSnap.data(), id: triggerSnap.id } as CampaignTrigger)
    })

    if (campaign.imageContentRef) {
      const imageSnap = await getDoc(campaign.imageContentRef)
      const imageData = imageSnap.data()
      if (imageData)
        campaign.imageUrl = imageData.uploadUrl ?? ''
    }

    if (!hasCampaign) {
      campaigns.value.push(campaign)
    } else {
      campaigns.value = campaigns.value.map((c) => {
        if (c.id === campaign.id)
          return campaign
        return c
      })
    }
    campaignsObject.value[campaignSnap.id] = campaign
  }

  const removeCampaign = async (campaignId: string) : Promise<void> => {
    campaigns.value = campaigns.value.filter((campaign) => campaign.id !== campaignId)

    setDoc(doc(db, 'campaigns', campaignId), { active: false, isArchived: true, pendingDelete: true }, { merge: true })
  }

  const loadCampaigns = async (forceLoad = false) : Promise<void> => {
    if (!currentCommunity.value || !currentCommunity.value.id)
      return

    if (!forceLoad && campaigns.value.length > 0)
      return

    const campaignsSnaps = await getDocs(query(
      collection(db, 'campaigns'),
      where('community', '==', doc(db, 'communities', currentCommunity.value.id))
    ))

    await Promise.all(campaignsSnaps.docs.map(
      async (campaignSnap) => {
        await addCampaign(campaignSnap, forceLoad)
      }
    ))
  }

  const saveTrigger = async (campaignRef, card, index) : Promise<void> => {
    const tmpStep = ref<Record<string, any>>({ type: card.type, subtype: card.subtype })
    tmpStep.value.triggerStep = index

    if (card.subtype === 'action') {
      tmpStep.value = {
        ...tmpStep.value,
        durationValue: card.durationValue,
        durationInterval: card.durationInterval,
        duration: durationToSeconds(+card.durationValue, card.durationInterval),
        actionTime: card.timeframe,
        event: card.event
      }

      if (card.eventRef)
        tmpStep.value.eventRef = doc(db, card.eventRef)
    } else if (card.subtype === 'time') {
      tmpStep.value.completed = tmpStep.value.completed ?? false
      tmpStep.value = {
        ...tmpStep.value,
        date: Timestamp.fromDate(new Date(card.date)),
        audience: getCampaignAudience(card)
      }

      if (card.recurring) {
        tmpStep.value = {
          ...tmpStep.value,
          recurring: card.recurring,
          recurringInterval: card.recurringInterval,
          recurringDay: card.recurringInterval === 'weekly' ? card.recurringDay : ''
        }
      }
    }

    if (!card.id) {
      const cardDoc = doc(collection(db, campaignRef!.path, 'triggers'))
      tmpStep.value.id = cardDoc.id
      card.id = cardDoc.id
      await setDoc(cardDoc, tmpStep.value)
    } else {
      await setDoc(doc(collection(db, campaignRef!.path, 'triggers'), card.id), tmpStep.value)
    }
    await loadCampaigns()
  }

  const convertStep = (card) : Record<string, any> => {
    const tmpStep = ref<Record<string, any>>({ type: card.type, subtype: card.subtype })
    if (card.type === 'message') {
      if (!card.message && card.content)
        return card

      tmpStep.value.methods = card.type === 'message' ? card.subtype : []
      tmpStep.value.content = card.message ?? ''
      tmpStep.value.grouping = card.grouping ?? 'individual'

      if (card.grouping === 'group') {
        if (card.group && !card.group.path)
          tmpStep.value.group = doc(db, card.group)
        else
          tmpStep.value.group = card.group
      }
    }

    if (['duration', 'time'].includes(card.subtype))
      tmpStep.value.delay = {}

    if (card.subtype === 'duration') {
      if (!card.durationInterval && card.delay)
        return card

      tmpStep.value.delay = {
        interval: card.durationInterval,
        value: card.durationValue,
        duration: durationToSeconds(+card.durationValue, card.durationInterval)
      }
    } else if (card.subtype === 'time') {
      if (!card.date && card.delay)
        return card

      tmpStep.value.delay = {
        date: Timestamp.fromDate(new Date(card.date))
      }
    }

    return tmpStep.value
  }

  const saveCampaign = async (
    campaignId: string | null,
    campaign: Record<string, any>,
    campaignCards: Array<Record<string, any>>
  ) : Promise<DocumentReference | null> => {
    if (!currentCommunity.value)
      return null

    const campaignRef = ref<DocumentReference | null>(null)
    if (!campaignId)
      campaignRef.value = doc(collection(db, 'campaigns'))
    else
      campaignRef.value = doc(db, 'campaigns', campaignId)

    const campaignData : Record<string, any> = {
      id: campaignRef.value.id,
      community: doc(db, 'communities', currentCommunity.value?.id),
      name: campaign.name ?? '',
      imageContentRef: campaign.imageContentRef ?? null,
      baseTemplate: campaign.baseTemplate ?? '',
      active: campaign.active ?? false,
      isArchived: false
    }

    if (campaignCards.length < 1)
      campaignData.steps = []

    await setDoc(campaignRef.value, campaignData, { merge: true })
    await loadCampaigns(true)
    return campaignRef.value
  }

  const saveStep = async (campaignRef: DocumentReference, step) : Promise<void> => {
    if (!campaignsObject.value[campaignRef.id])
      await loadCampaigns(true)

    if (step.index) {
      const steps : Array<Record<string, any>> = []
      campaignsObject.value[campaignRef.id]?.steps
        ?.filter((step) => step.type !== 'trigger')
        .forEach((step) => {
          steps.push(convertStep(step))
        })

      steps[step.index - 1] = convertStep(step)
      await setDoc(campaignRef, { steps }, { merge: true })
    } else {
      await setDoc(campaignRef, { steps: arrayUnion(convertStep(step)) }, { merge: true })
    }
    await loadCampaigns(true)
  }

  const removeStep = async (campaignRef: DocumentReference, step) : Promise<void> => {
    if (!campaignsObject.value[campaignRef.id])
      await loadCampaigns(true)

    const steps : Array<Record<string, any>> = []
    campaignsObject.value[campaignRef.id]?.steps
      ?.filter((step) => step.type !== 'trigger')
      .forEach((step) => {
        steps.push(convertStep(step))
      })
    steps.splice(step.index - 1, 1)

    await setDoc(campaignRef, { steps }, { merge: true })
    await loadCampaigns(true)
  }

  const getCampaignById = async (id: string, forceReload = false) : Promise<Campaign | undefined> => {
    if (!campaigns.value || campaigns.value.length < 1 || forceReload)
      await loadCampaigns(forceReload ?? false)
    return campaigns.value.find((campaign) => campaign.id === id)
  }

  const updateArchivedStatus = async (campaignId: string): Promise<void> => {
    const campaignRef = doc(db, 'campaigns', campaignId)
    const campaignSnap = await getDoc(campaignRef)
    const campaignData = campaignSnap.data()

    const archivedStatus = campaignData?.isArchived ? campaignData?.isArchived : false

    await setDoc(
      campaignRef,
      { isArchived: !archivedStatus, active: archivedStatus },
      { merge: true }
    )

    campaigns.value.forEach((campaign) => {
      if (campaign.id === campaignId)
        campaign.isArchived = !archivedStatus
    })
  }

  const getActiveCampaigns = computed(() => campaigns.value.filter((campaign) => !campaign.isArchived))

  const getArchivedCampaigns = computed(() => campaigns.value.filter((campaign) => campaign.isArchived))

  const duplicateCampaign = async (campaignId: string) : Promise<DocumentReference> => {
    if (!can('manage', 'community'))
      throw new Error('insufficient permissions')

    const campaign = JSON.parse(JSON.stringify(await getCampaignById(campaignId)))
    const newCampaignRef = doc(collection(db, 'campaigns'))

    campaign.id = newCampaignRef.id
    campaign.name += ' (Clone)'
    campaign.community = doc(db, 'communities', currentCommunity.value!.id)

    // remove triggers, dupe later
    delete campaign.triggers

    const newCampaign: Partial<Campaign> = { ...campaign }

    await setDoc(newCampaignRef, newCampaign)

    // dupe triggers
    const newCampaignSnap = await getDoc(newCampaignRef)
    const newCampaignId = newCampaignSnap.id

    const triggers = await getDocs(
      query(
        collection(db, 'campaigns', campaignId, 'triggers')
      )
    )

    await Promise.all(triggers.docs.map(async (triggerSnap) => {
      const newTriggerRef = doc(db, 'campaigns', newCampaignId, 'triggers', triggerSnap.id)
      const newTrigger = { ...triggerSnap.data() }
      await setDoc(newTriggerRef, newTrigger)
    }))

    await addCampaign(newCampaignSnap)

    return newCampaignRef
  }

  watch(() => currentCommunity.value, async () => {
    campaigns.value = []
    await loadCampaigns()
  })

  return {
    campaigns,
    getCampaignById,
    loadCampaigns,
    saveCampaign,
    saveTrigger,
    saveStep,
    removeStep,
    removeCampaign,
    triggerTypes,
    messageTypes,
    delayTypes,
    groupingOptions,
    durationOptions,
    timeframeOptions,
    actionOptions,
    repeatOptions,
    dayOptions,
    updateArchivedStatus,
    getActiveCampaigns,
    getArchivedCampaigns,
    duplicateCampaign
  }
})
