type useCalendar = () => {
  getFirstDayMonth: (date: Date) => Date
  getLastDayMonth: (date: Date) => Date
  getDateTomorrow: (date: Date) => Date
}

export const useCalendar: useCalendar = () => {
  const getFirstDayMonth = (date: Date) => {
    const year = date.getFullYear()
    const month = date.getMonth()
    return new Date(year, month, 1, 0, 0, 0, 0)
  }

  const getLastDayMonth = (date: Date) => {
    const year = date.getFullYear()
    const month = date.getMonth()
    return new Date(year, month + 1, 0, 23, 59, 59, 999)
  }

  const getDateTomorrow = (date: Date) => new Date(date.setHours(date.getHours() + 1))

  return {
    getFirstDayMonth,
    getLastDayMonth,
    getDateTomorrow
  }
}
