import { shallowRef } from 'vue'

import THUMB_UP from '@/assets/icons/emoji_thumbup.svg'
import LOVE from '@/assets/icons/emoji_love.svg'
import LAUGH from '@/assets/icons/emoji_laugh.svg'
import SURPRISE from '@/assets/icons/emoji_surprise.svg'
import SAD from '@/assets/icons/emoji_sad.svg'
import ANGRY from '@/assets/icons/emoji_angry.svg'

export const reactionIcons = shallowRef({
  THUMB_UP,
  LOVE,
  LAUGH,
  SURPRISE,
  SAD,
  ANGRY
})
export default reactionIcons
