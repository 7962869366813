// TODO:x deprecating this and is replaced with thread.ts
// aside from the search store (for now)

import {
  collection,
  doc,
  setDoc,
  Unsubscribe,
  deleteDoc,
  arrayRemove,
  arrayUnion,
  query,
  where,
  getDoc,
  onSnapshot,
  Timestamp,
  getDocs,
  DocumentReference, Query, DocumentData, doc as firebaseDoc
} from 'firebase/firestore'
import { getDownloadURL, uploadBytes, deleteObject, ref as storageRef } from 'firebase/storage'
import { serverTimestamp, ref, set, push, onChildAdded, onDisconnect, onValue, get, query as rtdbQuery, limitToLast, orderByKey, startAfter, onChildChanged, endBefore, remove } from 'firebase/database'
import { defineStore, storeToRefs } from 'pinia'
import { Ref, ref as vueRef, reactive, watch } from 'vue'

import { useSortedCollection } from '@/composables/utils/useSortedCollection'
import { useThread } from '@/composables/useThread'
import router from '@/router'
import { db, rtdb, storage } from '@/services/firebase'
import { useAuthStore } from '@/stores/auth'
import { useAlertStore } from '@/stores/alert'
import { useCoreStore } from '@/stores/core'
import type { ChatMessage, Community, Thread, User } from '@/types'
import { UseUser } from '@/composables/useUser'
import { v4 } from 'uuid'
import { usePermissions } from '@/composables/usePermissions'

type ChatStoreState = {
  threads: { [threadId: string]: Thread }
  threadOrder: Array<string>
  status: Record<string, boolean>
  messages: { [threadId: string]: Ref<ChatMessage[]> }
  typers: { [threadId: string]: Record<string, string> }
  recorders: { [threadId: string]: Record<string, string>}
  typingTimeout: NodeJS.Timeout | null
  newChatThreads: Array<string>
  activeThreadId: string | null
  chatSoundStatus: boolean
  reply?: ChatMessage | null
  chatExists: boolean
  threadWithMemberId: string | null
  threadSnapshot: Unsubscribe | null
  messageSnapshots: { [threadId: string]: { add: Unsubscribe | null, update: Unsubscribe | null } }
}

type threadSearchState = {
  messageID : Array<string> | null,
  lastIndex : string | null
}

export const useChatStore = defineStore('chat', {
  state: (): ChatStoreState => ({
    messages: {},
    threads: {},
    threadOrder: [],
    typers: {},
    recorders: {},
    status: {},
    typingTimeout: null,
    newChatThreads: [],
    activeThreadId: null,
    chatSoundStatus: true,
    reply: null,
    chatExists: true,
    threadWithMemberId: null,
    threadSnapshot: null,
    messageSnapshots: {}
  }),
  getters: {
    activeThread: (st) => {
      if (!st.activeThreadId)
        return null

      return st.threads[st.activeThreadId]
    },
    isChatSoundOn: (st) => st.chatSoundStatus,
    getThreadWithMemberId: (st) => st.threadWithMemberId,
    getThreadList: (st) => {
      const { currentUser } = storeToRefs(useCoreStore())
      const newThreads: Array<Thread> = []
      const threads: Array<Thread> = []

      st.threadOrder.map((threadId) => {
        if (!threadId || !st.threads[threadId])
          return null

        if ([process.env.VUE_APP_CHAT_SUPPORT_USER_ID, st.threads[threadId].owner.id].includes(currentUser.value?.id)) {
          if (st.threads[threadId].meta?.hideThread === true)
            return null
        }

        if (st.newChatThreads.includes(threadId))
          newThreads.push(st.threads[threadId])
        else
          threads.push(st.threads[threadId])

        return null
      })

      return newThreads.concat(threads)
    },
    searchThreadList: (st) => {
      const { currentUser } = storeToRefs(useCoreStore())
      const newThreads: Array<Thread> = []
      const threads: Array<Thread> = []

      st.threadOrder.map((threadId) => {
        if (!threadId || !st.threads[threadId])
          return null

        if (st.newChatThreads.includes(threadId))
          newThreads.push(st.threads[threadId])
        else
          threads.push(st.threads[threadId])

        return null
      })

      return newThreads.concat(threads)
    },
    getThreadById: (st) => (
      (id: string) : Thread | null => st.threads[id] || null
      // (id: string) : Thread | null => st.threads?.filter((thread) => thread?.thread_id === id)[0] ?? null
    ),
    hasNewChats: (st) => st.newChatThreads.filter(
      (newChatId) => (st.threads[newChatId] ? !st.threads[newChatId].meta?.hideThread || false : false)
    ).length,
    threadHasNewChats: (st) => (threadId) => st.newChatThreads.filter(
      (newChatId) => (st.threads[newChatId] ? !st.threads[newChatId].meta?.hideThread || false : false)
    ).includes(threadId),
    getThreadMessageById: (st) => (
      (threadId: string, messageId: string) : ChatMessage | null => (
        st.messages[threadId]?.filter((msg) => msg.message_id === messageId)[0] ?? null
      )
    ),
    getThreadMessages: (st) => (
      (id: string) : ChatMessage[] => reactive(st.messages[id] ?? [])
    ),
    threadMembers: (st) => {
      const thread = useThread(st.activeThreadId || '')
      return thread.members
    },
    nonMembers: (st) => {
      const { getCommunityMembers: allMembers } = storeToRefs(useCoreStore())
      const thread = useThread(st.activeThreadId || '')
      if (thread.doc.value?.type === 'bot')
        return []

      return allMembers.value.filter(
        (member) => {
          const tm = thread.members.value.map(
            (threadMember) => threadMember.id.value
          )
          return !tm.includes(member.id)
        }
      )
    },
    getMemberValidation: (st) => (st.chatExists)
  },
  actions: {
    addToTopThread(threadId: string) {
      const tempThreadOrder = this.threadOrder.filter((threadOrderId) => threadOrderId !== threadId)
      tempThreadOrder.unshift(threadId)
      this.threadOrder = tempThreadOrder
    },

    unHideThread(thread_id: string) {
      if (this.threads[thread_id] && this.threads[thread_id].meta && this.threads[thread_id].meta?.hideThread) {
        this.threads[thread_id].meta = {
          ...this.threads[thread_id].meta,
          hideThread: false
        }

        setDoc(
          doc(db, 'threads', thread_id),
          { meta: { hideThread: false } },
          { merge: true }
        )
      }
    },

    setActiveThread(thread_id: string) {
      this.activeThreadId = thread_id
      // this.addToTopThread(thread_id)
      this.saveThreadOrder()
    },

    switchChatSound(val: boolean) {
      const { userRef } = storeToRefs(useAuthStore())
      if (userRef.value) {
        setDoc(
          userRef.value,
          { meta: { chatSoundStatus: val } },
          { merge: true }
        )
      }
      this.chatSoundStatus = val
    },

    setUserStatus(state: 'online' | 'offline') {
      return {
        state,
        last_changed: serverTimestamp()
      }
    },

    async addMessageListener(threadId : string, listenerType : 'add'|'update') : Promise<Unsubscribe | null> {
      const alertStore = useAlertStore()
      const { currentCommunity } = storeToRefs(useCoreStore())
      const { userRef } = storeToRefs(useAuthStore())
      const messagesRef = ref(rtdb, `chats/${threadId}/messages`)
      const initialMessageRef = rtdbQuery(messagesRef, orderByKey(), limitToLast(1))

      const initialData = await new Promise((resolve, reject) => {
        const onError = (error) => reject(error)
        const onData = (snap) => resolve(snap.val())

        onValue(initialMessageRef, onData, onError, { onlyOnce: true })
      }) as Record<string, ChatMessage>

      const lastIndex = initialData ? Object.keys(initialData)[Object.keys(initialData).length - 1] : null
      let newMessageRef
      if (lastIndex)
        newMessageRef = rtdbQuery(initialMessageRef, startAfter(lastIndex))
      else
        newMessageRef = rtdbQuery(initialMessageRef)

      if (listenerType === 'add') {
        return onChildAdded(newMessageRef, async (messageSnap) => {
          const threadSnap = await getDoc(doc(db, 'threads', threadId))
          const thread = threadSnap.data() as Thread
          const message = messageSnap.val() as ChatMessage
          let notificationData: Record<string, any> = {}
          if ((currentCommunity.value && thread.community !== 'global' && currentCommunity.value.id === thread.community.id) || thread.type === 'support') {
            this.addToTopThread(threadId)
            this.saveThreadOrder()
            notificationData = {
              message: 'New chat message',
              actionLabel: 'View',
              onClick: () => {
                this.activeThreadId = threadId
                router.push({ name: 'chat' })
              }
            }
          } else if (currentCommunity.value && thread.community !== 'global' && currentCommunity.value.id !== thread.community.id) {
            const commSnap = await getDoc(thread.community)
            const community = commSnap.data() as Community
            const threadComposable = useThread(threadId, thread)
            notificationData = {
              message: `New chat message<br>Community: ${community.name}<br>Conversation: ${threadComposable.name.value || 'unnamed thread'}`
            }
          }

          if (message.timestamp)
            message.timestamp = new Timestamp(message.timestamp?.seconds, message.timestamp?.nanoseconds)

          if (Object.keys(this.messages).includes(threadId) && this.messages[threadId]) {
            if (!this.messages[threadId].some((msg) => msg.message_id === message.message_id))
              this.messages[threadId].push(message)
          }

          if (userRef.value?.id !== message?.from) {
            this.newChatThreads.push(threadId)

            alertStore.setAudio(
              'newChat',
              'https://firebasestorage.googleapis.com/v0/b/jurni-prod.appspot.com/o/sfxs%2Fmeeting-notification.mp3?alt=media&token=4eb80eca-5bd1-4826-af76-ce6460422ca2',
              { defaultMuted: true, muted: true }
            )

            if (this.chatSoundStatus)
              alertStore.playAudio('newChat')

            // send notification toast
            alertStore.addToast(notificationData, 2500)
          }

          await this.clearTyping()
        })
      }

      if (listenerType === 'update') {
        return onChildChanged(messagesRef, async (messageSnap) => {
          const message = messageSnap.val() as ChatMessage

          if (message.timestamp)
            message.timestamp = new Timestamp(message.timestamp?.seconds, message.timestamp?.nanoseconds)

          this.messages[threadId] = this.messages[threadId].map(
            (msg) => (msg.message_id === message.message_id ? message : msg)
          )
        })
      }
      return null
    },

    async clearNewChat(threadId) {
      const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
      if (!currentUser.value || !currentCommunity.value)
        return

      const communityId = currentUser.value.id === process.env.VUE_APP_CHAT_SUPPORT_USER_ID ? 'global' : currentCommunity.value.id
      this.newChatThreads = this.newChatThreads.filter((chatThreadId) => chatThreadId !== threadId)
      const commSettings = await setDoc(
        doc(currentUser.value.userRef, 'communitySettings', communityId),
        { newChatThreads: arrayRemove(threadId) },
        { merge: true }
      )
    },

    async addMessage(message: ChatMessage) {
      if (!this.messages[message.thread_id])
        this.messages[message.thread_id] = []

      this.messages[message.thread_id] = useSortedCollection(
        [...this.messages[message.thread_id], message], ['timestamp'], ['desc']
      ).value
    },

    async init() {
      const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
      const userProfile = vueRef(currentUser.value?.profile)
      const coreStore = useCoreStore()
      await this.loadUserChatSoundStatus()
      await this._resetChatStore()

      if (currentUser.value?.id === process.env.VUE_APP_CHAT_SUPPORT_USER_ID) {
        const userStatusDatabaseRef = ref(rtdb, `/status/${currentUser.value?.id}`)

        onValue(ref(rtdb, '.info/connected'), (snapshot) => {
          if (snapshot.val() === false)
            return

          onDisconnect(userStatusDatabaseRef)
            .set(this.setUserStatus('offline'))
            .then(async () => {
              await set(userStatusDatabaseRef, this.setUserStatus('online'))
            })
        })
      }

      onValue(ref(rtdb, `status/${process.env.VUE_APP_CHAT_SUPPORT_USER_ID}`), (snapshot) => {
        const supportStatus = snapshot.val()
        if (supportStatus === false)
          return

        if (process.env.VUE_APP_CHAT_SUPPORT_USER_ID)
          this.status[process.env.VUE_APP_CHAT_SUPPORT_USER_ID] = supportStatus.state === 'online'
      })

      // watch(() => currentCommunity.value, async () => {
      //   await this._resetChatStore()
      //   if (currentUser.value && currentCommunity.value) {
      //     const communityId = currentUser.value.id ===
      // process.env.VUE_APP_CHAT_SUPPORT_USER_ID ? 'global' : currentCommunity.value.id
      //     const commSettings = await getDoc(doc(currentUser.value.userRef, 'communitySettings', communityId))
      //     const comSettingsData = commSettings.data()
      //     this.newChatThreads = comSettingsData?.newChatThreads ? comSettingsData.newChatThreads : []
      //   }

      //   // need to reload userData and threads for when we clear userData.meta.activeThread
      //   // after switching communities, it reverts it back to the previous unupdated userData
      //   // await coreStore.setCommunity(currentCommunity.value!.id)
      //   await this._loadThreads()
      // }, { immediate: true })
    },

    async _loadThreadOrder() {
      const { currentUser, currentCommunity } = storeToRefs(useCoreStore())

      if (currentUser.value && currentCommunity.value) {
        const communityId = currentUser.value.id === process.env.VUE_APP_CHAT_SUPPORT_USER_ID ? 'global' : currentCommunity.value.id
        const userSettingsRef = doc(db, 'users', currentUser.value.id, 'communitySettings', communityId)
        const userSettingsSnap = await getDoc(userSettingsRef)
        const userSettingsData = userSettingsSnap.data()
        if (userSettingsData && userSettingsData.threadOrder)
          this.threadOrder = userSettingsData.threadOrder

        try {
          const supportThreads = await getDocs(
            query(
              collection(db, 'threads'),
              where('type', '==', 'support'),
              where('members', 'array-contains', currentUser.value.id)
            )
          )

          if (!supportThreads.empty) {
            supportThreads.docs.map((supportThread) => {
              if (!this.threadOrder.includes(supportThread.id))
                this.threadOrder.push(supportThread.id)
              return null
            })
          }
        } catch (err) {
          console.error('SUPPORT CHAT ERR', err)
        }
      }
    },

    async saveThreadOrder() {
      const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
      const uid = currentUser.value?.id as string

      if (currentCommunity.value && currentUser.value) {
        const communityId = currentUser.value.id === process.env.VUE_APP_CHAT_SUPPORT_USER_ID ? 'global' : currentCommunity.value.id
        const userSettingsRef = doc(db, 'users', uid, 'communitySettings', communityId)
        await setDoc(userSettingsRef, { threadOrder: this.threadOrder }, { merge: true })
      }
    },

    async _resetChatStore() {
      this.messages = {}
      this.threads = {}
      this.threadOrder = []
      this.activeThreadId = null
      this.reply = null
      if (this.threadSnapshot)
        this.threadSnapshot()
      this.threadSnapshot = null
      this.messageSnapshots = {}
    },

    async loadThreadByMembers(memberId) {
      const memberThreadId = vueRef<string | null>(null)
      const { currentUser, currentCommunity } = storeToRefs(useCoreStore())

      if (!this.threads)
        await this._loadThreads()

      Object.entries(this.threads).forEach(([key, value]) => {
        if (value.type !== 'bot') {
          const tm = value.members.map((threadMember) => threadMember.id).filter((id) => id)

          // member to chat and currentUser only
          if (tm.length === 2 && tm.includes(memberId))
            memberThreadId.value = key
        }
      })

      if (!memberThreadId.value) {
        if (!currentCommunity.value)
          throw new Error('Community not found')
        if (!currentUser.value)
          throw new Error('User auth error')
        const owner = doc(db, `users/${currentUser.value.id}`)
        const members = [owner, doc(db, `users/${memberId}`)]
        const newThread: Thread = {
          community: doc(db, 'communities', currentCommunity.value.id),
          thread_id: '',
          members,
          name: '',
          owner,
          type: 'thread'
        }
        const createdThread = await this.createThread(newThread)
        memberThreadId.value = createdThread.thread_id
      }
      return memberThreadId
    },

    async _loadThreads() {
      const alertStore = useAlertStore()
      const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
      const { can } = usePermissions()

      if (this.threadSnapshot)
        this.threadSnapshot()

      if (currentUser.value?.userRef && currentCommunity.value) {
        // roles are not loaded yet
        const isStudent = currentCommunity.value?.coach.id !== currentUser.value.id &&
          !['admin', 'coach', 'coachadmin', 'coachmoderator', 'superadmin'].includes(currentCommunity.value?.roles[currentUser.value.id])

        try {
          if (!this.threadOrder.length)
            await this._loadThreadOrder()
        } catch (err) {
          console.log('THREAD ORDER ERR', err)
        }

        const communityId = vueRef(currentCommunity.value.id)
        let threadQuery: Query<DocumentData> | undefined
        if (can('manage', 'chat')) {
          threadQuery = query(
            collection(db, 'threads'),
            where('community', 'in', [doc(db, 'communities', communityId.value), 'global'])
          )
        } else {
          threadQuery = query(
            collection(db, 'threads'),
            where('members', 'array-contains', currentUser.value.userRef)
          )
        }

        this.threadSnapshot = onSnapshot(threadQuery, async (threadSnap) => {
          await Promise.all(threadSnap.docChanges().map(async (change) => {
            if (change.type === 'added' || change.type === 'modified') {
              const thread = change.doc.data() as Thread

              if (!thread.community && thread.type === 'support') {
                await setDoc(change.doc.ref, { community: 'global' }, { merge: true })
                thread.community = 'global'
              }

              if (!thread.meta && thread.type === 'support') {
                await setDoc(change.doc.ref, { meta: { hideThread: true } }, { merge: true })
                thread.meta = { hideThread: true }
              }

              if (!currentUser.value || !thread.community)
                return

              if (thread.type === 'support' &&
                currentUser.value?.id !== process.env.VUE_APP_CHAT_SUPPORT_USER_ID &&
                !thread.members.find((m) => m.id === currentUser.value?.id))
                return

              if (thread.type === 'bot' && !thread.members.find((m) => m.id === currentUser.value?.id))
                return

              if (thread.community === 'global' && thread.type !== 'support')
                return

              if (thread.community !== 'global' && thread.community.id !== currentCommunity.value?.id)
                return

              if (thread.members && thread.members.length < 2 && !thread.name && thread.type !== 'support')
                return

              const userSnap = await getDoc(thread.owner)
              const profile = userSnap.data() as User
              const name = async () : Promise<string> => {
                if (thread.name)
                  return Promise.resolve(thread.name)

                const threadMembers = await Promise.all(thread.members
                  .map(async (member) => {
                    const memberSnap = await getDoc(member)
                    const memberData = memberSnap.data() as User
                    return { user: memberData }
                  }))
                return Promise.resolve(threadMembers.map(({ user }) => {
                  if (!user)
                    return null

                  if (user.uid !== currentUser.value?.id)
                    return `${user.firstName} ${user.lastName}` || user.email

                  return null
                }).filter((value) => value).join(', '))
              }

              thread.ownerData = { ...profile, id: userSnap.id } as User

              // hides support chat for students
              if (
                thread.type !== 'support' ||
                (thread.type === 'support' && (!isStudent || currentUser.value?.id === process.env.VUE_APP_CHAT_SUPPORT_USER_ID))
              )
                this.threads[change.doc.id] = { ...thread, name: await name(), thread_id: change.doc.id }

              let userJoinedMeeting = false
              if (thread.joinedMeeting) {
                const mmIds = thread.joinedMeeting.map(
                  (meetingMember) => meetingMember.id
                ).filter((id) => id).sort()

                userJoinedMeeting = mmIds.includes(currentUser.value?.id)
              }
              if (change.type === 'modified' && thread.startMeeting) {
                // TODO:x modify this started, stopped if we're going back to chat.ts
                if (thread.startMeeting === 'started' || thread.startMeeting === 'stopped')
                  return
                const initialUserSnap = await getDoc(thread.startMeeting)
                const initialUser = initialUserSnap.data()
                if (currentUser.value?.id === thread.startMeeting.id) {
                  const data = { startMeeting: null }
                  await setDoc(change.doc.ref, data, { merge: true })
                } else {
                  // Audio Notification
                  alertStore.setAudio(
                    'meeting',
                    'https://firebasestorage.googleapis.com/v0/b/jurni-prod.appspot.com/o/sfxs%2Fmeeting-notification.mp3?alt=media&token=4eb80eca-5bd1-4826-af76-ce6460422ca2',
                    { defaultMuted: true, muted: true }
                  )
                  alertStore.playAudio('meeting', true)

                  // Visual Notification
                  alertStore.addToast({
                    message: `Video chat from ${initialUser?.firstName || 'Unknown'}`,
                    actionLabel: 'Join',
                    onClick: () => {
                      alertStore.stopAudio('meeting')

                      const callUrl = router.resolve({
                        name: 'meet.room',
                        params: { room: thread.thread_id }
                      })

                      window.open(callUrl.href, '_blank')
                    },
                    closeOnClick: () => {
                      alertStore.stopAudio('meeting')
                    }
                  }, null)
                }
              }

              if (change.type === 'added') {
                this.typers[change.doc.id] = {}
                this.recorders[change.doc.id] = {}

                if (!Object.keys(this.messageSnapshots).includes(change.doc.id)) {
                  const addListener = await this.addMessageListener(change.doc.id, 'add')
                  const updateListener = await this.addMessageListener(change.doc.id, 'update')
                  this.messageSnapshots[change.doc.id] = { add: addListener, update: updateListener }
                } else if (!Object.keys(this.messageSnapshots[change.doc.id]).includes('add') || this.messageSnapshots[change.doc.id].add === null) {
                  this.messageSnapshots[change.doc.id].add = await this.addMessageListener(change.doc.id, 'add')
                } else if (!Object.keys(this.messageSnapshots[change.doc.id]).includes('update') || this.messageSnapshots[change.doc.id].update === null) {
                  this.messageSnapshots[change.doc.id].update = await this.addMessageListener(change.doc.id, 'update')
                }

                if (!this.threadOrder.length)
                  await this._loadThreadOrder()

                if (!this.threadOrder.includes(change.doc.id)) {
                  await this.addToTopThread(change.doc.id)
                  await this.saveThreadOrder()
                  this.newChatThreads.unshift(change.doc.id)
                }
              } else if (change.type === 'modified') {
                if (thread.typing) {
                  const typersData = {}
                  await Promise.all(thread.typing.map(async (typist) => {
                    const userSnap = await getDoc(typist)
                    const typistProfile = userSnap.data() as User
                    if (thread.type && currentUser.value?.id !== typist.id) {
                      if (['thread', 'support'].includes(thread.type))
                        typersData[typist.id] = typistProfile.firstName
                      else
                        typersData[typist.id] = typistProfile.meta?.name
                    }
                  }))
                  this.typers[change.doc.id] = typersData
                }
              }
            } else if (change.type === 'removed') {
              // console.log('inside else')
              delete this.threads[change.doc.id]
              delete this.messages[change.doc.id]
              delete this.typers[change.doc.id]
              delete this.recorders[change.doc.id]
            }
          })).then((e) => {
            console.log('error', e)
            if (!this.activeThreadId)
              this._selectUserActiveThread()
          })
        })
      }
    },

    async _selectUserActiveThread() {
      const { currentUser } = storeToRefs(useCoreStore())
      const userData = currentUser.value?.profile

      let activeThreadId = this.threadOrder[0]
      if (userData && userData.meta && userData.meta.activeThread)
        activeThreadId = userData.meta.activeThread

      this.activeThreadId = activeThreadId
    },

    async loadUserChatSoundStatus() {
      const { currentUser } = storeToRefs(useCoreStore())
      const userData = currentUser.value?.profile
      let status = true

      if (userData && userData.meta && userData.meta.chatSoundStatus != null)
        status = userData.meta.chatSoundStatus
      this.chatSoundStatus = status
    },

    async validateMemberList(members) {
      if (!this.threads)
        await this._loadThreads()

      let memberIds = members.map((member) => member.id)
      const { currentUser } = storeToRefs(useCoreStore())
      memberIds.push(currentUser.value?.id)
      memberIds = memberIds.sort()
      let existing = false

      Object.entries(this.threads).every(([key, thread]) => {
        if (thread.type !== 'bot') {
          const tm = thread.members.map(
            (threadMember) => threadMember.id
          ).filter((id) => id).sort()

          existing = (memberIds.length === tm.length) && memberIds.every((element, index) => element === tm[index])
          return !existing
        }
        return true
      })

      return existing
    },

    async getThreadIdOfMemberList(members) {
      if (!this.threads)
        await this._loadThreads()

      let memberListId = members.map((member) => member.id)
      const { userRef: currentUser } = storeToRefs(useAuthStore())
      memberListId.push(currentUser.value?.id)
      memberListId = memberListId.sort()

      let existingThreadId = ''

      Object.entries(this.threads).every(([key, value]) => {
        const thread = useThread(key)

        const threadMembersById = thread.members.value.map(
          (threadMember) => threadMember.id
        ).filter((id) => id).sort()

        const isThreadMemberEqualToMemberList = (
          (memberListId.length === threadMembersById.length) &&
          memberListId.every((element, index) => element === threadMembersById[index])
        )
        if (isThreadMemberEqualToMemberList) {
          existingThreadId = thread.doc.value?.thread_id ? thread.doc.value?.thread_id : ''
          return false
        }
        return true
      })

      return existingThreadId
    },

    async addUser(threadId: string, userId: string) {
      const threadRef = doc(collection(db, 'threads'), threadId)
      await setDoc(threadRef, { members: arrayUnion(doc(db, `users/${userId}`)) }, { merge: true })
      const rtdbThreadMemberRef = ref(rtdb, `chats/${threadId}/members/${userId}`)
      await set(rtdbThreadMemberRef, { isMember: true })
    },

    async removeUser(threadId: string, userId: string) {
      const threadRef = doc(collection(db, 'threads'), threadId)
      await setDoc(threadRef, { members: arrayRemove(doc(db, `users/${userId}`)) }, { merge: true })
      const rtdbThreadMemberRef = ref(rtdb, `chats/${threadId}/members/${userId}`)
      await remove(rtdbThreadMemberRef)
    },

    async renameThread(threadId: string, threadName: string) {
      const threadRef = doc(collection(db, 'threads'), threadId)
      await setDoc(threadRef, { name: threadName }, { merge: true })
    },

    async createThread(thread: Thread) {
      return new Promise<Thread>(async (resolve) => {
        const threadRef = doc(collection(db, 'threads'))
        const newThread: Thread = { ...thread, thread_id: threadRef.id }
        await setDoc(threadRef, newThread)
        this.threads = { [newThread.thread_id]: newThread, ...this.threads }
        this.messages[newThread.thread_id] = []
        const threadComposable = useThread(newThread.thread_id)
        threadComposable.select()
        resolve(newThread)
      })
    },

    async userIsTyping(ev) {
      if (ev.key === 'Enter')
        return

      const { userRef: currentUser } = storeToRefs(useAuthStore())
      await setDoc(doc(db, `threads/${this.activeThreadId}`), { typing: arrayUnion(currentUser.value) }, { merge: true })

      if (this.typingTimeout)
        clearTimeout(this.typingTimeout)

      await new Promise((resolve) => {
        this.typingTimeout = setTimeout(resolve, 2500)
      })
      await this.clearTyping()
      this.typingTimeout = null
    },

    async clearTyping() {
      const { userRef: currentUser } = storeToRefs(useAuthStore())
      await setDoc(doc(db, `threads/${this.activeThreadId}`), { typing: arrayRemove(currentUser.value) }, { merge: true })
    },

    async removeThread(threadId) {
      await deleteDoc(doc(collection(db, 'threads'), threadId))
      await remove(ref(rtdb, `chats/${threadId}`))
      delete this.threads[threadId]
    },

    async loadThreadMessages(threadId: string, loadMore = false, limit = 15) : Promise<boolean|null> {
      if (!this.messages[threadId])
        this.messages[threadId] = []

      let firstLoadedMessage: string | null = null
      if (this.messages[threadId].length > 0) {
        firstLoadedMessage = this.messages[threadId][0].message_id
        if (!loadMore)
          return true
      }

      const messagesRef = ref(rtdb, `chats/${threadId}/messages`)
      let initialMessageRef = rtdbQuery(messagesRef, orderByKey(), limitToLast(limit))
      if (firstLoadedMessage)
        initialMessageRef = rtdbQuery(initialMessageRef, endBefore(firstLoadedMessage))

      const initialData = await new Promise((resolve, reject) => {
        const onError = (error) => reject(error)
        const onData = (snap) => resolve(snap.val())

        onValue(initialMessageRef, onData, onError, { onlyOnce: true })
      }) as Record<string, ChatMessage>

      if (!initialData)
        return false

      const tempMessages: Array<ChatMessage> = []
      Object.values(initialData).map((value) => {
        const msg = value as ChatMessage
        if (msg.timestamp)
          msg.timestamp = new Timestamp(msg.timestamp?.seconds, msg.timestamp?.nanoseconds)

        tempMessages.push(msg)
        return null
      })

      this.messages[threadId] = tempMessages.concat(this.messages[threadId])
      return Object.values(initialData).length === limit
    },

    async saveMessage(message: ChatMessage) {
      const formattedTimestamp = message.formattedTimestamp
      delete message.formattedTimestamp

      const rtdbThreadRef = ref(rtdb, `chats/${message.thread_id}`)
      const rtdbThreadSnap = await get(rtdbThreadRef)
      const rtdbThreadData = rtdbThreadSnap.exists() ? rtdbThreadSnap.val() : {}

      if (!rtdbThreadData.members) {
        // solution for existing rtdb threads without members are not set
        // which we will use for the rtdb rule to allow reactions for non-author but member of the thread
        this.threadMembers.value.forEach(async (member) => {
          await set(
            ref(rtdb, `chats/${message.thread_id}/members/${member.id}`),
            {
              isMember: true
            }
          )
        })
      }

      await set(
        ref(rtdb, `chats/${message.thread_id}/messages/${message.message_id}`),
        message
      )

      message.formattedTimestamp = formattedTimestamp
      return message
    },

    async sendMessage(threadId: string, message: ChatMessage) {
      if (!this.messages[threadId])
        this.messages[threadId] = []

      if (this.reply)
        message.reply_to_id = this.reply.message_id

      const messagesRef = push(ref(rtdb, `chats/${threadId}/messages`))
      if (messagesRef.key) {
        message.message_id = messagesRef.key
        await set(messagesRef, message)
      }

      if (this.reply)
        this.reply = null
    },

    async editMessage(updatedMessage: ChatMessage) {
      await this.saveMessage(updatedMessage)
    },

    async replyTo(message?: ChatMessage) {
      this.reply = message
    },

    async toggleReaction(message: ChatMessage, user_id: string, reaction: string) {
      let tempReaction = (message.reactions || {})[reaction]

      if (!tempReaction)
        tempReaction = [user_id]
      else if (typeof tempReaction === 'boolean')
        tempReaction = []
      else if (!tempReaction.includes(user_id))
        tempReaction = [...tempReaction, user_id]
      else
        tempReaction = tempReaction.filter((react_user) => react_user !== user_id)

      message.reactions = { ...message.reactions, [reaction]: tempReaction }
      await this.saveMessage(message)
    },

    async deleteMessage(deletedMessage: ChatMessage) {
      await remove(
        ref(
          rtdb,
          `chats/${deletedMessage.thread_id}/messages/${deletedMessage.message_id}`
        )
      )
      this.messages[deletedMessage.thread_id] = this.messages[deletedMessage.thread_id].filter(
        (message) => deletedMessage.message_id !== message.message_id
      )
    },

    async removeGroupChatPhoto(threadRef: DocumentReference, threadAvatar: string) {
      const httpsReference = storageRef(storage, threadAvatar)
      await deleteObject(httpsReference)
      console.log('Group Chat Avatar image successfully deleted')
      setDoc(threadRef, { threadAvatar: '' }, { merge: true })
    },

    async setGroupChatPhoto(threadRef: DocumentReference, file: File) {
      const name = v4()
      const sr = storageRef(storage, `chat/avatars/${name}`)
      const fileSnap = await uploadBytes(sr, file, { contentType: file.type })
      const downloadURL = await getDownloadURL(fileSnap.ref)
      await setDoc(threadRef, { threadAvatar: downloadURL }, { merge: true })

      return downloadURL
    },

    async updateGroupChatPhoto(file: File) {
      const thread = this.activeThread
      if (!thread)
        return

      const threadId = thread.thread_id
      const threadRef = doc(collection(db, 'threads'), threadId)

      if (thread.threadAvatar)
        await this.removeGroupChatPhoto(threadRef, thread.threadAvatar)

      await this.setGroupChatPhoto(threadRef, file)
    }
  }
})

export const useSearchStore = defineStore('searchInThread', {
  state: (): threadSearchState => ({
    messageID: [],
    lastIndex: null
  }),
  getters: {
    getSearchedMessagedId(st) {
      return st.messageID
    }
  },
  actions: {
    async getSearchInThread(threadId: string, query, limit = 50) {
      const messagesRef = ref(rtdb, `chats/${threadId}/messages`)

      let initialMessageRef

      if (this.lastIndex)
        initialMessageRef = rtdbQuery(messagesRef, orderByKey(), endBefore(this.lastIndex), limitToLast(limit))
      else
        initialMessageRef = rtdbQuery(messagesRef, orderByKey(), limitToLast(limit))

      const initialData = await new Promise((resolve, reject) => {
        const onError = (error) => reject(error)
        const onData = (snap) => resolve(snap.val())

        onValue(initialMessageRef, onData, onError, { onlyOnce: true })
      }) as Record<string, ChatMessage>

      if (!initialData)
        return false

      this.lastIndex = initialData ? Object.keys(initialData)[Object.keys(initialData).length - 1] : null

      const tempMessages: Array<ChatMessage> = []
      Object.values(initialData).map((value) => {
        const msg = value as ChatMessage

        tempMessages.push(msg)
        return null
      })

      if (tempMessages?.length) {
        tempMessages.forEach((data, index) => {
          if (data.meta.content?.includes(query))
            this.messageID?.push(data.message_id)
        })
      }

      if (limit > tempMessages?.length)
        return { hasMore: false }

      return { hasMore: true }
    },
    async emptySearch() {
      this.messageID = []
      this.lastIndex = null
    }
  }
})
