// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUER_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

export const app = initializeApp(firebaseConfig)
export const functions = getFunctions(app)
export const db = getFirestore(app)
export const rtdb = getDatabase(app)
export const auth = getAuth(app)
export const analytics = getAnalytics(app)
export const storage = getStorage(app)
getPerformance(app)

if (process.env.VUE_APP_FIREBASE_EMULATOR_HOST && process.env.VUE_APP_USE_EMULATORS === 'true') {
  connectDatabaseEmulator(
    rtdb,
    process.env.VUE_APP_FIREBASE_EMULATOR_HOST || 'localhost',
    Number(process.env.VUE_APP_FIREBASE_REALTIME_EMULATOR_PORT || 9000)
  )
  connectFirestoreEmulator(
    db,
    process.env.VUE_APP_FIREBASE_EMULATOR_HOST || 'localhost',
    Number(process.env.VUE_APP_FIREBASE_FIRESTORE_EMULATOR_PORT || 8080)
  )

  connectFunctionsEmulator(
    functions,
    process.env.VUE_APP_FIREBASE_EMULATOR_HOST || 'localhost',
    Number(process.env.VUE_APP_FIREBASE_FUNCTIONS_EMULATOR_PORT || 5001)
  )

  connectStorageEmulator(
    storage,
    process.env.VUE_APP_FIREBASE_EMULATOR_HOST || 'localhost',
    Number(process.env.VUE_APP_FIREBASE_STORAGE_EMULATOR_PORT || 9199)
  )

  connectAuthEmulator(
    auth,
    `http://${process.env.VUE_APP_FIREBASE_EMULATOR_HOST || 'localhost'}:${process.env.VUE_APP_FIREBASE_AUTH_EMULATOR_PORT}`
  )
}
