import { computed, ref } from 'vue'
import _ from 'lodash'

import { useAlertStore, Notification } from '@/stores/alert'
import { doc, DocumentReference, setDoc } from 'firebase/firestore'
import { db } from '@/services/firebase'

export declare interface NotificationComposable {
  id: string
  title: string
  body: string
  actionable: boolean
  updateStatus: CallableFunction
  docRef: DocumentReference | null
  original: Notification
}

type UseNotification = (id: string) => NotificationComposable

export const useNotification : UseNotification = (id) => {
  const alertStore = useAlertStore()
  const notificationId = ref(id)

  const notification = computed(() => alertStore.getNotificationById(notificationId.value).value)
  const type = computed(() => {
    if (!notification.value)
      return []
    return notification.value.notificationType.split('.')
  })
  const actionable = computed(() => notification.value?.status === 'pending')

  const title = computed(() => {
    if (!type.value.length && !notification.value)
      return ''

    if (type.value[0] === 'invite')
      return `${_.capitalize(type.value[1])} Invitation`

    if (type.value[0] === 'tag')
      return `Someone tagged you in a ${_.capitalize(type.value[1])} ${_.capitalize(type.value[2])}`

    if (type.value[0] === 'added')
      return `Someone ${type.value[0]} you in a ${_.capitalize(type.value[1])}`

    if (type.value[0] === 'updated')
      return `${_.capitalize(type.value[1])} Update`

    if (type.value[0] === 'notify')
      return `${_.capitalize(type.value[1])} Notification`

    if (type.value[0] === 'live')
      return `Someone is ${_.capitalize(type.value[0])} in a ${_.capitalize(type.value[1])}`

    return notification.value.notificationType
  })

  const body = computed(() => {
    if (!type.value.length && !notification.value)
      return ''

    if (type.value[0] === 'invite')
      return `You have been invited to ${type.value[1]}: '${notification.value.meta.item?.name}'`

    return notification.value.meta.content ? String(notification.value.meta.content) : ''
  })

  const docRef = computed(() => notification.value.meta.item?.ref ?? null)

  const updateStatus = async (newStatus : string) : Promise<void> => {
    const notificationRef = doc(db, 'notifications', notificationId.value)
    setDoc(notificationRef, { status: newStatus }, { merge: true })
    notification.value.status = newStatus
  }

  return {
    id: notificationId.value,
    title: title.value,
    body: body.value,
    actionable: actionable.value,
    docRef: docRef.value,
    original: notification.value,
    updateStatus
  }
}
