import { storeToRefs } from 'pinia'
import { NavigationGuard } from 'vue-router'
import { useCoreStore } from '@/stores/core'
import { usePermissions } from '@/composables/usePermissions'
import { useAlertStore } from '@/stores/alert'

const routeInactiveCommunity : NavigationGuard = async (to, from, next) => {
  const { currentCommunity, awaitCurrentCommunity } = storeToRefs(useCoreStore())
  const { can } = usePermissions()
  const { add } = useAlertStore()

  await awaitCurrentCommunity.value

  if (
    currentCommunity.value?.status === 'TRIAL_EXPIRED' &&
    to.name !== 'account.trial-expired' &&
    to.name !== 'account.unavailable'
  ) {
    if (can('manage', 'community')) {
      add({ description: 'Trial Expired. Please add your community billing details or contact customer support', color: 'danger' })
      if (to.name === 'settings.account')
        return next({ name: 'settings.payments' })
      if (to.name === 'settings.payments')
        return next()
      return next({ name: 'account.trial-expired' })
    }

    return next({ name: 'account.unavailable' })
  }

  if (
    currentCommunity.value?.status === 'INACTIVE' &&
    to.name !== 'account.unavailable' &&
    to.name !== 'settings.payments'
  ) {
    if (can('manage', 'community')) {
      add({ description: 'Payment Failed. Please update your community billing details or contact customer support', color: 'danger' })
      return next({ name: 'settings.payments' })
    }

    return next({ name: 'account.unavailable' })
  }

  return next()
}

export {
  routeInactiveCommunity
}
