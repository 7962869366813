import { ref, Ref } from 'vue'

import { defineStore } from 'pinia'

type LayoutStoreState = {
  showSidebar: Ref<boolean>
  showSidebarActivator: Ref<boolean>
}

export const useLayoutStore = defineStore('layout', () : LayoutStoreState => {
  const showSidebar = ref(true)
  const showSidebarActivator = ref(true)

  return {
    showSidebar,
    showSidebarActivator
  }
})
