import { db } from '@/services/firebase'
import { useCoreStore } from '@/stores/core'
import { GameBoardCard, Group } from '@/types'
import { DocumentReference, arrayRemove, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore'
import { storeToRefs } from 'pinia'
import { Ref, ref } from 'vue'
import { usePermissions } from './usePermissions'

export const useGameboardActions = () : Record<string, CallableFunction> => {
  const { currentCommunity, currentUser } = storeToRefs(useCoreStore())
  const userWelcomeVideoProgress: Ref<boolean> = ref(true)
  const userWelcomeVideoProgressLoaded: Ref<boolean> = ref(false)

  const getAssociatedGroups = async (jurniId) => {
    const groupQuerySnapshot = await getDocs(
      query(
        collection(db, 'groups'),
        where('meta.associateJurnies', 'array-contains', jurniId)
      )
    )
    const assoicatedGroups = ref<Array<Group>>([])
    await Promise.all(groupQuerySnapshot.docs.map(async (doc) => {
      const groupData = doc.data() as Group
      groupData.id = doc.id
      if (groupData.bannerItem) {
        const contentSnap = await getDoc(groupData.bannerItem)
        const content = contentSnap.data()
        groupData.banner = content?.uploadUrl
      } else if (groupData.meta?.banner) {
        groupData.banner = groupData.meta.banner
      }
      assoicatedGroups.value.push(groupData as Group)
    }))
    return JSON.parse(JSON.stringify(assoicatedGroups.value))
  }

  const finishedWelcomeVideo = async () : Promise<void> => {
    if (!currentUser.value || !currentCommunity.value)
      return

    const userRef = currentUser.value.userRef
    const communitySettingsRef = doc(userRef, 'communitySettings', currentCommunity.value.id)
    await setDoc(communitySettingsRef, { watchedWelcomeVideo: true }, { merge: true })
    userWelcomeVideoProgress.value = true
  }

  const loadUserWelcomeVideoProgress = async () : Promise<void> => {
    userWelcomeVideoProgress.value = true
    userWelcomeVideoProgressLoaded.value = true
  }

  const savePhaseCard = async (jurniId: string, phaseId: string, changes: any) : Promise<boolean> => {
    const cardRef = doc(db, 'jurnis', jurniId, 'gameboardCards', phaseId)

    try {
      await updateDoc(cardRef, changes, { merge: true })
    } catch (err) {
      console.error(err)
      return false
    }

    return true
  }

  async function saveCard(
    jurniId: string,
    cardRef: DocumentReference,
    cardUpdates: Record<string, string>
  ) : Promise<void> {
    await setDoc(
      cardRef,
      cardUpdates,
      { merge: true }
    )
  }

  async function saveStep(jurniId: string, stepId:string, stepUpdates: Record<string, string>) : Promise<boolean> {
    try {
      await setDoc(
        doc(db, 'jurnis', jurniId, 'steps', stepId),
        stepUpdates,
        { merge: true }
      )
    } catch (error) {
      console.log(error)
      return false
    }
    return true
  }

  async function saveStepTitle(jurniId: string, stepId:string, newTitle: string) : Promise<boolean> {
    try {
      const stepStatus = await saveStep(jurniId, stepId, { title: newTitle })

      if (stepStatus) {
        const stepCards = await getDocs(
          query(
            collection(db, 'jurnis', jurniId, 'gameboardCards'),
            where('content', '==', doc(db, 'jurnis', jurniId, 'steps', stepId))
          )
        )

        if (!stepCards.empty) {
          await Promise.all(stepCards.docs.map(async (stepCard) => {
            await saveCard(jurniId, stepCard.ref, { title: newTitle })
            return null
          }))
        }
      }
    } catch (error) {
      console.log(error)
      return false
    }
    return true
  }

  return {
    getAssociatedGroups,
    finishedWelcomeVideo,
    loadUserWelcomeVideoProgress,
    savePhaseCard,
    saveStepTitle,
    saveStep
  }
}
