import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { v4 } from 'uuid'
import { storage } from '@/services/firebase'

export const useStorage = () => {
  const uploadFile = async (path: string, file: File, filename?: string) => {
    const newFileName = filename ?? file.name
    const sr = ref(storage, `${path}/${newFileName}`)
    const fileSnap = await uploadBytes(sr, file, { contentType: file.type })
    const downloadURL = await getDownloadURL(fileSnap.ref)
    return { path: `${path}/${newFileName}`, url: downloadURL }
  }

  return {
    uploadFile
  }
}
