import { collection, doc, getDocs, orderBy, query } from 'firebase/firestore'
import { db } from '@/services/firebase'
import { NavigationGuard } from 'vue-router'

export const fallbackToFirstStep : NavigationGuard = async (to, from, next) => {
  const { jurniId, stepId } = to.params

  if (!stepId) {
    const jurniRef = doc(db, 'jurnis', jurniId as string)
    const stepsRef = collection(jurniRef, 'steps')
    const stepsQuery = query(stepsRef)
    const stepsQuerySnap = await getDocs(stepsQuery)
    const firstStep = stepsQuerySnap.docs[0]
    try {
      next({ name: 'jurni.step', params: { jurniId, stepId: firstStep?.id } })
    } catch (e: any) {
      throw new Error(e)
    }
  }
  next()
}

export const fallbackToFirstChapter : NavigationGuard = async (to, from, next) => {
  const { jurniId, stepId, chapterId } = to.params
  if (!chapterId) {
    const stepRef = doc(db, 'jurnis', jurniId as string, 'steps', stepId as string)
    const chaptersQuerySnap = await getDocs(query(
      collection(stepRef, 'chapters'),
      orderBy('order')
    ))
    const firstChapter = chaptersQuerySnap.docs[0]

    try {
      next({ name: 'jurni.step.chapter',
        params: {
          jurniId,
          stepId,
          chapterId: firstChapter?.id
        }
      })
    } catch (e: any) {
      throw new Error(e)
    }
  } else {
    next()
  }
}

export default {
  fallbackToFirstStep
}
