import { RouterView } from 'vue-router'

import TrialExpired from '@/views/account/trial_expired.vue'
import AccountUnavailable from '@/views/account/unavailable.vue'
import Payment from '@/views/account/payment.vue'

export default [
  {
    name: 'account',
    path: '/account',
    component: RouterView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'trial-expired',
        name: 'account.trial-expired',
        component: TrialExpired
      },
      {
        alias: '/unavailable',
        path: 'unavailable',
        name: 'account.unavailable',
        component: AccountUnavailable
      },
      {
        path: 'payment',
        name: 'account.payment',
        component: Payment
      }
    ]
  }
]
