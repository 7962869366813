
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'JurInput',
    props: {
      placeholder: {
        type: String,
        default: 'search...'
      }
    }
  })
