import { defineStore, storeToRefs } from 'pinia'
import { ref as vueRef } from 'vue'

import { db, storage } from '@/services/firebase'
import {
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  addDoc,
  query,
  where,
  documentId,
  arrayRemove,
  orderBy,
  Query,
  endBefore,
  startAfter,
  limit,
  Timestamp,
  onSnapshot,
  DocumentSnapshot,
  deleteDoc,
  DocumentReference,
  arrayUnion,
  Unsubscribe
} from 'firebase/firestore'
import { deleteObject, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { v4 } from 'uuid'

import { GroupPost, Group, GroupUser, GroupPostComment, UserGroupPostReactions, MeetingEvent, User, Thread } from '@/types'

import { useAlertStore } from './alert'

import { useMember } from '@/composables/useMember'

import { Ref } from 'vue'
import { useAppOnboardingStore } from '@/stores/appOnboarding'
import { useCoreStore } from '@/stores/core'
import { usePermissions } from '@/composables/usePermissions'
import { useNotification } from '@/composables/useNotification'

type NewMention = {
  postId: string
  groupId: string
}

type GroupStoreState = {
  groups: Record<string, Group>
  currentGroupId: string | null
  availableMembers: Array<{
    id: string
    firstName?: string
    lastName?: string
    email?: string
    role?: string
  }>
  members: GroupUser[]
  newPosts: Record<string, GroupPost>
  listeningForNewPosts: boolean
  lastVisiblePostSnap: DocumentSnapshot | undefined
  posts: Record<string, GroupPost>
  roleOptions: ('admin' | 'poster' | 'commenter' | 'viewer')[],
  groupSnapUnsub: Unsubscribe | null
}

export const groupRoles = ['admin', 'poster', 'commenter', 'viewer']

export const useGroupStore = defineStore('group', {
  state: (): GroupStoreState => ({
    groups: {},
    currentGroupId: null,
    newPosts: {},
    listeningForNewPosts: false,
    lastVisiblePostSnap: undefined,
    availableMembers: [],
    members: [],
    posts: {},
    roleOptions: ['admin', 'poster', 'commenter', 'viewer'],
    groupSnapUnsub: null
  }),
  getters: {
    groupList: (st: GroupStoreState) => Object.values(st.groups),
    currentGroup: (st: GroupStoreState) : Group | null => {
      if (!st.currentGroupId)
        return null

      return st.groups[st.currentGroupId]
    },
    hasNewPosts: (st: GroupStoreState) => Object.keys(st.newPosts).length > 0,
    postsList: (st: GroupStoreState) => Object.values(st.posts).sort((a, b) => {
      if (a.timestamp.seconds < b.timestamp.seconds)
        return 1

      return -1
    }),
    getAvailableCommunityMembers: (st: GroupStoreState) => {
      const { getCommunityMembers } = useCoreStore()

      const availableMemberIds = st.availableMembers.map((member) => member.id)

      getCommunityMembers
        .forEach((member) => {
          if (!availableMemberIds.includes(member.id)) {
            st.availableMembers.push({
              id: member.id,
              firstName: member.profile?.firstName,
              lastName: member.profile?.lastName,
              email: member.profile?.email
            })
          }
        })
      return st.availableMembers
    },
    getAvailableMembers: (st: GroupStoreState) => {
      const { getAllCommunityMembers } = useCoreStore()
      if (!st.currentGroupId || !st.groups || !st.groups[st.currentGroupId])
        return []

      const groupMemberIds = st.groups[st.currentGroupId].members.map((member) => member.id)

      st.availableMembers = []
      getAllCommunityMembers
        .forEach((member) => {
          if (!groupMemberIds.includes(member.id)) {
            st.availableMembers.push({
              id: member.id,
              firstName: member.profile?.firstName,
              lastName: member.profile?.lastName,
              email: member.profile?.email
            })
          }
        })
      return st.availableMembers
    },
    getRole: (st: GroupStoreState) => {
      if (!st.currentGroupId)
        return 'viewer'

      const group = st.groups[st.currentGroupId]
      const { currentUser } = storeToRefs(useCoreStore())

      if (!currentUser.value)
        return 'viewer'

      if (currentUser.value.id === group.owner.id)
        return 'admin'

      if (group.accessLevel && group.accessLevel[currentUser.value.id])
        return group.accessLevel[currentUser.value.id]

      return 'viewer'
    },
    hasGroupTemplate: (st: GroupStoreState) => {
      if (!st.currentGroupId)
        return false

      const group = st.groups[st.currentGroupId]
      if (group?.meta?.template)
        return true
      return false
    }
  },
  actions: {
    getPostById(postId: string) {
      if (!this.posts[postId])
        return null

      return this.posts[postId]
    },
    getCommentsByPostId(postId: string) {
      if (!this.posts[postId] || !this.posts[postId].comments)
        return []
      return Object.values(this.posts[postId].comments)
    },

    getGroupById(id: string) {
      return this.groups[id] || {}
    },

    cleanGroupStore() {
      this.groups = {}
      this.currentGroupId = null
      this.newPosts = {}
      this.listeningForNewPosts = false
      this.lastVisiblePostSnap = undefined
      this.availableMembers = []
      this.members = []
      this.posts = {}
      this.roleOptions = ['admin', 'poster', 'commenter', 'viewer']
      this.groupSnapUnsub = null
    },

    async loadGroups(currentCommunityId : Ref<string>) {
      if (!currentCommunityId.value)
        return

      const { can } = usePermissions()
      const { currentUser } = storeToRefs(useCoreStore())
      const communityRef = doc(db, 'communities', currentCommunityId.value)

      let groupQuery = query(
        collection(db, 'groups'),
        where('community', 'in', [communityRef, 'global'])
      )

      if (!can('manage', 'community'))
        groupQuery = query(groupQuery, where('members', 'array-contains', currentUser.value?.userRef))

      const groupQuerySnapshot = await getDocs(groupQuery)

      // console.log('groupQuerySnapshot', groupQuerySnapshot.size)
      // this.groups = {}
      await Promise.all(groupQuerySnapshot.docs.map(async (doc) => {
        const group = doc.data() as Group

        if (group.meta?.deleted === true)
          return

        group.id = doc.id
        if (group.bannerItem) {
          const contentSnap = await getDoc(group.bannerItem)
          const content = contentSnap.data()
          group.banner = content?.uploadUrl
        }

        if (group.largeBannerItem) {
          const contentSnap = await getDoc(group.largeBannerItem)
          const content = contentSnap.data()
          group.largeBanner = content?.uploadUrl
        }

        this.groups[group.id] = group
      }))

      const templateGroupQuerySnapshot = await getDocs(
        query(
          collection(db, 'groups'),
          where('community', '==', communityRef),
          where('meta.template', '==', true)
        )
      )
      await Promise.all(templateGroupQuerySnapshot.docs.map(async (doc) => {
        const groupData = doc.data() as Group
        groupData.id = doc.id

        if (groupData.bannerItem) {
          const contentSnap = await getDoc(groupData.bannerItem)
          const content = contentSnap.data()
          groupData.banner = content?.uploadUrl
        }

        if (groupData.largeBannerItem) {
          const contentSnap = await getDoc(groupData.largeBannerItem)
          const content = contentSnap.data()
          groupData.largeBanner = content?.uploadUrl
        }

        this.groups[groupData.id] = groupData
      }))

      await this.loadGroupMembers()
    },
    async loadGroup(groupId: string, forceReload = false) {
      const groupRef = doc(db, 'groups', groupId)
      const groupSnapshot = await getDoc(groupRef)
      const groupData = groupSnapshot.data() as Group

      if (groupData.meta?.deleted === true)
        forceReload = false

      if (forceReload) {
        if (groupData.bannerItem) {
          const contentSnap = await getDoc(groupData.bannerItem)
          const content = contentSnap.data()
          groupData.banner = content?.uploadUrl
        }

        if (groupData.largeBannerItem) {
          const contentSnap = await getDoc(groupData.largeBannerItem)
          const content = contentSnap.data()
          groupData.largeBanner = content?.uploadUrl
        }

        this.groups[groupId] = groupData
      }
      return groupData
    },
    async selectGroup(groupId: string) {
      if (this.currentGroupId !== groupId)
        this.members = []
      this.currentGroupId = groupId
      this.posts = {}
      this.lastVisiblePostSnap = undefined
      await this.loadGroupPosts(groupId)
      await this.loadGroupMembers()
    },
    async loadGroupMembers(force = false) {
      if (!this.currentGroup)
        return []
      if (this.members.length > 0 && !force)
        return this.members

      this.members = []
      const group = vueRef(this.currentGroup ?? { accessLevel: {} })
      return group.value.members.filter(async (member: DocumentReference) => {
        if (!member.id)
          return false
        const memberSnap = await getDoc(member)
        const memberItem = memberSnap.data()
        let role = 'viewer'

        if (group.value.accessLevel && Object.keys(group.value.accessLevel).includes(member.id))
          role = group.value.accessLevel[member.id]

        if (group.value.owner.id === member.id)
          role = 'admin'

        const isMember = () => this.members.filter((curMember) => member.id === curMember.id)
        if (memberItem && isMember().length < 1) {
          this.members.push({
            id: member.id,
            avatar: memberItem?.avatar,
            name: [
              memberItem?.firstName,
              ...(memberItem?.middleInitial ? [memberItem?.middleInitial] : []),
              memberItem?.lastName
            ].join(' '),
            email: memberItem?.email,
            invited: false,
            role
          })
        }
        return true
      })
    },
    async removeMember(id : string) {
      const groupRef =
      await setDoc(
        doc(db, `groups/${this.currentGroupId}`),
        { members: arrayRemove(doc(db, `users/${id}`)) },
        { merge: true }
      )
      this.members = this.members.filter((member) => member.id !== id)
    },
    showNewPosts() {
      Object.values(this.newPosts).forEach((post) => {
        this.posts[post.id] = post
      })
      this.newPosts = {}
    },
    async viewAddedGroupNotif(groupId: string) {
      const { currentUser } = storeToRefs(useCoreStore())

      if (!groupId || !currentUser.value?.id)
        return

      // read all added group notifications
      const groupRef = doc(db, 'groups', groupId)
      const notifSnaps = await getDocs(
        query(
          collection(db, 'notifications'),
          where('meta.item.ref', '==', groupRef),
          where('meta.user', '==', doc(db, 'users', currentUser.value?.id)),
          where('notificationType', '==', 'added.group')
        )
      )

      await Promise.all(notifSnaps.docs.map(async (doc) => {
        const notif = useNotification(doc.ref.id)
        if (notif.original.status === 'new')
          await notif.updateStatus('read')
      }))
    },
    async viewGroupLiveNotif(groupId: string) {
      const { currentUser } = storeToRefs(useCoreStore())

      if (!groupId || !currentUser.value?.id)
        return

      // read all group live notifications
      const groupRef = doc(db, 'groups', groupId)
      const notifSnaps = await getDocs(
        query(
          collection(db, 'notifications'),
          where('meta.item.ref', '==', groupRef),
          where('meta.user', '==', doc(db, 'users', currentUser.value?.id)),
          where('notificationType', '==', 'live.group')
        )
      )

      await Promise.all(notifSnaps.docs.map(async (doc) => {
        const notif = useNotification(doc.ref.id)
        if (notif.original.status === 'new')
          await notif.updateStatus('read')
      }))
    },
    async viewMentionedPost(groupId: string, postId: string) {
      const { currentUser } = storeToRefs(useCoreStore())

      if (!groupId || !postId || !currentUser.value?.id)
        return

      // read all group post tag notifications
      const groupPostRef = doc(db, 'groups', groupId, 'posts', postId)
      const notifSnaps = await getDocs(
        query(
          collection(db, 'notifications'),
          where('meta.item.ref', '==', groupPostRef),
          where('meta.user', '==', doc(db, 'users', currentUser.value?.id))
        )
      )

      await Promise.all(notifSnaps.docs.map(async (doc) => {
        const notif = useNotification(doc.ref.id)
        if (notif.original.status === 'new')
          await notif.updateStatus('read')
      }))
    },
    async loadGroupPosts(groupId) {
      const firstLimit = 1
      const loadMoreLimit = 1

      const groupRef = doc(db, 'groups', groupId)
      const groupPostsCollectionRef = collection(groupRef, 'posts')

      const firstQ = query(
        groupPostsCollectionRef,
        orderBy('timestamp', 'desc'),
        limit(firstLimit)
      )

      let nextQ : Query | null = null
      if (this.lastVisiblePostSnap) {
        nextQ = query(
          groupPostsCollectionRef,
          orderBy('timestamp', 'desc'),
          startAfter(this.lastVisiblePostSnap),
          limit(loadMoreLimit)
        )
      }

      if (!this.listeningForNewPosts) {
        const newQ = query(
          groupPostsCollectionRef,
          orderBy('timestamp', 'desc'),
          endBefore(Timestamp.now())
        )

        this.groupSnapUnsub = onSnapshot(newQ, (qSnap) => {
          qSnap.docChanges().forEach((change) => {
            const docData = change.doc.data() as GroupPost
            const { currentUser } = storeToRefs(useCoreStore())

            if (change.type === 'added') {
              docData.id = change.doc.id

              if (docData.from.id === currentUser.value?.id)
                this.posts[docData.id] = docData
              else
                this.newPosts[docData.id] = docData
            }
          })
          this.listeningForNewPosts = true
        })
      }

      const q = nextQ || firstQ
      const postsSnap = await getDocs(q)

      const lastPost = postsSnap.docs[postsSnap.size - 1]
      this.lastVisiblePostSnap = lastPost

      postsSnap.docs.forEach((doc) => {
        const post = doc.data() as GroupPost
        post.id = doc.id
        post.comments = {}

        this.addPost(post)
      })

      const expectedSize = nextQ ? loadMoreLimit : firstLimit
      if (postsSnap.empty || (postsSnap.size < expectedSize)) {
        this.lastVisiblePostSnap = undefined
        return false
      }

      return true
    },
    async loadGroupPost(postId) {
      if (this.currentGroupId) {
        const postRef = collection(db, 'groups', this.currentGroupId, 'posts')

        const postSnap = await getDocs(
          query(
            postRef,
            where(documentId(), '==', `${postId}`)
          )
        )

        postSnap.forEach((doc) => {
          const post = doc.data() as GroupPost
          post.id = doc.id
          post.comments = {}

          this.addPost(post)
        })
      }
    },
    addPost(post) {
      this.posts[post.id] = post
      this.loadComments(post.id)
      this.loadCurrentUsersPostReactions(post.id)
    },
    async createPost(post) {
      if (this.currentGroup && this.currentGroup?.id) {
        post.timestamp = Timestamp.now()

        post.reactions = {
          THUMB_UP: 0,
          LOVE: 0,
          SAD: 0,
          ANGRY: 0,
          SURPRISE: 0,
          LAUGH: 0
        }

        const collectionRef = collection(db, 'groups', this.currentGroup.id, 'posts')
        const postRef = await addDoc(collectionRef, post)

        const postSnap = await getDoc(postRef)
        const postData = postSnap.data() as GroupPost
        postData.id = postRef.id
        postData.comments = {}
        this.addPost(postData)

        const appOnboardingStore = useAppOnboardingStore()
        appOnboardingStore.completeItem('group.post.create')
      }
    },
    async createTemplatePost(groupId, post) {
      try {
        if (groupId) {
          const postRefs = collection(db, 'groups', groupId, 'posts')
          const postSnaps = await getDocs(
            query(
              postRefs,
              where('from', '==', post.from)
            )
          )

          const templatePostExists = postSnaps.docs.length > 0
          if (!templatePostExists) {
            const groupRef = doc(db, `groups/${groupId}`)
            await setDoc(groupRef, { members: arrayUnion(doc(db, `users/${process.env.VUE_APP_CHAT_SUPPORT_USER_ID}`)) }, { merge: true })
            await setDoc(groupRef, { owner: doc(db, `users/${process.env.VUE_APP_CHAT_SUPPORT_USER_ID}`) }, { merge: true })
            post.timestamp = Timestamp.now()
            post.reactions = {
              THUMB_UP: 0,
              LOVE: 1,
              SAD: 0,
              ANGRY: 0,
              SURPRISE: 0,
              LAUGH: 0
            }

            const collectionRef = collection(db, 'groups', groupId, 'posts')
            const postRef = await addDoc(collectionRef, post)
            const postSnap = await getDoc(postRef)
            const postData = postSnap.data() as GroupPost
            postData.id = postRef.id
            postData.comments = {}
            this.addPost(postData)
          }
        }
      } catch (error) {
        console.log('Error:', error)
      }
    },
    async editPost(postId, post) {
      if (this.currentGroup && this.currentGroup?.id) {
        post.timestamp = Timestamp.now()

        const postRef = doc(collection(db, 'groups', this.currentGroup.id, 'posts'), postId)
        await setDoc(postRef, { ...post }, { merge: true })

        const postSnap = await getDoc(postRef)
        const postData = postSnap.data() as GroupPost
        postData.id = postRef.id

        delete this.posts[postId]
        this.posts[post.id] = post
      }
    },
    async deletePost(postId) {
      if (this.currentGroup && this.currentGroup?.id) {
        deleteDoc(doc(collection(db, 'groups', this.currentGroup.id, 'posts'), postId))
        delete this.posts[postId]
      }
    },
    async uploadPostFile(type: 'image' | 'video', file: File) {
      const name = v4()
      const sr = ref(storage, `groups/posts/${type}/${name}`)
      const fileSnap = await uploadBytes(sr, file, { contentType: file.type })
      return fileSnap
    },
    async removeMedia(url: string) {
      const urlToDelete = new URL(url)
      const splitURL = urlToDelete.pathname.split('/o/')
      const path = splitURL[1]
      const pathDecoded = path.replaceAll('%2F', '/')
      const mediaRef = ref(storage, pathDecoded)
      await deleteObject(mediaRef)
    },
    async addComment(post, comment) {
      if (this.currentGroup && this.currentGroup?.id) {
        comment.timestamp = Timestamp.now()

        const { currentUser } = storeToRefs(useCoreStore())
        comment.from = currentUser.value?.userRef

        const collectionRef = collection(db, 'groups', this.currentGroup.id, 'posts', post.id, 'comments')

        const newCommentRef = await addDoc(collectionRef, comment)
        const newCommentSnap = await getDoc(newCommentRef)
        const newComment = newCommentSnap.data() as GroupPostComment

        newComment.id = newCommentRef.id
        newComment.from = useMember(newComment.from?.id)

        this.posts[post.id].comments[newCommentRef.id] = newComment
      }
    },
    async editComment(post, comment) {
      if (this.currentGroup && this.currentGroup?.id) {
        comment.timestamp = Timestamp.now()

        const { currentUser } = storeToRefs(useCoreStore())
        comment.from = currentUser.value?.userRef

        const commentRef = doc(collection(db, 'groups', this.currentGroup.id, 'posts', post.id, 'comments'), comment.id)
        await setDoc(commentRef, { ...comment }, { merge: true })

        const commentSnap = await getDoc(commentRef)
        const commentData = commentSnap.data() as GroupPostComment

        commentData.id = commentRef.id
        commentData.from = useMember(commentData.from?.id)

        delete this.posts[post.id].comments[commentData.id]
        this.posts[post.id].comments[comment.id] = commentData
      }
    },
    async deleteComment(post, comment) {
      if (this.currentGroup && this.currentGroup?.id) {
        const commmentRef = doc(db, 'groups', this.currentGroup.id, 'posts', post.id, 'comments', comment.id)

        await deleteDoc(commmentRef)

        delete this.posts[post.id].comments[commmentRef.id]
      }
    },
    async loadCurrentUsersPostReactions(postId) {
      const { currentUser } = storeToRefs(useCoreStore())
      if (this.currentGroupId) {
        const reactionsRef = collection(db, 'groups', this.currentGroupId, 'posts', postId, 'reactions')

        const reactionsSnap = await getDocs(
          query(
            reactionsRef,
            where(documentId(), '==', `${postId}_${currentUser.value?.id}`)
          )
        )

        if (reactionsSnap.size > 0) {
          reactionsSnap.forEach((doc) => {
            this.posts[postId].reaction = doc.data() as UserGroupPostReactions
          })
        } else {
          this.posts[postId].reaction = {
            THUMB_UP: false,
            LOVE: false,
            SAD: false,
            ANGRY: false,
            SURPRISE: false,
            LAUGH: false
          } as UserGroupPostReactions
        }
      }
    },
    patchPostReactionCount(post, oldReaction, newReaction) {
      Object.keys(newReaction)
        .forEach((key) => {
          const patch = (oldReaction && newReaction[key] !== oldReaction[key])
          const firstReaction = !oldReaction && newReaction[key]
          if (patch || firstReaction) {
            if (!post.reactions[key])
              post.reactions[key] = 0

            if (newReaction[key])
              post.reactions[key] += 1

            if (patch && !newReaction[key])
              post.reactions[key] -= 1
          }

          return false
        })
    },
    async addReaction(post, reaction) {
      if (this.currentGroupId) {
        const { currentUser } = storeToRefs(useCoreStore())
        const reactionRef = doc(db, 'groups', this.currentGroupId, 'posts', post.id, 'reactions', `${post.id}_${currentUser.value?.id}`)

        setDoc(reactionRef, reaction, { merge: true })
        const storedPost = this.posts[post.id]

        const oldReactionValues = storedPost.reaction
        storedPost.reaction = reaction

        this.patchPostReactionCount(storedPost, oldReactionValues, reaction)
      }
    },
    async loadComments(postId, lastVisibleComment: GroupPostComment | null = null) {
      const firstLimit = 2
      const loadMoreLimit = 10
      let expectedResultLength = firstLimit

      if (this.currentGroupId) {
        const commentsCollectionRef = collection(db, 'groups', this.currentGroupId, 'posts', postId, 'comments')

        let commentQuery = query(
          commentsCollectionRef,
          orderBy('timestamp', 'desc'),
          limit(firstLimit)
        )

        if (lastVisibleComment) {
          expectedResultLength = loadMoreLimit
          commentQuery = query(
            commentsCollectionRef,
            orderBy('timestamp', 'desc'),
            startAfter(lastVisibleComment.timestamp),
            limit(loadMoreLimit)
          )
        }

        const commentsSnap = await getDocs(commentQuery)

        const post = this.posts[postId]

        if (commentsSnap.docs.length < expectedResultLength)
          post.hasMoreComments = false
        else
          post.hasMoreComments = true

        commentsSnap.docs.forEach((comment, index) => {
          if ((!lastVisibleComment && index < 1) || lastVisibleComment) {
            if (!post.comments)
              post.comments = {}

            const commentData = comment.data()
            commentData.from = useMember(commentData.from?.id)
            commentData.id = comment.id

            post.comments[comment.id] = commentData
          }
        })
      }
    },
    sendInvites(data : Array<GroupUser>) {
      const counts = { success: 0, failure: 0 }
      const alertStore = useAlertStore()
      data.map((value) => {
        const notificationData = {
          meta: {
            user: doc(db, `users/${value.id}`),
            item: {
              ref: doc(db, `groups/${this.currentGroupId}`),
              name: this.currentGroup?.name || ''
            }
          },
          status: 'pending',
          notificationType: 'invite.group'
        }
        try {
          setDoc(doc(collection(db, 'notifications')), notificationData)
          counts.success += 1
        } catch (error) {
          counts.failure += 1
        }
        return true
      })

      if (counts.success > 0)
        alertStore.add({ description: `Successfully sent group invitations to ${counts.success} member(s)`, color: 'success' })

      if (counts.failure > 0)
        alertStore.add({ description: `Failed to send group invitations to ${counts.failure} member(s)`, color: 'danger' })
    },
    async addMembers(users: Array<GroupUser>) {
      const alertStore = useAlertStore()

      if (!this.currentGroupId)
        return

      await Promise.allSettled(users.map(async (user) => {
        if (!this.currentGroupId)
          return Promise.reject(new Error('Missing group id'))

        const groupRef = doc(db, 'groups', this.currentGroupId)
        const userRef = doc(db, 'users', user.id)

        try {
          await setDoc(groupRef,
            {
              members: arrayUnion(userRef),
              accessLevel: { [user.id]: user.role }
            },
            { merge: true })

          this.autoAddToThread(groupRef, userRef)
          return Promise.resolve(user)
        } catch (e) {
          return Promise.reject(e)
        }
      })).then((results) => {
        const errMsgs: any = []

        results.forEach((result) => {
          if (result.status === 'fulfilled')
            alertStore.add({ description: `${result.value.firstName} ${result.value.lastName} added to group.`, color: 'success' })

          if (result.status === 'rejected')
            errMsgs.push(result.reason.message ?? result.reason)
        })

        if (errMsgs.length > 0)
          alertStore.add({ description: `Failed to add some users: ${errMsgs.join(', ')}`, color: 'danger' })
      })

      await this.loadGroup(this.currentGroupId, true)
      await this.loadGroupMembers(true)
    },
    async updateMemberRole(memberId: string, newRole: string) {
      await setDoc(
        doc(db, 'groups', this.currentGroupId ?? ''),
        { accessLevel: { [memberId]: newRole } },
        { merge: true }
      )
      this.groups[this.currentGroupId ?? ''].accessLevel[memberId] = newRole
      this.members = this.members.map((mem) => {
        if (mem.id === memberId)
          mem.role = newRole

        return mem
      })
    },
    async removeGroupTemplate(groupId) {
      try {
        const groupRef = doc(db, 'groups', groupId)
        await setDoc(groupRef, { meta: { templateVisibility: false } }, { merge: true })

        if (!this.groups[groupId] || !this.groups[groupId].meta)
          await this.loadGroup(groupId, true)
        else
          this.groups[groupId].meta!.templateVisibility = false
      } catch (err) {
        console.log(err)
      }
    },
    async removeGroup(groupId: string) {
      const alertStore = useAlertStore()
      try {
        const groupRef = doc(db, 'groups', groupId)
        await setDoc(groupRef, { meta: { deleted: true } }, { merge: true })

        if (!this.groups[groupId] || !this.groups[groupId].meta)
          await this.loadGroup(groupId, true)
        else
          this.groups[groupId].meta = { ...this.groups[groupId].meta, deleted: true }
        alertStore.add({ description: 'Removed group', color: 'success' })
      } catch (err) {
        // console.log(err)
        alertStore.add({ description: 'Failed to remove group', color: 'danger' })
      }
    },
    async createGroup(obj) {
      try {
        const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
        if (currentUser.value?.userRef) {
          const owner = doc(db, `users/${currentUser.value?.userRef.id}`)
          const members = [owner]
          obj.members.forEach((member) => members.push(doc(db, `users/${member.id}`)))
          const name = obj.groupName
          const currentCommunityId = obj.currentCommunityId
          const description = obj.description ? obj.description : ''
          const largeBannerItem = obj.largeBannerItem ? obj.largeBannerItem : ''
          const autoAdd = obj.allAutoMember
          const associateJurnies = obj.associateJurnies
          const slectedMembersOfJurni = obj.slectedMembersOfJurni ? obj.slectedMembersOfJurni : []
          const newGroupCreated: Group = {
            community: doc(db, 'communities', currentCommunityId),
            id: '',
            members,
            name,
            owner,
            description,
            accessLevel: {},
            largeBannerItem,
            meta: {
              autoAdd,
              associateJurnies,
              slectedMembersOfJurni,
              allMember: obj.allMember ? obj.allMember : false,
              thread_id: obj.threadId
            }
          }
          const groupRef = doc(collection(db, 'groups'))
          const newGroup: Group = { ...newGroupCreated, id: groupRef.id, thread: obj.thread || null }
          await setDoc(groupRef, newGroup)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateGroup(group, groupId) {
      try {
        const { currentUser } = storeToRefs(useCoreStore())

        if (!currentUser.value || !currentUser.value.userRef)
          return

        const groupRef = doc(db, 'groups', groupId)
        const groupData = (await getDoc(groupRef)).data() as Group

        const name = group.groupName ?? groupData.name ?? ''
        const autoAdd = group.allAutoMember ?? false
        const description = group.description ?? groupData.description ?? ''
        const slectedMembersOfJurni = group.slectedMembersOfJurni ? group.slectedMembersOfJurni : []
        const associateJurnies = group.associateJurnies

        const members: Array<DocumentReference> = [groupData.owner]
        group.members.forEach((member) => members.push(doc(db, `users/${member.id}`)))

        const updatedGroupData: Partial<Group> = {
          name,
          description,
          members,
          thread: group.thread || null,
          meta: {
            autoAdd,
            slectedMembersOfJurni,
            associateJurnies,
            allMember: group.allMember ? group.allMember : false
          }
        }

        if (group.largeBannerItem)
          updatedGroupData.largeBannerItem = group.largeBannerItem

        await setDoc(groupRef, updatedGroupData, { merge: true })
        this.autoAddToThread(groupRef, members)
      } catch (error) {
        console.log(error)
      }
    },
    async saveGroup(groupId: string, groupUpdates: Record<string, string>) {
      try {
        await setDoc(
          doc(db, 'groups', groupId),
          groupUpdates,
          { merge: true }
        )
        await this.loadGroup(groupId, true)
      } catch (error) {
        console.log(error, 'error is here')
      }
    },
    async unsetGroupBanner(groupId: string) {
      const groupRef = doc(db, 'groups', groupId)
      await setDoc(groupRef, { bannerItem: '' }, { merge: true })
      await this.loadGroup(groupId, true)
    },
    async removeGroupBanner(groupId: string, groupBanner: string) {
      try {
        const httpsReference = ref(storage, groupBanner)
        await deleteObject(httpsReference)
          .finally(async () => {
            // for non-existent or external reference so we could push
            // through removing/updating banner
            await this.unsetGroupBanner(groupId)
          })
      } catch (err) {
        // for non-existent or external reference so we could push
        // through removing/updating banner
        console.log(err)
        await this.unsetGroupBanner(groupId)
      }
    },
    async setGroupBanner(file: File) {
      const name = v4()
      const sr = ref(storage, `groups/banner/${name}`)
      const fileSnap = await uploadBytes(sr, file, { contentType: file.type })
      const downloadURL = await getDownloadURL(fileSnap.ref)
      return downloadURL
    },
    async getAssociatedGroups(jurniId) {
      const groupQuerySnapshot = await getDocs(
        query(
          collection(db, 'groups'),
          where('meta.associateJurnies', 'array-contains', jurniId)
        )
      )
      const groups : Group[] = []
      await Promise.all(groupQuerySnapshot.docs.map(async (doc) => {
        const groupData = doc.data() as Group
        groupData.id = doc.id
        if (groupData.bannerItem) {
          const contentSnap = await getDoc(groupData.bannerItem)
          const content = contentSnap.data()
          groupData.banner = content?.uploadUrl
        } else if (groupData.meta?.banner) {
          groupData.banner = groupData.meta.banner
        }
        groups.push(groupData as Group)
      }))
      return groups
    },
    async checkAutoAddToGroup(communityId, userId) {
      const communityRef = doc(db, 'communities', communityId)
      const groupQuerySnapshot = await getDocs(
        query(
          collection(db, 'groups'),
          where('community', '==', communityRef)
        )
      )
      groupQuerySnapshot.forEach(async (docs) => {
        const groupData = docs.data() as Group
        if (groupData?.meta?.autoAdd) {
          const groupRef = doc(db, `groups/${groupData.id}`)
          await setDoc(groupRef, { members: arrayUnion(doc(db, `users/${userId}`)) }, { merge: true })
        }
      })
      return true
    },

    async createGroupEvent(values) {
      const { currentCommunity } = storeToRefs(useCoreStore())

      if (!currentCommunity.value)
        return

      const communityRef = doc(db, 'communities', currentCommunity.value?.id)
      const newGroupEvent: Partial<MeetingEvent> = { ...values }
      const newGroupEventRef = doc(collection(db, 'meetingEvents'))
      newGroupEvent.community = communityRef

      await setDoc(newGroupEventRef, newGroupEvent)
    },

    async loadGroupTemplate(currentCommunityId : Ref<string>) {
      if (!currentCommunityId.value)
        return

      // const groupId : string[] = []
      const communityRef = doc(db, 'communities', currentCommunityId.value)
      const groupQuerySnapshot = await getDocs(
        query(
          collection(db, 'groups'),
          where('community', '==', communityRef),
          where('meta.template', '==', true)
        )
      )
      if (!groupQuerySnapshot.docs.length)
        await this.createTemplateGroup(currentCommunityId.value)

      await this.loadGroupMembers()
      await this.loadGroups(currentCommunityId)
    },
    async createTemplateGroup(currentCommunity) {
      const owner = doc(db, `users/${process.env.VUE_APP_CHAT_SUPPORT_USER_ID}`)
      const members = [owner]
      members.push(doc(db, `users/${process.env.VUE_APP_CHAT_SUPPORT_USER_ID}`))
      const name = 'Group Template'
      const description = 'Group Template'
      const newGroupCreated: Group = {
        community: doc(db, 'communities', currentCommunity),
        id: '',
        members,
        name,
        owner,
        description,
        accessLevel: {},
        meta: {
          template: true
        }
      }
      const groupRef = doc(collection(db, 'groups'))
      const newGroup: Group = { ...newGroupCreated, id: groupRef.id }
      await setDoc(groupRef, newGroup)
      const post = {
        type: 'image',
        meta: {
          content: '<p><span style="font-family: Roboto, sans-serif">Hey Team! This is a template Post.',
          url: ['https://firebasestorage.googleapis.com/v0/b/jurni-dev/o/groups%2Fgroup-template-post.png?alt=media&token=b15bff99-45d6-4a03-9cd9-90f27cd48628']
        },
        from: doc(db, `users/${process.env.VUE_APP_CHAT_SUPPORT_USER_ID}`),
        timestamp: Timestamp.now(),
        reactions: {
          THUMB_UP: 0,
          LOVE: 1,
          SAD: 0,
          ANGRY: 0,
          SURPRISE: 0,
          LAUGH: 0
        }
      }

      const collectionRef = collection(db, 'groups', groupRef.id, 'posts')
      const postRef = await addDoc(collectionRef, post)
      const postSnap = await getDoc(postRef)
      const postData = postSnap.data() as GroupPost

      const comment = {
        content: 'Hi from Jurni Support!',
        timestamp: Timestamp.now(),
        from: doc(db, 'users', `${process.env.VUE_APP_CHAT_SUPPORT_USER_ID}`)
      }

      const collectionCommentRef = collection(db, 'groups', groupRef.id, 'posts', postRef.id, 'comments')

      const newCommentRef = await addDoc(collectionCommentRef, comment)
      const newCommentSnap = await getDoc(newCommentRef)
      const newComment = newCommentSnap.data() as GroupPostComment

      newComment.id = newCommentRef.id
      newComment.from = useMember(newComment.from?.id)

      this.posts[postRef.id] = postData
      this.posts[postRef.id].comments = {}
      this.posts[postRef.id].comments[newCommentRef.id] = newComment
    },
    async autoAddToThread(groupRef, userRef) {
      const groupSnap = await getDoc(groupRef)
      const groupData = groupSnap.data() as Group
      if (groupData && groupData.thread) {
        const threadRef = groupData.thread
        await setDoc(threadRef, { members: arrayUnion(userRef) }, { merge: true })
      }
    },
    async notifyLive(currentCommunityId: string, hostName: string) {
      const { currentUser } = storeToRefs(useCoreStore())

      if (!this.currentGroupId || !this.currentGroup || !currentUser.value)
        return

      this.currentGroup.members.map((member) => {
        if (currentUser.value?.id === member.id)
          return true

        const notifRef = doc(collection(db, 'notifications'))
        const notificationData = {
          id: notifRef.id,
          meta: {
            user: doc(db, `users/${member.id}`),
            item: {
              ref: doc(db, `groups/${this.currentGroupId}`),
              name: this.currentGroup?.name || '',
              communityId: currentCommunityId
            },
            timestamp: Timestamp.now(),
            content: `${hostName} is now Live in ${this.currentGroup?.name}`
          },
          status: 'new',
          notificationType: 'live.group'
        }
        setDoc(notifRef, notificationData)
        return true
      })
    }
  }
})
