import type { Thread, User } from '@/types'

import { useAuthenticatedUser } from '@/composables/useAuthenticatedUser'
// import { useSortedCollection } from '@/composables/utils/useSortedCollection'
import { doc, DocumentReference } from 'firebase/firestore'
import { db } from '@/services/firebase'
import { storeToRefs } from 'pinia'
import { useCoreStore } from '@/stores/core'
import { useThreadStore } from '@/stores/thread'

export const useNewThread = () => {
  const threadStore = useThreadStore()

  const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
  const create = async (memberList: Array<Partial<User>>) => {
    if (!currentCommunity.value)
      throw new Error('Community not found')
    if (!currentUser.value)
      throw new Error('User auth error')
    const owner = currentUser.value.userRef as DocumentReference
    const members = [owner]
    memberList.forEach((member) => members.push(doc(db, `users/${member.id}`)))
    const newThread: Thread = {
      community: doc(db, 'communities', currentCommunity.value.id),
      thread_id: '',
      members,
      name: '',
      owner,
      type: 'thread'
    }
    const threadData = await threadStore.createThread(newThread)
    return threadData
  }

  return { create }
}
