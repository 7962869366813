import { useCoreStore } from '@/stores/core'
import { useGroupStore } from '@/stores/group'
import { useCalendarStore } from './calendar'
import { useBillingStore } from '@/stores/billing'
import { db } from '@/services/firebase'

import { ref, Ref } from 'vue'
import { useRouter } from 'vue-router'
import {
  doc,
  collection,
  DocumentReference,
  onSnapshot,
  Query,
  DocumentData,
  query,
  where,
  Unsubscribe,
  deleteDoc,
  orderBy
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { defineStore, storeToRefs } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { Toast, User } from '@/types'

const functions = getFunctions()

interface Alert {
  id: string,
  description: string
  color?: string,
  visible: boolean
}

export interface Notification {
  id: string
  meta: {
    item?: {
      name: string
      user?: DocumentReference<User>
      ref: DocumentReference
    },
    user: DocumentReference<User>,
    content?: string
  }
  notificationType: string
  status?: string
}

type AlertStoreState = {
  alerts: Alert[]
  firstLoad: boolean
  notificationsLoading: boolean
  lazyLoadPointer: number
  lazyLoadedNotifications: Notification[]
  checkForNotificationsLoading: boolean
  hasUpcomingEvents: boolean
  notifications: Notification[]
  newNotifications: Notification[]
  readNotifications: Notification[]
  toasts: Record<string, Toast>
  audio: Record<string, HTMLAudioElement>
  notifSnapUnsub: Unsubscribe | null
}

export const useAlertStore = defineStore('alert', {
  state: (): AlertStoreState => ({
    alerts: [],
    firstLoad: true,
    notificationsLoading: false,
    lazyLoadPointer: 0,
    lazyLoadedNotifications: [],
    checkForNotificationsLoading: true,
    hasUpcomingEvents: false,
    notifications: [],
    newNotifications: [],
    readNotifications: [],
    toasts: {},
    audio: {},
    notifSnapUnsub: null
  }),
  getters: {
    visibleAlerts(st) {
      return st.alerts.filter((alert) => alert.visible)
    },
    getNewNotifications(st) {
      return st.newNotifications
    },
    loadedNotifications(st) {
      return st.lazyLoadedNotifications
    },
    allNotifications(st) {
      return st.notifications
    },
    toastList(st) {
      // return Object.values(st.toasts).filter((toast) => toast.visible)
      return Object.values(st.toasts)
    }
  },
  actions: {
    clearAllAlerts() {
      this.alerts = []
    },
    clearAllToasts() {
      this.toasts = {}
    },
    clearLastAlert() {
      this.alerts.pop()
    },
    removeToast(toastId: string) {
      if (this.toasts[toastId])
        delete this.toasts[toastId]
    },
    clearNotifications() {
      this.newNotifications = []
      this.readNotifications = []
      this.notifications = []
    },
    removeNotificationById(notificationId) {
      this.notifications = this.notifications.filter((notification) => notification.id !== notificationId)
    },
    setAudio(id: string, url: string, opts: Record<string, boolean>) : HTMLAudioElement {
      if (!Object.keys(this.audio).includes(id)) {
        const audElm = new Audio(url)

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(opts))
          audElm[key] = value

        this.audio[id] = audElm
      }
      return this.audio[id]
    },

    playAudio(id: string, loop = false) : void {
      const audElm = this.audio[id]
      audElm.loop = loop
      audElm.muted = false

      const newMsgAlert = audElm.play()
      if (newMsgAlert !== undefined) {
        newMsgAlert
          .then(() => { console.log(`${id} ALERT SENT`) })
          .catch((err) => { console.log(`${id} ALERT FAILED`, err) })
      }
    },

    stopAudio(id: string) : void {
      this.audio[id].loop = false
    },

    getNotificationById(id : string) : Ref<Notification> {
      return ref(this.notifications.filter((notification) => notification.id === id)[0] ?? null)
    },
    async checkForNotifications() {
      const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
      const { viewMentionedPost, viewAddedGroupNotif, viewGroupLiveNotif } = useGroupStore()
      const { viewUpdatedEventNotif } = useCalendarStore()
      const { viewPaymentFailedNotif } = useBillingStore()
      const router = useRouter()

      if (!currentUser.value || !currentCommunity.value)
        return

      if (this.notifSnapUnsub) {
        this.notifSnapUnsub()
        this.checkForNotificationsLoading = true
        this.firstLoad = true
      }

      const notifsQuery : Query<DocumentData> = query(
        collection(db, 'notifications'),
        where('meta.user', '==', currentUser.value.userRef),
        where('meta.item.communityId', '==', currentCommunity.value.id),
        orderBy('meta.timestamp', 'desc')
      )

      this.notifSnapUnsub = onSnapshot(notifsQuery, async (notificationQuerySnap) => {
        await Promise.all(notificationQuerySnap.docChanges().map(async (notificationChange) => {
          const notificationId = notificationChange.doc.id
          const notificationData = notificationChange.doc.data() as Notification

          if (notificationChange.type === 'removed') {
            this.newNotifications = this.newNotifications.filter((notif) => notif.id !== notificationId)
            this.readNotifications = this.readNotifications.filter((notif) => notif.id !== notificationId)
            this.notifications = this.notifications.filter((notif) => notif.id !== notificationId)
            this.lazyLoadedNotifications = this.notifications.filter((notif) => notif.id !== notificationId)
            this.lazyLoadPointer -= 1
            return
          }

          if (notificationChange.type === 'added') {
            notificationData.id = notificationId

            if (notificationData.status === 'pending' || notificationData.status === 'new') {
              // if the first loading of notifs is done, we put new notifications on top of lazyloaded notifs
              // this will effect while in Notifications page
              if (!this.checkForNotificationsLoading) {
                this.notifications.unshift(notificationData)
                this.newNotifications.unshift(notificationData)
                this.lazyLoadedNotifications.unshift(notificationData)
                this.lazyLoadPointer += 1
              } else {
                this.newNotifications.push(notificationData)
              }
            } else {
              this.readNotifications.push(notificationData)
            }

            if (notificationData.notificationType === 'tag.group.post' && notificationData.status === 'new') {
              const groupPostRef = notificationData.meta.item?.ref
              const groupId = groupPostRef?.parent.parent?.id
              const postId = groupPostRef?.id

              if (groupId && postId && !this.firstLoad) {
                const viewMention = async (groupId, postId) => {
                  await viewMentionedPost(groupId, postId)
                  await router.push({ name: 'group.feed.post', params: { groupId, postId } })
                }

                this.addToast({
                  message: 'You have been tagged in a post',
                  onClick: () => viewMention(groupId, postId),
                  actionLabel: 'Show'
                })
              }
            } else if (notificationData.notificationType === 'added.group' && notificationData.status === 'new') {
              const groupId = notificationData.meta.item?.ref.id

              if (groupId && !this.firstLoad) {
                const viewNotif = async (groupId) => {
                  await viewAddedGroupNotif(groupId)
                  await router.push({ name: 'group.feed', params: { groupId } })
                }

                this.addToast({
                  message: notificationData.meta.content || 'You have been added to a group',
                  onClick: () => viewNotif(groupId),
                  actionLabel: 'Show'
                })
              }
            } else if (notificationData.notificationType === 'notify.event') {
              this.hasUpcomingEvents = true
            } else if ((notificationData.notificationType === 'updated.event') &&
              notificationData.status === 'new') {
              const meetingEventRef = notificationData.meta.item?.ref
              if (meetingEventRef && !this.firstLoad) {
                const viewNotif = async (meetingRef, notificationType) => {
                  await viewUpdatedEventNotif(meetingRef, notificationType)
                  router.push({ name: 'calendar.index' })
                }

                this.addToast({
                  message: notificationData.meta.content || 'You have an event update',
                  onClick: () => viewNotif(meetingEventRef, notificationData.notificationType),
                  actionLabel: 'Show'
                })
              }
            } else if (notificationData.notificationType === 'notify.payment.failed') {
              const customerBillingDetailsRef = notificationData.meta.item?.ref
              if (customerBillingDetailsRef && !this.firstLoad) {
                const viewNotif = async (billingDetailsRef, notificationType) => {
                  await viewPaymentFailedNotif(billingDetailsRef, notificationType)
                  router.push({ name: 'settings.payments' })
                }

                this.addToast({
                  message: notificationData.meta.content || 'Your payment has failed',
                  onClick: () => viewNotif(customerBillingDetailsRef, notificationData.notificationType),
                  actionLabel: 'Show'
                })
              }
            } else if (notificationData.notificationType === 'live.group') {
              const groupId = notificationData.meta.item?.ref.id
              if (groupId && !this.firstLoad) {
                const viewNotif = async (groupId) => {
                  await viewGroupLiveNotif(groupId)
                  await router.push({ name: 'group.feed', params: { groupId } })
                }

                this.addToast({
                  message: notificationData.meta.content || 'Someone is live in your group',
                  onClick: () => viewNotif(groupId),
                  actionLabel: 'Show'
                })
              }
            }
          } else if (notificationChange.type === 'modified') {
            if (notificationData.status !== 'pending' && notificationData.status !== 'new')
              this.newNotifications = this.newNotifications.filter((notif) => notif.id !== notificationId)
          }
        }))

        if (this.checkForNotificationsLoading) {
          this.notifications = [...this.newNotifications, ...this.readNotifications]
          if (this.notifications.length)
            this.lazyLoadNotifications(true)

          if (this.firstLoad) {
            if (this.newNotifications.length) {
              this.addToast({
                message: 'You have unread notifications.',
                onClick: () => router.push({ name: 'notifications' }),
                actionLabel: 'Show'
              }, null)
            }

            if (this.hasUpcomingEvents) {
              this.addToast({
                message: 'You have upcoming events.',
                onClick: () => router.push({ name: 'notifications' }),
                actionLabel: 'Show'
              }, null)
              this.hasUpcomingEvents = false
            }
          }
        }
        this.checkForNotificationsLoading = false
        this.firstLoad = false
      })

      const createUpcomingEventsNotifsFunc = httpsCallable<{ communityId: string }, any>(functions, 'createUpcomingEventsNotifs')
      createUpcomingEventsNotifsFunc({ communityId: currentCommunity.value?.id })
    },
    async lazyLoadNotifications(force = false, limit = 5) {
      this.notificationsLoading = true

      if (force) {
        // reset
        this.lazyLoadPointer = 0
        this.lazyLoadedNotifications = []
      }

      let endPoint = this.lazyLoadPointer + limit
      if (endPoint > this.notifications.length)
        endPoint = this.notifications.length

      const notifBatch = this.notifications.slice(this.lazyLoadPointer, endPoint)

      if (!notifBatch.length) {
        this.notificationsLoading = false
        return true
      }

      this.lazyLoadedNotifications = this.lazyLoadedNotifications.concat(notifBatch)
      this.lazyLoadPointer = endPoint
      this.notificationsLoading = false

      return Object.values(this.notifications).length === this.lazyLoadedNotifications.length
    },
    async deleteNotif(id: string) {
      this.removeNotificationById(id)
      await deleteDoc(doc(collection(db, 'notifications'), id))
    },
    add(alert, timeout: number | null = 3000) {
      const newAlert = {
        id: uuidv4(),
        visible: true,
        ...alert
      }

      this.alerts.push(newAlert)

      if (timeout) {
        setTimeout(() => {
          const alertIdx = this.alerts.findIndex((alrt) => alrt.id === newAlert.id)
          this.alerts.splice(alertIdx, 1)
        }, timeout)
      }
      return newAlert
    },
    addToast(toast, timeout: number | null = 3000) {
      const newToast = {
        id: uuidv4(),
        visible: false,
        ...toast
      }

      this.toasts[newToast.id] = newToast
      const storedToast = this.toasts[newToast.id]
      setTimeout(() => {
        storedToast.visible = true
      }, 100)

      if (timeout) {
        setTimeout(() => {
          storedToast.visible = false
          delete this.toasts[newToast.id]
        }, timeout)
      }
    }
  }
})
