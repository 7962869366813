
  import { defineComponent } from 'vue'
  import JurButton from '@/components/jur-button/JurButton.vue'
  import Card from '@/components/card-ui/Card.vue'
  import CardHeader from '@/components/card-header-ui/CardHeader.vue'
  import CardCover from '@/components/card-cover-ui/CardCover.vue'
  import CardBody from '@/components/card-body-ui/CardBody.vue'
  import LikeSaveFollow from '@/components/common/social/groups/LikeSaveFollow.vue'

  export default defineComponent({
    name: 'style-guide',
    components: {
      LikeSaveFollow, CardBody, CardCover, CardHeader, Card, JurButton
    }
  })
