import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()

interface AddPaymentMethodReqInterface {
  uid: string
  communityId: string
  token: string
  expiry: string,
  postal: string
  studentOnboarding?: boolean
}

interface RemovePaymentMethodReqInterface {
  uid: string
  communityId: string
  token: string
}

export const addPaymentMethod = async (data) => {
  const addPaymentMethodFunc = httpsCallable<AddPaymentMethodReqInterface, void>(functions, 'addPaymentMethod')

  try {
    const resource = await addPaymentMethodFunc(data)

    return resource
  } catch (err: any) {
    throw new Error(`add payment method err: ${err.message}`)
  }
}

export const removePaymentMethod = async (data) => {
  const removePaymentMethodFunc = httpsCallable<RemovePaymentMethodReqInterface, void>(functions, 'removePaymentMethod')

  try {
    const resource = await removePaymentMethodFunc(data)

    return resource
  } catch (err: any) {
    throw new Error(`remove payment method err: ${err.message}`)
  }
}

export default {
  addPaymentMethod,
  removePaymentMethod
}
