import { User as AuthUser } from 'firebase/auth'
import {
  arrayRemove,
  collection,
  getDocs,
  query,
  where,
  setDoc,
  getDoc,
  doc,
  DocumentReference,
  arrayUnion,
  limit
} from 'firebase/firestore'
import { deleteObject, ref as storageRef } from 'firebase/storage'
import { defineStore } from 'pinia'
import { computed, ref, Ref, watch, reactive } from 'vue'

import { useUser, UseUser } from '@/composables/useUser'
import { auth, db, storage } from '@/services/firebase'
import {
  Permission,
  Community,
  User,
  ImpersonateUserReqInterface,
  ImpersonateUserResInteface,
  Onboarding
} from '@/types'

import { storeToRefs } from 'pinia'
import { useThreadStore } from '@/stores/thread'
import { useContentStore } from '@/stores/content'

import { getFunctions, httpsCallable } from 'firebase/functions'
import { useFirebaseAuth } from '@/composables/useFirebaseAuth'

const functions = getFunctions()

export const useCoreStore = defineStore('core', () => {
  const currentUserAuth: Ref<AuthUser | null> = ref(null)
  const currentUserId: Ref<string | null> = ref(null)
  const users: Ref<Record<string, UseUser>> = ref({})
  const communities: Ref<Record<string, Community>> = ref({})
  const inactiveCommunities: Ref<Record<string, Community>> = ref({})
  const permissions: Ref<Permission[]> = ref([])
  const permissionsLoaded: Ref<boolean> = ref(false)
  const watchedCoachWelcomeVideo: Ref<boolean> = ref(false)
  const watchedCoachWelcomeVideoLoaded: Ref<boolean> = ref(false)
  const welcomeVideoData: Ref<Record<string, any>> = ref({})
  const profile = ref<User | null>(null)
  const currentCommunity = ref<Record<string, any> | null>(null)

  const currentUser = computed(() : UseUser | null => {
    if (!currentUserId.value)
      return null

    if (!Object.keys(users.value).includes(currentUserId.value))
      users.value[currentUserId.value] = useUser(currentUserId.value, currentUserAuth.value)
    return users.value[currentUserId.value]
  })

  const userCommunities = computed(
    () => [...Object.values(communities.value)]
  )

  const clearCoreStore = () => {
    currentUserAuth.value = null
    currentUserId.value = null
    users.value = {}
    communities.value = {}
    inactiveCommunities.value = {}
    permissions.value = []
    permissionsLoaded.value = false
    watchedCoachWelcomeVideo.value = false
    watchedCoachWelcomeVideoLoaded.value = false
    welcomeVideoData.value = {}
  }

  const getCommunityMembers = computed<Array<UseUser>>(() => {
    const communityMemberIds = currentCommunity.value?.members.map((communityMember) => communityMember.id) ?? []
    const commMembers = Object.keys(users.value).map((memberId) => {
      if (!communityMemberIds.includes(memberId))
        return null
      return users.value[memberId]
    })

    return commMembers.filter((communityMember) => communityMember) as Array<UseUser>
  })

  const getAllCommunityMembers = computed<Array<UseUser>>(() => {
    if (!currentCommunity.value)
      return []

    let _allMembers: Array<UseUser> = getCommunityMembers.value

    const inactiveIds = currentCommunity.value?.inactiveMembers?.map((communityMemberId) => communityMemberId) ?? []
    const commMembers = Object.keys(users.value).map((memberId) => {
      if (!inactiveIds.includes(memberId))
        return null
      return users.value[memberId]
    })
    _allMembers = _allMembers.concat(commMembers.filter((communityMember) => communityMember) as Array<UseUser>)

    return _allMembers
  })

  const refreshCommunity = async () => {
    if (!currentCommunity.value)
      return

    const communitySnap = await getDoc(doc(db, 'communities', currentCommunity.value?.id))
    const communityData = communitySnap.data() as Community
    const community = communities.value[currentCommunity.value?.id]

    if (!community)
      communities.value[currentCommunity.value?.id] = communityData

    community.name = communityData.name
    community.description = communityData.description
  }

  const getCurrentCommunityMembers = async () => {
    if (!currentCommunity.value?.id)
      return []
    const UserSnapshot = await getDocs(
      query(
        collection(db, 'users'),
        where('communities', 'array-contains', doc(db, `communities/${currentCommunity.value?.id}`))
      )
    )

    const members : User[] = []
    UserSnapshot.docs.map((user) => {
      const data = user.data()
      members.push(data as User)
      return members
    })
    return members
  }

  const getCurrentUserAuth = async (): Promise<AuthUser | null> => new Promise((resolve, reject) => {
    if (auth.currentUser)
      resolve(auth.currentUser)

    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe()
      resolve(user)
    }, reject)
  })

  const loadPermissions = async () => {
    if (!currentCommunity.value || !currentCommunity.value.roles)
      return

    const userId = ref(currentUser.value?.id)
    const communityRole = ref(currentUser.value?.role || currentCommunity.value?.roles[userId.value ?? ''] || 'student')
    permissions.value = []

    if (communityRole.value) {
      const permissionsCollection = collection(db, `roles/${communityRole.value}/permissions`)
      const permissionsQuerySnap = await getDocs(permissionsCollection)

      permissionsQuerySnap.forEach((permission) => {
        const actions = permission.data()

        Object.entries(actions).forEach(([key, value]) => {
          if (value) {
            const perm = { action: key, subject: permission.id }
            permissions.value.push(perm)
          }
        })
      })
    }
    permissionsLoaded.value = true
  }

  let currentCommunityResolve

  const currentCommunityPending = ref(true)

  const createCurrentCommunityPromise = () => new Promise<boolean>((resolve) => {
    currentCommunityResolve = resolve
  }).finally(() => { currentCommunityPending.value = false })

  const awaitCurrentCommunity = ref(createCurrentCommunityPromise())

  const loadCurrentUserCommunities = async () => {
    if (!currentUser.value)
      return

    communities.value = {}
    inactiveCommunities.value = {}

    const processCommunity = async (doc: any) : Promise<Community> => {
      const community = doc.data() as Community
      community.id = doc.id

      if (community.meta?.bannerItem) {
        const contentSnap = await getDoc(community.meta.bannerItem)
        const content = contentSnap.data()
        community.banner = content?.uploadUrl
      } else if (community.meta?.logo) {
        community.banner = community.meta.logo
      }
      return community
    }

    if (currentUser.value.activeCommunityId.value) {
      const currentCommunitySnap = await getDoc(doc(db, 'communities', currentUser.value.activeCommunityId.value))
      const community = await processCommunity(currentCommunitySnap)
      if (community.members.some((member) => member.id === currentUser.value?.id.value))
        communities.value[community.id] = community
      if (community.inactiveMembers?.some((memberId) => memberId === currentUser.value?.id.value))
        inactiveCommunities.value[community.id] = community
    }

    const communitiesQuerySnapshot = await getDocs(
      query(
        collection(db, 'communities'),
        where('members', 'array-contains', currentUser.value?.userRef)
      )
    )

    const inactiveCommunitiesQuerySnapshot = await getDocs(
      query(
        collection(db, 'communities'),
        where('inactiveMembers', 'array-contains', currentUser.value?.userRef.id)
      )
    )

    await Promise.all(communitiesQuerySnapshot.docs.map(async (doc) => {
      const community = await processCommunity(doc)
      if (!communities.value[community.id])
        communities.value[community.id] = community
    }))

    await Promise.all(inactiveCommunitiesQuerySnapshot.docs.map(async (doc) => {
      const community = await processCommunity(doc)
      if (!inactiveCommunities.value[community.id])
        inactiveCommunities.value[community.id] = community
    }))

    if (currentCommunityPending.value)
      currentCommunityResolve()
  }

  const loadMember = async (
    userId: string,
    userAuth: AuthUser | null = null,
    forceReload = false
  ) : Promise<UseUser | null> => {
    if (!userId)
      return null

    if (!Object.keys(users.value).includes(userId) || forceReload)
      users.value[userId] = useUser(userId, userAuth)
    return users.value[userId] as UseUser
  }

  const loadCommunityMembers = async (forceReload = false) : Promise<void> => {
    if (!currentCommunity.value || !currentUser.value)
      return

    if (forceReload)
      users.value = { [currentUserId.value ?? '']: currentUser.value }

    await Promise.all(currentCommunity.value.members.map(async (member) => {
      if (Object.keys(users.value).includes(member.id))
        return Promise.resolve(null)

      if (member.id !== currentUser.value?.id.value)
        return loadMember(member.id, null, forceReload)

      return Promise.resolve(null)
    }))

    if (currentCommunity.value.inactiveMembers) {
      await Promise.all(currentCommunity.value.inactiveMembers?.map(async (memberId) => {
        if (Object.keys(users.value).includes(memberId))
          return Promise.resolve(null)

        if (memberId !== currentUser.value?.id.value)
          return loadMember(memberId, null, forceReload)

        return Promise.resolve(null)
      }))
    }
  }

  const setMember = async (user: AuthUser) : Promise<void> => {
    currentUserAuth.value = user
    currentUserId.value = (!user) ? null : user.uid
    if (!user)
      return

    const userRef = doc(db, 'users', user.uid) as DocumentReference<User>
    const userSnap = await getDoc(userRef)
    profile.value = userSnap.data() ?? null

    if (!profile.value)
      return

    if (!profile.value.activeCommunityId) {
      const communitiesQuery = await getDocs(query(
        collection(db, 'communities'),
        where('members', 'array-contains', userRef),
        limit(1)
      ))
      if (!communitiesQuery.empty) {
        const firstDoc = communitiesQuery.docs[0]
        await setDoc(userRef, { activeCommunityId: firstDoc.id }, { merge: true })
        profile.value.activeCommunityId = firstDoc.id
      }
      const userSnap = await getDoc(userRef)
      profile.value = userSnap.data() ?? null
    }

    if (!profile.value)
      return

    const communityRef = doc(db, 'communities', profile.value.activeCommunityId)
    const communitySnap = await getDoc(communityRef)
    currentCommunity.value = { ...communitySnap.data(), id: communitySnap.id }

    await loadCurrentUserCommunities()
    await loadPermissions()
  }

  const removeMember = async (memberId) => {
    if (!currentCommunity.value)
      return

    await setDoc(
      doc(db, `communities/${currentCommunity.value?.id}`),
      {
        members: arrayRemove(doc(db, `users/${memberId}`)),
        inactiveMembers: arrayRemove(memberId)
      },
      { merge: true }
    )
    await loadCommunityMembers(true)
  }

  const signInAsUser = async (memberId: string) => {
    if (!currentCommunity.value)
      return

    const impersonateUserFunc = httpsCallable<ImpersonateUserReqInterface, ImpersonateUserResInteface>(functions, 'impersonateUser')

    try {
      const response = await impersonateUserFunc({ uid: memberId })

      if (response) {
        const token = response.data.token

        if (token) {
          const { tokenAuth } = useFirebaseAuth()
          await tokenAuth(token)

          return
        }
        return
      }
      return
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
  }

  const toggleMemberStatus = async (memberId) => {
    if (!currentCommunity.value)
      return

    const activeUser = currentCommunity.value.members.some((mbr) => mbr.id === memberId)

    await setDoc(
      doc(db, `communities/${currentCommunity.value?.id}`),
      {
        members: activeUser ? arrayRemove(doc(db, `users/${memberId}`)) : arrayUnion(doc(db, `users/${memberId}`)),
        inactiveMembers: activeUser ? arrayUnion(memberId) : arrayRemove(memberId)
      },
      { merge: true }
    )

    if (!currentCommunity.value.inactiveMembers)
      currentCommunity.value.inactiveMembers = []

    if (activeUser) {
      currentCommunity.value.members = currentCommunity.value?.members.filter(
        (currCommMember) => currCommMember.id !== memberId
      )

      currentCommunity.value.inactiveMembers = [...currentCommunity.value.inactiveMembers, memberId]
    } else {
      currentCommunity.value.members = [
        ...currentCommunity.value.members,
        doc(db, 'users', memberId) as DocumentReference<User>
      ]

      currentCommunity.value.inactiveMembers = currentCommunity.value.inactiveMembers.filter(
        (mbrId) => mbrId !== memberId
      )
    }
  }

  const getWelcomeVideoStep = async () => {
    const welcomeVideoRef = doc(db, 'onboardingflows', 'welcome-video')
    const welcomeVideoSnap = await getDoc(welcomeVideoRef)
    if (welcomeVideoSnap.exists())
      welcomeVideoData.value = welcomeVideoSnap.data()
  }

  const finishedCoachWelcomeVideo = async () => {
    if (!currentUser.value)
      return

    const userRef = currentUser.value.userRef
    const coachOnobardingRef = doc(userRef, 'onboarding', 'coach')
    await setDoc(coachOnobardingRef, { watchedWelcomeVideo: true }, { merge: true })
    watchedCoachWelcomeVideo.value = true
  }

  const hasCommunityCoachWatchedWelcomeVideo = async () => {
    await getWelcomeVideoStep()

    if (!currentCommunity.value || !currentCommunity.value.coach || !currentUser.value || !currentUser.value.userRef) {
      watchedCoachWelcomeVideo.value = true
      watchedCoachWelcomeVideoLoaded.value = true
      return
    }

    if (currentUser.value.userRef.id !== currentCommunity.value.coach.id) {
      watchedCoachWelcomeVideo.value = true
      watchedCoachWelcomeVideoLoaded.value = true
      return
    }

    const userRef = currentUser.value.userRef
    const coachOnobardingSnap = await getDoc(doc(userRef, 'onboarding', 'coach'))

    if (!coachOnobardingSnap.exists()) {
      watchedCoachWelcomeVideo.value = false
      watchedCoachWelcomeVideoLoaded.value = true
      return
    }

    const coachOnobarding = coachOnobardingSnap.data() as Onboarding
    watchedCoachWelcomeVideo.value = coachOnobarding.watchedWelcomeVideo ?? false
    watchedCoachWelcomeVideoLoaded.value = true
  }

  const setCommunity = async (communityId: string) => {
    if (!currentUser.value || !profile.value)
      return

    const userRef = currentUser.value?.userRef
    currentUser.value.activeCommunityId = ref(communityId)

    const communityRef = doc(db, 'communities', communityId)
    const communitySnap = await getDoc(communityRef)
    currentCommunity.value = { ...communitySnap.data(), id: communitySnap.id }

    await setDoc(
      userRef,
      {
        activeCommunityId: communityId,
        meta: { activeThread: '' }
      },
      { merge: true }
    )

    const userSnap = await getDoc(userRef)
    const profileData = userSnap.data() as User

    currentUser.value.profile.value = profileData
    profile.value = { ...profileData }

    const threadStore = useThreadStore()
    const { activeThreadId } = storeToRefs(threadStore)
    activeThreadId.value = null
  }

  const loadCurrentUserSharedContent = async () => {
    if (!currentCommunity.value || !currentUser.value)
      return

    currentUser.value.sharedContent = ref([])
    const userRef = currentUser.value?.userRef

    const commSettings = await getDoc(doc(userRef, 'communitySettings', currentCommunity.value?.id))
    const comSettingsData = commSettings.data()
    currentUser.value.sharedContent = comSettingsData
      ?.sharedContent ?? ref([])
  }

  const getMemberById = (id: string) => {
    if (!Object.keys(users.value).includes(id))
      loadMember(id)
    return users.value[id]
  }

  const getMemberIdByAgoraId = async (agoraId: string) => {
    const agoraIdInt = parseInt(agoraId.replace('screen-', ''), 10)
    const userSnaps = await getDocs(
      query(
        collection(db, 'users'),
        where('agoraId', '==', agoraIdInt)
      )
    )
    // since we have set agoraId as unique per user
    const userSnap = userSnaps.docs[0]
    return userSnap.id
  }

  const getAgoraIdByMemberId = async (userId: string) => {
    const userSnap = await getDoc(doc(db, 'users', userId))
    const userSnapData = userSnap.data() as User
    return userSnapData.agoraId
  }

  const isCommunityActive = computed(() => {
    if (!currentCommunity.value || !currentCommunity.value.status)
      return false

    const activeStatuses = ['ACTIVE', 'TRIAL']

    return activeStatuses.includes(currentCommunity.value.status)
  })

  const validateRequiredUserDataForPayment = async () => {
    if (!currentUserId.value)
      throw new Error('User not found')

    const requiredFields = reactive({
      firstName: false,
      lastName: false,
      email: false,
      phone: false
    })
    const err: string[] = []

    Object.entries(requiredFields).forEach(([key, val]) => {
      if (currentUser.value?.profile[key])
        requiredFields[key] = true
      else
        err.push(`${key}`)
    })

    if (err.length > 0) {
      throw new Error(`User missing fields: ${err.join(', ')}.
        Please update in &nbsp;<a href="/settings/account"
        style="text-decoration: underline;">account settings</a>`)
    }
  }

  const updateCommunity = async (data: Partial<Community>) : Promise<void> => {
    if (currentCommunity.value) {
      await setDoc(doc(db, `communities/${currentCommunity.value?.id}`), data, { merge: true })

      Object.entries(data).forEach(([key, value]) => {
        if (communities.value && currentCommunity.value?.id && communities.value[currentCommunity.value?.id]) {
          if (key === 'roles') {
            Object.entries(value).forEach(([k, v]) => {
              communities.value[currentCommunity.value!.id][key][k] = v
            })
          } else if (key === 'meta') {
            Object.entries(value).forEach(async ([k, v]) => {
              if (k === 'bannerItem') {
                const contentSnap = await getDoc(v as DocumentReference)
                const content = contentSnap.data()
                communities.value[currentCommunity.value!.id].banner = content?.uploadUrl
              }
            })
          } else {
            communities.value[currentCommunity.value.id][key] = value
          }
        }
      })
    }
  }

  const setCommunityRole = async (userId: string, role: string) => {
    await updateCommunity({ roles: { [userId]: role } })
  }

  const setCommunityLogo = async (file: File) : Promise<DocumentReference> => {
    // const name = v4()
    // const sr = storageRef(storage, `public/${name}`)
    // const fileSnap = await uploadBytes(sr, file, { contentType: file.type })
    // const downloadURL = await getDownloadURL(fileSnap.ref)
    // await updateCommunity({ meta: { logo: downloadURL } })
    const { addContent } = useContentStore()
    const contentRef = await addContent('other', false, file)

    return contentRef
  }

  const removeCommunityLogo = async () => {
    if (!currentCommunity.value)
      return

    const httpsReference = storageRef(storage, currentCommunity.value?.meta.logo)
    await deleteObject(httpsReference)
    await updateCommunity({ meta: { logo: '' } })
  }

  const updateUser = async (data: Partial<User>) : Promise<void> => {
    if (!currentUser.value)
      return

    Object.keys(data).forEach((key) => {
      if (currentUser.value)
        currentUser.value.profile[key] = data[key]
    })
  }

  watch(() => currentCommunity.value, async () => {
    await loadPermissions()
    await hasCommunityCoachWatchedWelcomeVideo()
    await loadCurrentUserSharedContent()
  })

  return {
    getCurrentCommunityMembers,
    getCommunityMembers,
    getAllCommunityMembers,
    loadCommunityMembers,
    getMemberById,
    getMemberIdByAgoraId,
    getCurrentUserAuth,
    loadPermissions,
    userCommunities,
    setMember,
    removeMember,
    signInAsUser,
    toggleMemberStatus,
    setCommunity,
    setCommunityRole,
    loadMember,
    loadCurrentUserCommunities,
    clearCoreStore,
    currentUser,
    currentUserId,
    communities,
    currentCommunity,
    awaitCurrentCommunity,
    permissions,
    permissionsLoaded,
    isCommunityActive,
    watchedCoachWelcomeVideo,
    watchedCoachWelcomeVideoLoaded,
    welcomeVideoData,
    finishedCoachWelcomeVideo,
    validateRequiredUserDataForPayment,
    updateCommunity,
    setCommunityLogo,
    removeCommunityLogo,
    updateUser,
    users,
    refreshCommunity,
    getAgoraIdByMemberId
  }
})
