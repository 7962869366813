import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '@/services/firebase'
import { Role } from '@/types'

export const useRole = () => {
  const getRoleList = async () => {
    let roleSnaps
    try {
      roleSnaps = await getDocs(query(
        collection(db, 'roles'),
        where('type', '!=', 'system')
      ))
    } catch (err) {
      console.error('ROLE ERROR', err)
      return []
    }
    return roleSnaps.docs.map((roleSnap) => roleSnap.data() as Role)
  }

  return { getRoleList }
}
