import {
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signOut,
  updateCurrentUser,
  updateEmail,
  updatePassword,
  verifyPasswordResetCode,
  AuthError,
  UserCredential
} from 'firebase/auth'
import { storeToRefs } from 'pinia'

import { auth } from '@/services/firebase'
import { useAlertStore } from '@/stores/alert'
import { useGroupStore } from '@/stores/group'
import { useThreadStore } from '@/stores/thread'
import { useCoreStore } from '@/stores/core'

type UseFirebaseAuth = () => {
  tokenAuth: (token: string) => Promise<UserCredential>
  logout: () => Promise<void>
  changeEmail: (email: string) => Promise<boolean>
  changePassword: (password: string) => Promise<boolean>
  resetRequest: (email: string) => Promise<boolean>
  resetConfirm: (code: string, password: string) => Promise<void>
  resetValidate: (code: string) => Promise<string>
}

export const authErrors = {
  'auth/invalid-email': 'Invalid email',
  'auth/user-not-found': 'No account with that email was found',
  'auth/wrong-password': 'Incorrect password',
  'auth/email-already-in-use': 'There is already an account with that email. Try logging in.',
  'auth/weak-password': 'Enter at least 6 characters.'
}

export const useFirebaseAuth: UseFirebaseAuth = () => {
  const tokenAuth = async (token: string) : Promise<UserCredential> => {
    const credential = await signInWithCustomToken(auth, token)
    await updateCurrentUser(auth, credential.user)
    return credential
  }

  const logout = async () : Promise<void> => {
    const { notifSnapUnsub } = storeToRefs(useAlertStore())
    const { groupSnapUnsub } = storeToRefs(useGroupStore())
    const { clearAllAlerts, clearAllToasts } = useAlertStore()
    const { cleanGroupStore } = useGroupStore()
    const { clearCoreStore } = useCoreStore()
    const { resetThreadStore } = useThreadStore()
    // unsubscribe thread and message snapshots
    resetThreadStore()
    // unsubscribe notification snapshot
    if (notifSnapUnsub.value)
      notifSnapUnsub.value()
    // unsubscribe group post snapshot
    if (groupSnapUnsub.value)
      groupSnapUnsub.value()
    cleanGroupStore()
    clearCoreStore()
    clearAllAlerts()
    clearAllToasts()

    await signOut(auth)
  }

  const resetRequest = async (email: string) : Promise<boolean> => {
    try {
      await sendPasswordResetEmail(auth, email)
      return true
    } catch (e) {
      const code = (e as AuthError).code
      return Promise.reject(authErrors[code] ?? 'Email or password was incorrect')
    }
  }

  const resetConfirm = async (code: string, password: string) : Promise<void> => confirmPasswordReset(
    auth,
    code,
    password
  )

  const resetValidate = async (code: string) : Promise<string> => verifyPasswordResetCode(auth, code)

  const changePassword = async (password: string) : Promise<boolean> => {
    if (auth.currentUser) {
      try {
        await updatePassword(auth.currentUser, password)
      } catch (e) {
        const code = (e as AuthError).code
        return Promise.reject(authErrors[code] ?? 'Email or password was incorrect')
      }

      return true
    }

    throw new Error('User not authenticated')
  }

  const changeEmail = async (email: string) : Promise<boolean> => {
    if (auth.currentUser) {
      await updateEmail(auth.currentUser, email)

      return true
    }

    throw new Error('User not authenticated')
  }

  return {
    changePassword,
    changeEmail,
    tokenAuth,
    logout,
    resetRequest,
    resetConfirm,
    resetValidate
  }
}
