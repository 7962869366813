<template>
  <div class="header">
    <div class="title">
      <slot name="title"></slot>
    </div>
    <div class="icons">
      <slot name="icons"></slot>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'CardHeader'
  })
</script>

<style scoped>

</style>
