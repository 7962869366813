import { ComputedRef, Ref, computed, ref, watch } from 'vue'

export type useGameboardLineReturn = {
  colsPerRow: ComputedRef<number>
  gapWidth: ComputedRef<number>
  positionInRow: (card: Record<string, any>, index: number) => boolean
  isLastCard: (cards: Array<Record<string, any>>, index: number) => boolean
  getRow: (index: number) => number
}

export const useGameboardLine = (
  gridWidth: Ref<number> | ComputedRef<number> | number,
  gridCardWidth: Ref<number> | ComputedRef<number> | number,
  minGapWidth = 40,
  widthOffset = 0
) : useGameboardLineReturn => {
  const maxWidth = ref(gridWidth)
  const boardWidth = ref(maxWidth.value + widthOffset)
  const cardWidth = ref(gridCardWidth)
  const colsPerRow = computed(() => Math.floor(boardWidth.value / (cardWidth.value + minGapWidth)))
  const gapWidth = computed(() => {
    const totalSpace = (boardWidth.value + widthOffset) - (cardWidth.value * colsPerRow.value)
    return Math.floor(totalSpace / colsPerRow.value)
  })

  const positionInRow = (
    card: Record<string, any>,
    index: number
  ) : boolean => {
    if (card.boardRow % 2 === 0)
      return index % colsPerRow.value === colsPerRow.value - 1
    return index % colsPerRow.value === 0
  }

  const isLastCard = (cards: Array<Record<string, any>>, index: number) : boolean => {
    if (colsPerRow.value < 0)
      return cards[index].boardRow % 2 === 0 && index === cards.length - 1

    if (cards.length <= index + colsPerRow.value) {
      if (cards[index].boardRow % 2 !== 0 && index % colsPerRow.value === 0)
        return true

      if (cards[index].boardRow % 2 === 0 && index === cards.length - 1)
        return true
    }

    return false
  }

  const getRow = (index: number): number => Math.floor(index / colsPerRow.value)

  watch(() => ref(gridWidth).value, (newWidth) => {
    if (newWidth && newWidth + widthOffset > 1 && newWidth !== boardWidth.value)
      boardWidth.value = newWidth + widthOffset
  })

  return {
    colsPerRow,
    gapWidth,
    positionInRow,
    isLastCard,
    getRow
  }
}
