import type { Ref, UnwrapRef } from 'vue'
import type { MaybeRef } from '@vueuse/core'
import { doc as firebaseDoc, setDoc, Timestamp } from 'firebase/firestore'
import moment from 'moment'
import { computed, reactive, ref } from 'vue'

import type { Thread } from '@/types'

// eslint-disable-next-line import/no-cycle
import { useCoreStore } from '@/stores/core'
import { useThreadStore } from '@/stores/thread'
// eslint-disable-next-line import/no-cycle
import { useMessage } from '@/composables/useMessage'
import { storeToRefs } from 'pinia'
import { UseUser } from '@/composables/useUser'

type UseThread = (threadId: MaybeRef<string | undefined>, thread?: Thread) => {
  doc: Ref<Thread | null>
  lastActivity: Ref<string>
  lastMessage: Ref<ReturnType<typeof useMessage> | null>
  load: () => Promise<boolean>
  members: Ref<Array<UseUser>>
  messages: Ref<UnwrapRef<ReturnType<typeof useMessage>[]>>
  modifyMembership(userId: string, add: boolean)
  name: Ref<string>
  newMessage: (type: string, meta: Record<string, any>) => Promise<void>
  select: () => void
  unHide: () => void
  isOwner: Ref<boolean | null>
}

export const useThread: UseThread = (maybeRef, thread) => {
  const threadId = ref(maybeRef)
  const threadStore = useThreadStore()
  const { currentUser } = storeToRefs(useCoreStore())
  const { getMemberById } = useCoreStore()

  const modifyMembership = (userId : string, add = true) : void => {
    if (!threadId.value)
      throw new Error('Thread not selected')

    if (add)
      threadStore.addThreadMember(threadId.value, userId)
    else
      threadStore.removeThreadMember(threadId.value, userId)
  }

  const doc = computed(() => {
    if (thread)
      return thread

    if (threadId.value === undefined)
      return null

    return threadStore.getThreadById(threadId.value)
  })

  const lastActivity = computed(() => {
    if (threadId.value === undefined)
      return ''

    const message = threadStore.getThreadMessages(threadId.value).slice(-1)?.[0]

    if (!message)
      return ''

    return moment(message.timestamp).fromNow()
  })

  const lastMessage = computed(() => {
    if (threadId.value === undefined)
      return null

    const messages = threadStore.getThreadMessages(threadId.value)
    const message = messages[messages.length - 1]

    if (!message)
      return null

    return useMessage(threadId.value, message.message_id)
  })

  const load = async () : Promise<boolean> => {
    if (!threadId.value)
      throw new Error('threadId missing')
    await threadStore.loadThreadMessages(threadId.value)

    return false
  }

  const members = computed(() => {
    if (!doc.value?.members)
      return [] as Array<UseUser>

    return doc.value.members.map((member) => getMemberById(member.id)) as Array<UseUser>
  })

  const messages = computed(() => {
    if (threadId.value === undefined)
      return []

    const threadMessages = threadStore.getThreadMessages(threadId.value)

    // useMessage returns an object with refs.
    // Sending it through `reactive` will unwrap those.
    const threadMsg = threadMessages.map((message) => reactive(useMessage(threadId.value, message.message_id)))
    return threadMsg
  })

  const name = computed(() => {
    if (!doc.value)
      return ''

    if (doc.value.name && (currentUser.value?.userRef.id !== process.env.VUE_APP_CHAT_SUPPORT_USER_ID || doc.value.type !== 'support'))
      return doc.value.name

    return members.value.map(({ id: memberId, profile, name }) => {
      const mId = memberId.value ? memberId.value : memberId
      if (!profile)
        return null

      console.log('member', mId, currentUser.value?.id, mId !== currentUser.value?.id)
      if (mId !== currentUser.value?.id)
        return name

      return null
    }).filter((value) => value).join(', ')
  }) as Ref<string>

  const newMessage = async (type, meta) : Promise<void> => {
    if (!currentUser.value)
      throw new Error('Auth error: no user set')

    if (!threadId.value)
      throw new Error('Thread not set')

    await threadStore.sendMessage(threadId.value, {
      type,
      message_id: '',
      thread_id: threadId.value,
      reactions: {},
      meta,
      from: currentUser.value.id,
      timestamp: Timestamp.now()
    })
  }

  const select = () : void => {
    if (!threadId.value)
      throw new Error('Thread not found')

    const { currentUser } = storeToRefs(useCoreStore())

    if (currentUser.value?.userRef) {
      setDoc(
        currentUser.value?.userRef,
        { meta: { activeThread: threadId.value } },
        { merge: true }
      )
    }

    threadStore.setActiveThread(threadId.value)
  }

  const unHide = () : void => {
    if (!threadId.value)
      throw new Error('Thread not found')

    threadStore.unHideThread(threadId.value)
  }

  const isOnline = (userId: string) : boolean => {
    if (!threadId.value)
      return false

    if (currentUser.value?.id === process.env.VUE_APP_CHAT_SUPPORT_USER_ID)
      return false

    if (userId !== process.env.VUE_APP_CHAT_SUPPORT_USER_ID)
      return false

    return threadStore.status[userId]
  }

  const isOwner = computed(() => currentUser.value && doc.value &&
    currentUser.value.id === doc.value.owner.id)

  return {
    doc,
    lastActivity,
    lastMessage,
    load,
    members,
    messages,
    modifyMembership,
    name,
    newMessage,
    select,
    unHide,
    isOnline,
    isOwner
  }
}
