<template>
  <div class="sheet">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.sheet {
  border-radius: $border-radius-md;
  background-color: white;
  box-shadow: $shadow-1;
}
</style>
