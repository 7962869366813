import type { ComputedRef, Ref } from 'vue'
import { computed, ref } from 'vue'
import type { MaybeRef } from '@vueuse/core'
import { useDebounce } from '@vueuse/core'

import { search } from 'ss-search'

type UseClientSideSearch = <T extends Record<string, any>>(
  collection: MaybeRef<T[]>,
  keys: string[]
) => {
  matches: ComputedRef<T[]>
  query: Ref<string>
};

export const useClientSideSearch: UseClientSideSearch = (maybeRef, keys) => {
  const collection = ref(maybeRef)
  const query = ref('')

  const queryDebounced = useDebounce(query, 100)
  const matches = computed(() => {
    if (queryDebounced.value?.length === 0)
      return collection.value

    return search(
      collection.value || [],
      keys,
      queryDebounced.value ?? ''
    ) as typeof collection.value
  })

  // console.log('queryDebounced', queryDebounced, 'matches', matches)
  return { query: queryDebounced, matches }
}
