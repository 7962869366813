import { ref, computed, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import {
  getDocs,
  query,
  collectionGroup,
  where,
  doc,
  setDoc,
  arrayUnion,
  getDoc,
  DocumentReference,
  collection
} from 'firebase/firestore'

import { db } from '@/services/firebase'

import { useTimestamp } from '@/composables/utils/useTimestamp'

import { useCoreStore } from '@/stores/core'

import { User } from '@/types'

interface TempInvite {
  firstName: string
  lastName: string
  memberRef: DocumentReference
  userProfile: User
  isActive: boolean
}

export const useUserStore = defineStore('user', () => {
  const limit = ref(10)
  const lazyLoadPointer = ref(0)
  const loadedAll = ref(false)
  const tempInvites = ref<Array<TempInvite>>([])
  const invites = ref<Array<Record<string, any>>>([])
  const loading = ref(false)

  const { currentCommunity, currentUser } = storeToRefs(useCoreStore())

  const getRole = (userId: string) : string => {
    if (currentUser.value?.id === userId)
      return currentUser.value?.role ?? 'student'

    const communityRoles = currentCommunity.value?.roles
    if (!communityRoles || !communityRoles[userId])
      return 'student'

    if (['admin', 'superadmin'].includes(communityRoles[userId]))
      return 'coach'

    return communityRoles[userId]
  }

  const updateRole = (userId: string, newRole: string) => {
    const inviteIdx = invites.value.findIndex((invite) => invite.id === userId)
    invites.value[inviteIdx].role = newRole
  }

  const getStatus = (userData) => {
    if (userData.activeUser)
      return 'Active'

    if (currentCommunity.value?.inactiveMembers?.includes(userData.uid)) {
      if (userData.hasInvite && !userData.timeAccepted)
        return 'Invited'
      return 'Deactivated'
    }

    return 'Inactive'
  }

  // eslint-disable-next-line max-len
  const getCommunityInvites = computed(() => invites.value.filter((invite) => {
    console.log(invite)
    // debugger
    return true
  }))

  const detectOldInactiveMembers = async () => {
    if (!currentCommunity.value || !currentCommunity.value.id || (currentCommunity.value.inactiveMembers ?? []).length)
      return

    const memberIds = currentCommunity.value.members.map((member) => member.id)
    const inActiveMembers: Array<string> = []

    const inviteSnaps = await getDocs(query(
      collectionGroup(db, 'invitations'),
      where('community', '==', doc(db, 'communities', currentCommunity.value.id))
    ))

    await Promise.all(inviteSnaps.docs.map(async (inviteSnap) => {
      const userId = inviteSnap.ref.parent.parent?.id

      if (userId && !memberIds.includes(userId))
        inActiveMembers.push(userId)
    }))

    if (inActiveMembers.length) {
      await setDoc(
        doc(db, 'communities', currentCommunity.value.id),
        { inactiveMembers: arrayUnion(...inActiveMembers) },
        { merge: true }
      )
    }
  }

  const addTempInvite = async (memberRef: DocumentReference, isActive: boolean) => {
    const userSnap = await getDoc(memberRef)
    const userProfile = userSnap.data() as User
    const hasUser = !!tempInvites.value.find((invite) => invite.memberRef.id === memberRef.id)

    if (userProfile && !hasUser) {
      tempInvites.value.push({
        firstName: userProfile.firstName ?? '',
        lastName: userProfile.lastName ?? '',
        memberRef,
        userProfile,
        isActive
      })
    }
  }

  const addToCommunityInactiveMember = async (memberId: string) => {
    if (!currentCommunity.value)
      return

    if (currentCommunity.value?.inactiveMembers)
      currentCommunity.value?.inactiveMembers.push(memberId)
    else
      currentCommunity.value!.inactiveMembers = [memberId]
  }

  const sortTmpInvites = () : void => {
    tempInvites.value.sort((a, b) => {
      if ((a.firstName ?? '').toLowerCase() > (b.firstName ?? '').toLowerCase())
        return 1

      if ((a.firstName ?? '').toLowerCase() < (b.firstName ?? '').toLowerCase())
        return -1

      if ((a.lastName ?? '').toLowerCase() > (b.lastName ?? '').toLowerCase())
        return 1

      if ((a.lastName ?? '').toLowerCase() < (b.lastName ?? '').toLowerCase())
        return -1

      return 0
    })
  }

  const loadTempInvites = async () : Promise<void> => {
    if (!currentCommunity.value)
      return

    await Promise.all(currentCommunity.value.members.map(async (memberRef) => {
      if (!tempInvites.value.find((invite) => invite.memberRef.id === memberRef.id))
        await addTempInvite(memberRef, true)
    }))

    if (currentCommunity.value.inactiveMembers) {
      await Promise.all(currentCommunity.value.inactiveMembers.map(async (memberId) => {
        const memberRef = doc(db, 'users', memberId)
        if (!tempInvites.value.find((invite) => invite.memberRef.id === memberRef.id))
          await addTempInvite(memberRef, false)
      }))
    }
    sortTmpInvites()
  }

  const trackMember = async (memberRef: DocumentReference, userProfile: User, activeUser: boolean) => {
    if (!currentCommunity.value)
      return

    const hasUser = !!invites.value.find((invite) => invite.id === memberRef.id)
    if (hasUser)
      return

    const memberInvite: Record<string, any> = {
      ...userProfile,
      activeUser,
      role: getRole(memberRef.id),
      jurnis: [],
      groups: []
    }

    if (!memberInvite.uid)
      return

    const memberInvitations = await getDocs(query(
      collection(memberRef, 'invitations'),
      where('community', '==', doc(db, 'communities', currentCommunity.value.id))
    ))

    if (!memberInvitations.empty) {
      const tmpInvite = memberInvitations.docs[0].data()
      memberInvite.joined = tmpInvite.timeAccepted ? useTimestamp(tmpInvite.timeAccepted) : '-'
      memberInvite.hasInvite = true
      memberInvite.timeAccepted = tmpInvite.timeAccepted
      memberInvite.inviteId = tmpInvite.id
    } else {
      memberInvite.joined = '-'
      memberInvite.hasInvite = false
    }

    const jurniActiveSnaps = await getDocs(query(
      collection(db, 'jurnis'),
      where('community', '==', doc(db, 'communities', currentCommunity.value.id)),
      where('members', 'array-contains', doc(db, 'users', memberInvite.uid))
    ))
    if (!jurniActiveSnaps.empty) {
      jurniActiveSnaps.docs.map((jurniSnap) => {
        const jurni = jurniSnap.data()
        jurni.id = jurniSnap.ref.id
        memberInvite.jurnis.push(jurni)
        return null
      })
    }

    const jurniInactiveSnaps = await getDocs(query(
      collection(db, 'jurnis'),
      where('community', '==', doc(db, 'communities', currentCommunity.value.id)),
      where('inactiveMembers', 'array-contains', memberInvite.uid)
    ))
    if (!jurniInactiveSnaps.empty) {
      jurniInactiveSnaps.docs.map((jurniSnap) => {
        const jurni = jurniSnap.data()
        jurni.id = jurniSnap.ref.id
        memberInvite.jurnis.push(jurni)
        return null
      })
    }

    const groupSnaps = await getDocs(query(
      collection(db, 'groups'),
      where('community', '==', doc(db, 'communities', currentCommunity.value.id)),
      where('members', 'array-contains', doc(db, 'users', memberInvite.uid))
    ))
    if (!groupSnaps.empty) {
      groupSnaps.docs.map((groupSnap) => {
        const group = groupSnap.data()
        memberInvite.groups.push(group)
        return null
      })
    }
    memberInvite.status = getStatus(memberInvite)
    memberInvite.id = memberInvite.uid
    invites.value.push(memberInvite)
  }

  const loadInvites = async (batch: Array<TempInvite>) => {
    if (!currentCommunity.value)
      return

    await Promise.all(batch.map(async (tempInvite: TempInvite) => {
      if (!tempInvite.userProfile.uid)
        tempInvite.userProfile.uid = tempInvite.memberRef.id
      await trackMember(tempInvite.memberRef, tempInvite.userProfile, tempInvite.isActive)
    }))
  }

  const lazyLoadUsers = async (force = false) => {
    loadedAll.value = false

    if (force) {
      // resets lazy loaded invites
      lazyLoadPointer.value = 0
      invites.value = []
    }

    let endPoint = lazyLoadPointer.value + limit.value
    if (lazyLoadPointer.value + limit.value > tempInvites.value.length)
      endPoint = tempInvites.value.length

    const batch = tempInvites.value.slice(lazyLoadPointer.value, endPoint)

    if (!batch.length)
      return true

    await loadInvites(batch)

    if (lazyLoadPointer.value + limit.value > tempInvites.value.length)
      lazyLoadPointer.value = tempInvites.value.length
    else
      lazyLoadPointer.value += limit.value

    return invites.value.length >= tempInvites.value.length
  }

  const init = async () => {
    loading.value = true
    lazyLoadPointer.value = 0
    tempInvites.value = []
    invites.value = []
    await detectOldInactiveMembers()
    await loadTempInvites()
    loadedAll.value = await lazyLoadUsers(true)
    loading.value = false
  }

  watch(() => currentCommunity.value, async (val) => {
    if (val)
      await init()
  }, { immediate: true })

  return {
    detectOldInactiveMembers,
    loadTempInvites,
    init,
    getCommunityInvites,
    invites,
    loadedAll,
    lazyLoadUsers,
    getRole,
    getStatus,
    updateRole,
    loading,
    addTempInvite,
    sortTmpInvites,
    addToCommunityInactiveMember
  }
})
